import React from 'react';
import { Box, IconButton, useTheme } from '@mui/material';
import { FiMessageCircle } from 'react-icons/fi';

import { COMPONENT_FEATURE_FLAGS } from '../../utils/component-feature-flags';
import { Attendees } from '../Attendees';
import { Notifications } from '../Notifications';
import { useAdBoardContext } from '../../context';
import { CalendarCheck } from '../../icons/nova/CalendarCheck';

interface MobileAdBoardTopBarProps {
  toggleDiscussionDrawer: (isDrawerOpen: boolean) => void;
  toggleSectionDrawer: () => void;
  isSectionDrawerOpen: boolean;
  isDiscussionDrawerOpen: boolean;
}

export const MobileAdBoardTopBar = ({
  toggleDiscussionDrawer,
  toggleSectionDrawer,
  isSectionDrawerOpen,
  isDiscussionDrawerOpen,
}: MobileAdBoardTopBarProps): JSX.Element => {
  const { palette } = useTheme();
  const { shouldShowMainHcpNav } = useAdBoardContext();

  return (
    <Box display={'flex'} alignItems={'center'}>
      <Attendees />
      {COMPONENT_FEATURE_FLAGS.ADBOARD_DISCUSSION && (
        <IconButton
          data-testid='discussion-board-button'
          aria-label='discussion-board-button'
          sx={{
            color: isDiscussionDrawerOpen
              ? palette.keyops.teal
              : palette.keyops.blue.midnightBlue,
            mt: -0.2,
          }}
          onClick={() => toggleDiscussionDrawer(false)} // TODO: add discussion board state change here
        >
          <FiMessageCircle fontSize={25} />
        </IconButton>
      )}
      {/* TODO: remove this if we do not need section drawer in mobile view */}
      {/* TODO: and if we need it in the mobile view than we will have to fix styles in the
                SectionDrawer component  */}
      <IconButton
        data-testid='section-drawer-toggle-button'
        aria-label='section-drawer-toggle-button'
        sx={{
          color: isSectionDrawerOpen
            ? palette.keyops.teal
            : palette.keyops.blue.midnightBlue,
          mt: -0.2,
          mr: 0.5,
        }}
        onClick={() => toggleSectionDrawer()}
      >
        <CalendarCheck
          sx={{
            fontSize: '25px',
          }}
        />
      </IconButton>
      {/* No need to display the Notification in the Admin Preview */}
      {shouldShowMainHcpNav() && <Notifications />}
    </Box>
  );
};
