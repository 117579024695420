// External Dependencies
import React, { useState, useCallback } from 'react';
import {
  Edit,
  TextInput,
  SelectInput,
  ReferenceInput,
  BooleanInput,
  NumberInput,
  FormDataConsumer,
  FormTab,
  ImageField,
  TabbedForm,
  required,
  maxLength,
  minLength,
  useRedirect,
  useNotify,
  DateInput,
  SelectArrayInput,
  Labeled,
  TextField,
  useUpdate,
  useRecordContext,
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';

// Internal Dependencies
import KOToggleButton from '../../../components/toggle_button';
import { FileUploadInput } from '../../../components/file_upload_input';
import { stateChoices } from '../data.js';
import { HcpTypeChoices, SpecialtyChoices } from '../../../utils/choices.js';

const validateName = [required(), minLength(2), maxLength(255)];
const EngagementName = ({ record }) => {
  return <span>Engagement {record ? `"${record.title}"` : ''}</span>;
};

const redirectShow = (id) => `/Engagement/${id}/show`;

// EditForm must be a children component to be able to access the props from the Edit component and from there, the record (here the Engagement)
// We need it to then set the current engagement.hcpType
const EditForm = (properties) => {
  const record = useRecordContext(properties);

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectedHcpType, setSelectedHcpType] = useState(record.hcpType);

  const handleFileUpload = async (link, id = null) => {
    let actualLink = link;
    setUploadedFiles((previousState) => [
      ...previousState,
      {
        link: actualLink,
        ...(id ? { id, new: false } : { new: true }),
      },
    ]);
  };

  const handleFileMove = (index, newIndex) => {
    let copiedFiles = [...uploadedFiles];
    const removedElement = copiedFiles.splice(index, 1)[0];
    copiedFiles.splice(newIndex, 0, removedElement);
    setUploadedFiles(copiedFiles);
  };

  const handleFileDelete = (index) => {
    const copiedFiles = uploadedFiles.filter((_, index_) => index !== index_);
    setUploadedFiles(copiedFiles);
  };

  const { basePath } = properties;
  const [mutate] = useUpdate();
  const redirectTo = useRedirect();
  const notify = useNotify();
  const save = useCallback(
    async ({ ...values }, redirect) => {
      try {
        const res = await mutate(
          {
            type: 'update',
            resource: 'Engagement',
            payload: {
              data: {
                ...values,
                files: uploadedFiles,
              },
            },
          },
          { returnPromise: true }
        );
        notify('ra.notification.updated', 'info', {
          smart_count: 1,
        });
        redirectTo(redirect, basePath, res.data.id, res.data);
      } catch (error) {
        console.log(error);
      }
    },
    [mutate, uploadedFiles]
  );

  return (
    <TabbedForm save={save} redirect={redirectShow}>
      <FormTab label="Info">
        <h4
          style={{
            marginBottom: 0,
            borderBottom: '1px solid #000',
            width: '100%',
          }}
        >
          Main details
        </h4>
        <Labeled label="Id">
          <TextField source="id" />
        </Labeled>
        <TextInput source="title" validate={validateName} fullWidth />
        <RichTextInput source="summary" fullWidth />
        <SelectInput source="state" choices={stateChoices} fullWidth />
        <ReferenceInput source="companyId" reference="Company">
          <SelectInput optionText="name" perPage={1000} allowEmpty fullWidth />
        </ReferenceInput>
        <BooleanInput source="isBlinded" />
        <h4
          style={{
            marginBottom: 0,
            borderBottom: '1px solid #000',
            width: '100%',
          }}
        >
          Survey Sparrow details
        </h4>
        <TextInput
          source="link"
          options={{
            label: 'Survey Sparrow Share Link',
          }}
          fullWidth
        />
        <NumberInput
          source="surveySparrowId"
          options={{ label: 'Survey Sparrow ID' }}
          fullWidth
        />

        <h4
          style={{
            marginBottom: 0,
            borderBottom: '1px solid #000',
            width: '100%',
          }}
        >
          Eligibility details
        </h4>
        <DateInput source="endsAt" fullWidth />

        {/* TODO: replace with a shared HcpTypes */}
        <SelectInput
          source="hcpType"
          choices={HcpTypeChoices}
          fullWidth
          onChange={(e) => setSelectedHcpType(e.target.value)}
          required
        />

        {selectedHcpType === 'physician' && (
          <SelectArrayInput
            source="allowedSpecialties"
            choices={SpecialtyChoices}
            fullWidth
          />
        )}
        <BooleanInput source="hasOtherEligibilityCriteria" />
        <RichTextInput source="otherEligibilityCriteria" fullWidth />

        <h4
          style={{
            marginBottom: 0,
            borderBottom: '1px solid #000',
            width: '100%',
          }}
        >
          Effort & Payout
        </h4>
        <NumberInput source="estimatedTime" fullWidth />
        <NumberInput source="payoutValue" fullWidth />
        <NumberInput source="rewardValue" fullWidth />

        <h4
          style={{
            marginBottom: 0,
            borderBottom: '1px solid #000',
            width: '100%',
          }}
        >
          Referrals
        </h4>
        <NumberInput source="referralPayout" fullWidth />
        <BooleanInput source="referralEnabled" fullWidth />
        <NumberInput source="referralExpiration" fullWidth />
        <NumberInput source="referralRewardValue" fullWidth />

        <h4
          style={{
            marginBottom: 0,
            borderBottom: '1px solid #000',
            width: '100%',
          }}
        >
          Results and Media
        </h4>
        <TextInput source="mediaDescription" fullWidth />
        <KOToggleButton
          source="type"
          values={{
            video: 'Video',
            document: 'Images',
            attachment: 'Attachment Link',
          }}
          defaultValue="plain"
        />

        <FormDataConsumer>
          {({ formData }) => {
            return formData.type === 'video' ? (
              <>
                <TextInput
                  source="mediaLink"
                  options={{
                    label: 'Vimeo Media Link',
                  }}
                  fullWidth
                />
              </>
            ) : formData.type === 'document' ? (
              <>
                <FileUploadInput
                  source="EngagementFiles"
                  label="Documents"
                  accept="image/*"
                  multiple={true}
                  handleFileUpload={handleFileUpload}
                  handleFileMove={handleFileMove}
                  handleFileDelete={handleFileDelete}
                >
                  <ImageField source="link" />
                </FileUploadInput>
              </>
            ) : formData.type === 'attachment' ? (
              <TextInput source="attachmentLink" fullWidth />
            ) : (
              <></>
            );
          }}
        </FormDataConsumer>
      </FormTab>
      <FormTab label={'Results'}>
        <TextInput
          source="resultsLink"
          options={{ label: 'Results Link' }}
          fullWidth
        />
      </FormTab>
    </TabbedForm>
  );
};

// Cf explanation above
export const EngagementEdit = (properties) => {
  return (
    <Edit title={<EngagementName />} {...properties}>
      <EditForm />
    </Edit>
  );
};
