import buildGraphQLProvider from 'ra-data-graphql-simple';
import apolloClient from './apolloClient';
import buildQuery from './buildQuery';
import localDataProvider from './localDataProvider';

const combinedDataProvider = async () => {
  const graphQLProvider = await buildGraphQLProvider({
    client: apolloClient,
    buildQuery: buildQuery,
  });

  return {
    getList: (resource, parameters) => {
      if (resource === 'AdBoards') {
        return localDataProvider.getList(resource, parameters);
      }
      return graphQLProvider.getList(resource, parameters);
    },
    getOne: (resource, parameters) => {
      if (resource === 'AdBoards') {
        return localDataProvider.getOne(resource, parameters);
      }
      return graphQLProvider.getOne(resource, parameters);
    },
    getMany: (resource, parameters) =>
      graphQLProvider.getMany(resource, parameters),
    getManyReference: (resource, parameters) =>
      graphQLProvider.getManyReference(resource, parameters),
    create: (resource, parameters) => {
      if (resource === 'AdBoards') {
        return localDataProvider.create(resource, parameters);
      }
      return graphQLProvider.create(resource, parameters);
    },
    update: (resource, parameters) => {
      if (resource === 'AdBoards') {
        return localDataProvider.update(resource, parameters);
      }
      return graphQLProvider.update(resource, parameters);
    },
    updateMany: (resource, parameters) =>
      graphQLProvider.updateMany(resource, parameters),
    delete: (resource, parameters) =>
      graphQLProvider.delete(resource, parameters),
    deleteMany: (resource, parameters) => {
      if (resource === 'AdBoards') {
        return localDataProvider.deleteMany(resource, parameters);
      }
      return graphQLProvider.deleteMany(resource, parameters);
    },
  };
};

export default combinedDataProvider;
