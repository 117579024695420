import React from 'react';

import { BooleanField, SimpleShowLayout, TextField } from 'react-admin';

export const ShowNurseProfile = () => {
  return (
    <SimpleShowLayout>
      <h3>Nurse</h3>
      <h4
        style={{
          marginBottom: 0,
          borderBottom: '1px solid #000',
          width: '100%',
        }}
      >
        Practice
      </h4>
      <TextField source="occupation" />
      <TextField source="otherOccupation" />
      <TextField source="practiceSetting" />
      <TextField source="otherPracticeSetting" />
      <TextField source="specialty" />
      <TextField source="practiceSettingDetail" />
      <TextField source="otherPracticeSettingDetail" />
      <TextField source="employmentStatus" />
      <TextField source="otherDetails" />
      <h4
        style={{
          marginBottom: 0,
          borderBottom: '1px solid #000',
          width: '100%',
        }}
      >
        License
      </h4>
      <TextField source="licenseNumber" />
      <TextField source="practiceLicenseYear" />
      <h4
        style={{
          marginBottom: 0,
          borderBottom: '1px solid #000',
          width: '100%',
        }}
      >
        Work Email
      </h4>
      <TextField source="workEmail" />
      <BooleanField source="workEmailVerified" />
    </SimpleShowLayout>
  );
};
