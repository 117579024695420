import { AdBoardInviteDto } from '@keyops-hcp/dtos';
import { Close, Edit } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
} from '@mui/material';
import React, { useState } from 'react';
import { useRecordContext } from 'react-admin';

import { updateOneAdBoardInvite } from '../../../../api/adboard-invites-api';

export const EditAdBoardInviteDialog = ({
  onSave = () => {},
}: {
  onSave?: () => void;
}) => {
  const [open, setOpen] = useState(false);
  const invite = useRecordContext<AdBoardInviteDto>();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton onClick={handleClickOpen} title={`Edit ${invite.role}`}>
        <Edit />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const updateDto = Object.fromEntries(formData.entries());
            updateDto.adBoardId = invite.adBoardId;
            updateDto.id = invite.id;
            updateDto.role = invite.role;
            await updateOneAdBoardInvite(updateDto);
            onSave();
            handleClose();
          },
        }}
      >
        <DialogTitle>Edit {invite.role}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <Close />
        </IconButton>
        <DialogContent>
          <Stack spacing={2} width={500}>
            <TextField
              InputProps={{
                readOnly: true,
              }}
              id="email"
              name="email"
              label="Email Address"
              type="email"
              fullWidth
              variant="outlined"
              defaultValue={invite.invitee.email}
            />
            <TextField
              InputProps={{
                readOnly: invite.type === 'in-network',
              }}
              id="firstName"
              name="firstName"
              label="First Name"
              type="text"
              fullWidth
              variant="outlined"
              defaultValue={invite.invitee.firstName}
            />
            <TextField
              InputProps={{
                readOnly: invite.type === 'in-network',
              }}
              id="lastName"
              name="lastName"
              label="Last Name"
              type="text"
              fullWidth
              variant="outlined"
              defaultValue={invite.invitee.lastName}
            />
            {invite.role === 'participant' && (
              <TextField
                id="honorarium"
                name="honorarium"
                label="Honorarium"
                type="number"
                fullWidth
                variant="outlined"
                InputProps={{ inputProps: { min: 0, max: 10000 } }}
                defaultValue={invite.honorarium}
              />
            )}
            {invite.role === 'participant' && (
              <TextField
                id="rewards"
                name="rewards"
                label="Rewards"
                type="number"
                fullWidth
                variant="outlined"
                InputProps={{ inputProps: { min: 0, max: 10000 } }}
                defaultValue={invite.rewards}
              />
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" type="submit">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
