import LinkIcon from '@mui/icons-material/Link';

import OnboardingLinkList from './components/List';
import OnboardingLinkEdit from './components/Edit';
import OnboardingLinkCreate from './components/Create';

const resource = {
  list: OnboardingLinkList,
  edit: OnboardingLinkEdit,
  create: OnboardingLinkCreate,
  icon: LinkIcon,
};

export default resource;
