import { useMutation } from '@keyops-hcp/ui-components';
import { NotificationType } from 'react-admin';
import {
  SectionDto,
  SurveyAdBoardSectionDto,
  SurveyDto,
} from '@keyops-hcp/dtos';
import { useUpdateSectionContent } from './useUpdateSectionContent';
import { updateOneSurvey } from '../api/surveys';

export const useUpdateSurveySection = (
  section: SurveyAdBoardSectionDto,
  setEditSection: React.Dispatch<React.SetStateAction<SectionDto>>,
  notify: (
    message: string | React.ReactNode,
    options?: NotificationOptions & {
      type?: NotificationType;
    },
  ) => void,
  setProcessing: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const { mutate: updateSectionContent } =
    useUpdateSectionContent(setEditSection);

  return useMutation<SurveyDto, unknown, { surveySparrowId: string }>(
    async ({ surveySparrowId }): Promise<SurveyDto> => {
      // First update the Survey
      setProcessing(true);

      const updatedSurvey = await updateOneSurvey(section.content.surveyId, {
        surveySparrowId,
      });

      return updatedSurvey;
    },
    {
      onSuccess: (updatedSurvey) => {
        if (updatedSurvey) {
          try {
            // Then update the section (and re-set the section currently under edition)
            updateSectionContent({
              sectionId: section.id,
              content: updatedSurvey.id,
            });
            notify('Survey updated successfully', { type: 'success' });
          } catch (error) {
            console.error('Failed to update section:', error);
          } finally {
            setProcessing(false);
          }
        }
      },
      onError: (error) => {
        console.error(error);
        notify('Failed to update the survey', { type: 'error' });
      },
    },
  );
};
