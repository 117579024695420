// External Dependencies
import ImageIcon from '@mui/icons-material/Image';

// Internal Dependencies
import EngagementFilesList from './components/List';

const resource = {
  list: EngagementFilesList,
  icon: ImageIcon,
};

export default resource;
