import { AdBoardSectionGroupDto } from '@keyops-hcp/dtos';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { Button } from 'react-admin';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import React, { useState } from 'react';
import { useNotify } from 'react-admin';
import { useDeleteAdBoardSectionGroup } from '../../../../custom-hooks/useDeleteAdBoardSectionGroup';

export const DeleteSectionGroupDialog = ({
  sectionGroup,
}: {
  sectionGroup: AdBoardSectionGroupDto;
}) => {
  const notify = useNotify();
  const [open, setOpen] = useState(false);
  const { mutate: deleteSectionGroup } = useDeleteAdBoardSectionGroup(notify);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onDeleteSection = async () => {
    if (sectionGroup) {
      await deleteSectionGroup(sectionGroup.id);
    } else {
      console.error(
        'trying to delete a section group, but no section group available.',
      );
    }
    handleClose();
  };

  return (
    <>
      <IconButton
        onClick={handleClickOpen}
        sx={{ textTransform: 'none' }}
        title="Delete section"
      >
        <DeleteOutlineIcon sx={{ color: '#1976d2' }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="delete-section-group-dialog"
      >
        <DialogTitle id="delete-section-dialog">Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-section-dialog-description">
            Deleting this will delete the entire section, including all
            activities and content. Are you sure you’d like to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: 3, pt: 0 }}>
          <Button
            label="Cancel"
            size={'medium'}
            variant={'outlined'}
            onClick={handleClose}
            sx={{ textTransform: 'initial' }}
          />
          <Button
            label="Yes, delete section"
            size={'medium'}
            variant={'contained'}
            onClick={() => {
              onDeleteSection();
              handleClose();
            }}
            sx={{ textTransform: 'initial' }}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};
