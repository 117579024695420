import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const FileText = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      width='43'
      height='43'
      viewBox='0 0 43 43'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.3333 30.4582C14.3333 29.4687 15.1355 28.6665 16.125 28.6665H26.875C27.8645 28.6665 28.6667 29.4687 28.6667 30.4582C28.6667 31.4477 27.8645 32.2498 26.875 32.2498H16.125C15.1355 32.2498 14.3333 31.4477 14.3333 30.4582Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.3333 23.2917C14.3333 22.3022 15.1355 21.5 16.125 21.5H26.875C27.8645 21.5 28.6667 22.3022 28.6667 23.2917C28.6667 24.2812 27.8645 25.0833 26.875 25.0833H16.125C15.1355 25.0833 14.3333 24.2812 14.3333 23.2917Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.3333 16.1252C14.3333 15.1357 15.1355 14.3335 16.125 14.3335H17.9167C18.9062 14.3335 19.7083 15.1357 19.7083 16.1252C19.7083 17.1147 18.9062 17.9168 17.9167 17.9168H16.125C15.1355 17.9168 14.3333 17.1147 14.3333 16.1252Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.3333 7.16683C12.3543 7.16683 10.75 8.77114 10.75 10.7502V32.2502C10.75 34.2292 12.3543 35.8335 14.3333 35.8335H28.6667C30.6457 35.8335 32.25 34.2292 32.25 32.2502V15.8178C32.25 15.3426 32.0612 14.8869 31.7252 14.5509L24.866 7.6916C24.53 7.35559 24.0742 7.16683 23.5991 7.16683H14.3333ZM7.16667 10.7502C7.16667 6.79212 10.3753 3.5835 14.3333 3.5835H23.5991C25.0246 3.5835 26.3918 4.14979 27.3998 5.1578L34.259 12.0171C35.267 13.0251 35.8333 14.3922 35.8333 15.8178V32.2502C35.8333 36.2082 32.6247 39.4168 28.6667 39.4168H14.3333C10.3753 39.4168 7.16667 36.2082 7.16667 32.2502V10.7502Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M25.0833 5.375V8.95833C25.0833 11.9269 27.4898 14.3333 30.4583 14.3333H34.0417V17.9167H30.4583C25.5108 17.9167 21.5 13.9059 21.5 8.95833V5.375H25.0833Z'
        fill='currentColor'
      />
    </svg>
  </SvgIcon>
);
