import React from 'react';
import { Box, Typography } from '@mui/material';
import { CloseButton } from '../CloseButton';

interface DiscussionDrawerHeaderProps {
  title: string;
  toggleDiscussionDrawer: (isDrawerOpen: boolean) => void;
}

export const DiscussionDrawerHeader = ({
  title,
  toggleDiscussionDrawer,
}: DiscussionDrawerHeaderProps): JSX.Element => {
  return (
    <Box p={2} display={'flex'} justifyContent={'left'} alignItems={'center'}>
      <CloseButton onClick={() => toggleDiscussionDrawer(false)} />
      <Typography pl={'16px'} variant='body1' fontWeight={500}>
        {title}
      </Typography>
    </Box>
  );
};
