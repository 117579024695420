import React from 'react';
import { Box, Grid } from '@mui/material';
import { AdBoardDto } from '@keyops-hcp/dtos';

import { ModeratorsSection } from './components/ModeratorsSection';
import { ActivitiesSection } from './components/ActivitiesSection';

interface AdBoardActivitiesContentProps {
  adBoard: AdBoardDto;
}

export const AdBoardActivitiesContent = ({
  adBoard,
}: AdBoardActivitiesContentProps) => {
  return (
    <Box mt={2}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <ActivitiesSection adBoard={adBoard} />
        </Grid>
        <Grid item xs={12} md={4}>
          <ModeratorsSection adBoard={adBoard} />
        </Grid>
      </Grid>
    </Box>
  );
};
