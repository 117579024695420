import React from 'react';
import { BulkDeleteButton } from 'react-admin';

const CustomBulkDeleteButton = (properties) => {
  return (
    <BulkDeleteButton
      confirmTitle={`Delete all the selected items?`}
      confirmContent={'Are you sure you want to delete these items?'}
      undoable={false}
      {...properties}
    />
  );
};

export default CustomBulkDeleteButton;
