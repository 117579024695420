import { Box, Stack, Typography } from '@mui/material';
import {
  ChipField,
  DateField,
  FunctionField,
  Loading,
  TextField,
  useEditContext,
  WrapperField,
} from 'react-admin';
import React from 'react';
import { Datagrid, ListContextProvider, useList } from 'react-admin';
import { BulkSendInviteButton } from './participants/BulkSendInviteButton';
import { useInvitesByAdBoardId } from '../../../custom-hooks/useInvitesByAdBoardId';
import { useNavigate } from 'react-router-dom';
import { CreateAdBoardInviteDialog } from './participants/CreateAdBoardInviteDialog';
import { EditAdBoardInviteDialog } from './participants/EditAdBoardInviteDialog';
import { DeleteAdBoardInviteDialog } from './participants/DeleteAdBoardInviteDialog';
import { CopyPropertyButton } from '../../../components/CopyPropertyButton';
import { getUserNameDisplay } from '../../../utils/user';

export const ModeratorTab = () => {
  const navigate = useNavigate();
  const { record } = useEditContext();
  const {
    data: invites = [],
    isLoading,
    isError,
    refetch,
  } = useInvitesByAdBoardId(record.id);
  const moderatorInvites = invites.filter(
    (invite) => invite.role === 'moderator',
  );
  const listContext = useList({
    data: moderatorInvites,
    sort: { field: 'invitee.firstName', order: 'ASC' },
  });

  if (isLoading) return <Loading />;
  if (isError) navigate('/AdBoards');

  const bulkActions = (
    <>
      <BulkSendInviteButton role="moderator" />
    </>
  );

  return (
    <Box alignItems="center" justifyContent="space-between" width="100%">
      <Box padding={0}>
        <Typography fontWeight={600}>Moderators</Typography>
        <Typography>
          Select one or more moderators to send invitations to.
        </Typography>
      </Box>
      <Box display="flex" justifyContent="flex-end" padding={1} margin={1}>
        <CreateAdBoardInviteDialog role="moderator" onSave={refetch} />
      </Box>
      <ListContextProvider value={listContext}>
        <Datagrid bulkActionButtons={bulkActions}>
          <WrapperField source="participant" sortBy="invitee.firstName">
            <Stack>
              <Stack direction="row">
                <FunctionField
                  source="invitee"
                  render={(record) => {
                    return getUserNameDisplay(record);
                  }}
                />
              </Stack>
              <Stack direction="row" display="inline-flex" alignItems="center">
                <TextField source="invitee.email" fontSize={12} />
                <CopyPropertyButton property="invitee.email" iconSize={12} />
              </Stack>
            </Stack>
          </WrapperField>
          <ChipField source="state" label="Invite Status" textAlign="center" />
          <DateField
            source="sentAt"
            label="Date invited"
            sortable={false}
            options={{
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            }}
          />
          <WrapperField label="Actions" sortable={false} textAlign="center">
            <Stack direction="row" justifyContent="flex-end">
              <CopyPropertyButton property="id" />
              <EditAdBoardInviteDialog onSave={refetch} />
              <DeleteAdBoardInviteDialog onSave={refetch} />
            </Stack>
          </WrapperField>
        </Datagrid>
      </ListContextProvider>
    </Box>
  );
};
