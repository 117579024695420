// External Dependencies
import * as React from 'react';
import {
  Filter,
  List,
  Datagrid,
  TextField,
  EditButton,
  TextInput,
} from 'react-admin';

// Internal Dependencies
import CustomBulkDeleteButton from '../../../components/CustomBulkDeleteButton';

const CompanyFilter = (properties) => (
  <Filter {...properties}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

const CompanyBulkActionButtons = (properties) => (
  <React.Fragment>
    {/* <ResetViewsButton label="Reset Views" {...props} /> */}
    {/* default bulk delete action */}
    <CustomBulkDeleteButton {...properties} />
  </React.Fragment>
);

const CompanyList = (properties) => (
  <List
    exporter={false}
    filters={<CompanyFilter />}
    bulkActionButtons={<CompanyBulkActionButtons />}
    sort={{ field: 'id', order: 'DESC' }}
    {...properties}
  >
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
      <EditButton />
    </Datagrid>
  </List>
);

export default CompanyList;
