// External Dependencies
import VisibilityIcon from '@mui/icons-material/Visibility';

// Internal Dependencies
import EngagementList from './components/List';
import EngagementShow from './components/Show';
import EngagementCreate from './components/Create';
import { EngagementEdit } from './components/Edit';

const resource = {
  list: EngagementList,
  show: EngagementShow,
  create: EngagementCreate,
  edit: EngagementEdit,
  icon: VisibilityIcon,
};

export default resource;
