// External Dependencies
import React from 'react';
import {
  Filter,
  List,
  Datagrid,
  TextField,
  EditButton,
  SelectInput,
  ReferenceInput,
  ReferenceField,
  ChipField,
  DateField,
} from 'react-admin';

// Internal Dependencies
import CustomBulkDeleteButton from '../../../components/CustomBulkDeleteButton';
import { UserTypeChoices } from '../../../utils/choices';

const EngagementFilter = (properties) => {
  return (
    <Filter {...properties}>
      <ReferenceInput perPage={1000} source="companyId" reference="Company">
        <SelectInput label="name" source="name" optionText="name" />
      </ReferenceInput>
      <SelectInput source="hcpType" choices={UserTypeChoices} fullWidth />
    </Filter>
  );
};

const EngagementBulkActionButtons = (properties) => (
  <React.Fragment>
    <CustomBulkDeleteButton {...properties} />
  </React.Fragment>
);

const EngagementList = (properties) => (
  <List
    exporter={false}
    filters={<EngagementFilter />}
    perPage={25}
    sort={{ field: 'updatedAt', order: 'DESC' }}
    {...properties}
  >
    <Datagrid
      rowClick="show"
      bulkActionButtons={<EngagementBulkActionButtons />}
    >
      <TextField source="id" />
      <TextField source="title" />
      <ReferenceField label="Company" source="companyId" reference="Company">
        <TextField source="name" />
      </ReferenceField>
      <ChipField source="state" />
      <TextField source="hcpType" />
      <TextField source="payoutValue" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
      {/* <TextField source="matchesCount" /> */}
      <EditButton />
    </Datagrid>
  </List>
);

export default EngagementList;
