import axios from 'axios';
import { SurveyDto } from '@keyops-hcp/dtos';

import { getAPIUrl } from '../utils/env';

const apiUrl = getAPIUrl();

export const createSurvey = async (
  surveySparrowId: string,
): Promise<SurveyDto> => {
  try {
    const response = await axios.post(
      `${apiUrl}/surveys`,
      { surveySparrowId },
      {
        headers: {
          keyops__accesstokens: localStorage.getItem('token'),
          userType: 'admin',
        },
      },
    );
    return response.data.data;
  } catch (error) {
    console.error('Error creating survey:', error);
    throw new Error('Failed to create survey');
  }
};

export const updateOneSurvey = async (
  id: string,
  fieldsToUpdate: Partial<SurveyDto>,
): Promise<SurveyDto> => {
  try {
    const response = await axios.patch(
      `${apiUrl}/surveys/${id}`,
      fieldsToUpdate,
      {
        headers: {
          keyops__accesstokens: localStorage.getItem('token'),
          userType: 'admin',
        },
      },
    );
    return response.data.data;
  } catch (error) {
    console.error(
      `Error update one survey with id: ${id} and fieldsToUpdate: ${fieldsToUpdate}`,
      error,
    );
    throw error;
  }
};
