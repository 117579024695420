// External Dependencies
import React from 'react';

import {
  Edit,
  TextInput,
  BooleanInput,
  SimpleForm,
  required,
  maxLength,
  minLength,
} from 'react-admin';

const validateName = [required(), minLength(2), maxLength(255)];

const FeatureSwitchesEdit = (properties) => {
  return (
    <Edit title={<div />} {...properties}>
      <SimpleForm>
        <TextInput source="id" validate={validateName} fullWidth />
        <BooleanInput source="enabled" defaultValue={true} />
      </SimpleForm>
    </Edit>
  );
};

export default FeatureSwitchesEdit;
