import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { FiChevronLeft } from 'react-icons/fi';
import { useNavigate } from 'react-router';

import i18next from '../../languages/i18n.config';
import { DASHBOARD } from '../../utils/physician-ui-routes';
import { TextButton } from '../TextButton';

export const BackToDashboardButton = ({
  title,
  showBackButton,
}: {
  title?: string;
  showBackButton?: boolean;
}) => {
  const navigate = useNavigate();
  const { t } = i18next;

  const { palette } = useTheme();

  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='left'
      paddingX={{ xs: 2, md: 8 }}
      paddingY={2}
    >
      <TextButton
        aria-label='back'
        startIcon={<FiChevronLeft />}
        onClick={() => navigate(DASHBOARD)}
        sx={{
          '&:hover': {
            color: palette.keyops.blue.midnightBlue,
            backgroundColor: 'transparent',
          },
          p: 0,
          ...(!showBackButton && { visibility: 'hidden' }),
        }}
      >
        <Typography variant='body2' color={'inherit'}>
          {title ?? t('buttons.backToDashboardButton')}
        </Typography>
      </TextButton>
    </Box>
  );
};
