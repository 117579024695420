import { AdBoardParticipantsDto } from '@keyops-hcp/dtos';
import { formatAuthorName } from '../common-utils';
import { CustomTagData } from '../../components/MentionInput';

export const filterParticipants = (
  allParticipantsData: AdBoardParticipantsDto[]
): AdBoardParticipantsDto[] => {
  return allParticipantsData?.filter(
    (participant) => participant.role === 'participant'
  );
};

export const filterModerators = (
  allParticipantsData: AdBoardParticipantsDto[]
): AdBoardParticipantsDto[] => {
  return allParticipantsData?.filter(
    (participant) => participant.role === 'moderator'
  );
};

/**
 *
 * @param {AdBoardParticipantsDto[]} allParticipantsData
 * @param {number} currentUserId
 * @returns  {{id: string,value: string;}[]}
 */
export const getParticipantsUserListForMentions = (
  allParticipantsData: AdBoardParticipantsDto[],
  currentUserId: number
): CustomTagData[] => {
  return allParticipantsData
    .filter((participant) => participant.userId !== currentUserId)
    .map((participant, i) => ({
      id: participant.userId.toString(),
      value: formatAuthorName(
        [participant.user.firstName, participant.user.lastName],
        false
      ),
    }));
};
