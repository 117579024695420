import React, { useRef } from 'react';
import ReactPlayer from 'react-player';
import { Stack } from '@mui/material';

import { Video } from '../Video';
import { VideoComments } from '../VideoComments';
import { AdBoardSectionTaskInfo } from '../AdBoardSectionTaskInfo';
import i18next from '../../languages/i18n.config';
import { VideoDto } from '@keyops-hcp/dtos';
import { VideoDiscussion } from '../VideoDiscussion';

export const VideoWrapper = ({
  videoContent,
  requiredCommentCount,
}: {
  videoContent: VideoDto;
  requiredCommentCount: number;
}) => {
  const playerRef = useRef<ReactPlayer | null>(null);

  // TODO: rm VideoComments when no need anymore to check if there is a discussionId in videoContent
  return (
    <Stack spacing={2}>
      <AdBoardSectionTaskInfo>
        {i18next.t('video.infoText', { count: requiredCommentCount })}
      </AdBoardSectionTaskInfo>
      <Video videoId={videoContent?.id} ref={playerRef} />
      {videoContent?.discussionId ? (
        <VideoDiscussion
          discussionId={videoContent?.discussionId}
          playerRef={playerRef}
          requiredCount={requiredCommentCount}
        />
      ) : (
        <VideoComments
          videoId={videoContent?.id}
          ref={playerRef}
          requiredCount={requiredCommentCount}
        />
      )}
    </Stack>
  );
};
