import axios from 'axios';
import { AttachmentDto } from '@keyops-hcp/dtos';
import { AttachmentFormDto } from '@keyops-hcp/dtos';

import { getAPIUrl } from '../utils/env';

const apiUrl = getAPIUrl();

// TODO: put some of these calls in ui-components package
export const getAllAttachments = async (): Promise<AttachmentDto[]> => {
  try {
    const response = await axios.get(`${apiUrl}/attachments`, {
      headers: {
        keyops__accesstokens: localStorage.getItem('token'),
        userType: 'admin',
      },
    });
    return response.data.data;
  } catch (error) {
    console.error('Error fetching data getAllAttachments:', error);
    throw error;
  }
};

export const getOneAttachment = async (id: string): Promise<AttachmentDto> => {
  try {
    const response = await axios.get(`${apiUrl}/attachments/${id}`, {
      headers: {
        keyops__accesstokens: localStorage.getItem('token'),
        userType: 'admin',
      },
    });
    return response.data.data;
  } catch (error) {
    console.error('Error fetching data getOneAttachment:', error);
    throw error;
  }
};

// Specific to admin capabilities, do not put in ui-components
export const createOneAttachment = async (
  attachmentsToCreate: AttachmentFormDto,
): Promise<AttachmentDto> => {
  try {
    const formData = new FormData();
    if (attachmentsToCreate.title)
      formData.append('title', attachmentsToCreate.title);
    if (attachmentsToCreate.description)
      formData.append('description', attachmentsToCreate.description);
    if (attachmentsToCreate.readingTime)
      formData.append(
        'readingTime',
        attachmentsToCreate.readingTime.toString(),
      );
    formData.append('file', attachmentsToCreate.file);

    const response = await axios.post(`${apiUrl}/attachments`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        keyops__accesstokens: localStorage.getItem('token'),
        userType: 'admin',
      },
    });

    return response.data.data;
  } catch (error) {
    console.error('Error creating one attachment', error);
    throw error;
  }
};

// Specific to admin capabilities, do not put in ui-components
export const updateOneAttachment = async (
  attachmentId: string,
  attachmentToUpdate: AttachmentFormDto,
): Promise<AttachmentDto> => {
  try {
    // we cannot update the file in the current design
    const formData = new FormData();
    if (attachmentToUpdate.title)
      formData.append('title', attachmentToUpdate.title);
    if (attachmentToUpdate.description)
      formData.append('description', attachmentToUpdate.description);
    if (attachmentToUpdate.readingTime)
      formData.append('readingTime', attachmentToUpdate.readingTime.toString());

    const response = await axios.patch(
      `${apiUrl}/attachments/${attachmentId}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          keyops__accesstokens: localStorage.getItem('token'),
          userType: 'admin',
        },
      },
    );

    return response.data.data;
  } catch (error) {
    console.error('Error creating one attachment', error);
    throw error;
  }
};
