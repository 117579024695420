// External Dependencies
import * as React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  maxLength,
  minLength,
} from 'react-admin';

const validateName = [required(), minLength(2), maxLength(15)];

const CompanyCreate = (properties) => (
  <Create {...properties}>
    <SimpleForm>
      <TextInput source="name" validate={validateName} fullWidth />
    </SimpleForm>
  </Create>
);

export default CompanyCreate;
