// External Dependencies
import ToggleOnIcon from '@mui/icons-material/ToggleOn';

// Internal Dependencies
import FeatureSwitchesList from './components/List';
import FeatureSwitchesEdit from './components/Edit';

const resource = {
  list: FeatureSwitchesList,
  edit: FeatureSwitchesEdit,
  icon: ToggleOnIcon,
};

export default resource;
