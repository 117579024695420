import React from 'react';

import { SimpleShowLayout, TextField } from 'react-admin';

export const ShowPharmacistProfile = () => {
  return (
    <SimpleShowLayout>
      <h3>Pharmacist</h3>
      <h4
        style={{
          marginBottom: 0,
          borderBottom: '1px solid #000',
          width: '100%',
        }}
      >
        Practice
      </h4>
      <TextField source="pharmacistType" />
      <TextField source="practiceSetting" />
      <TextField source="otherPracticeSetting" />
      <TextField source="practiceSpecialty" />
      <TextField source="otherPracticeSpecialty" />
      <TextField source="retailChain" />
      <TextField source="otherRetailChain" />
      <TextField source="otherDetails" />
      <h4
        style={{
          marginBottom: 0,
          borderBottom: '1px solid #000',
          width: '100%',
        }}
      >
        License
      </h4>
      <TextField source="licenseNumber" />
      <TextField source="practiceLicenseYear" />
    </SimpleShowLayout>
  );
};
