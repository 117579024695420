import React from 'react';
import {
  AdBoardInviteContextDataDto,
  CreateInvitationContextDataDto,
  CreateRewardsTierContextDataDto,
  MessageCreatedContextDto,
  NotificationDto,
} from '@keyops-hcp/dtos';
import { EventType } from '@keyops-hcp/constants';
import i18next from '../languages/i18n.config';
import { Trans } from 'react-i18next';

export const formatDateRelative = (inputDate: Date): string => {
  const currentDate = new Date();
  const timeDiff = currentDate.getTime() - inputDate.getTime();
  const minuteDiff = Math.floor(timeDiff / (1000 * 60));
  const dayDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

  if (minuteDiff < 10) {
    return i18next.t(
      'notifications.notificationsList.notificationTimestamp.justNow'
    );
  } else if (dayDiff === 0) {
    return i18next.t(
      'notifications.notificationsList.notificationTimestamp.today'
    );
  } else if (dayDiff === 1) {
    return i18next.t(
      'notifications.notificationsList.notificationTimestamp.yesterday'
    );
  } else if (dayDiff < 30) {
    return i18next.t(
      'notifications.notificationsList.notificationTimestamp.daysAgo',
      { dayDiff }
    );
  } else {
    return i18next.t(
      'notifications.notificationsList.notificationTimestamp.aMonthAgo'
    );
  }
};

export interface DisplayNotification {
  id: string;
  url?: string;
  text: string | React.ReactElement;
  time: string;
  read: boolean;
}

export const getNotificationsCopy = (
  version: number,
  eventType: EventType,
  context:
    | CreateInvitationContextDataDto
    | CreateRewardsTierContextDataDto
    | AdBoardInviteContextDataDto
    | MessageCreatedContextDto
): string | React.ReactElement | undefined => {
  const inviteContext = context as AdBoardInviteContextDataDto;
  const role = inviteContext.role ?? 'participant';

  switch (eventType) {
    case 'SURVEY_INVITE':
    case 'SURVEY_INVITE_REMINDER':
      return (
        <Trans
          i18nKey={`notifications.notificationsList.notificationsCopy.v${version}.${eventType}`}
          values={{
            title: (context as CreateInvitationContextDataDto).engagementTitle,
          }}
          components={{ strong: <strong /> }}
        />
      );
    case 'SILVER_REWARD_TIER_ACHIEVED':
    case 'GOLD_REWARD_TIER_ACHIEVED':
    case 'PLATINUM_REWARD_TIER_ACHIEVED':
      return (
        <Trans
          i18nKey={`notifications.notificationsList.notificationsCopy.v${version}.REWARD_TIER_ACHIEVED`}
          values={{
            tier: i18next.options.interpolation.format(
              (context as CreateRewardsTierContextDataDto).tier,
              'capitalize'
            ),
          }}
          components={{ strong: <strong /> }}
        />
      );
    case 'ADBOARD_INVITE_SENT':
      return (
        <Trans
          i18nKey={`notifications.notificationsList.notificationsCopy.v${version}.${eventType}.${role}`}
          values={{
            title: inviteContext.adboardTitle,
            companyName: inviteContext.companyName,
          }}
          components={{ strong: <strong /> }}
        />
      );
    case 'ADBOARD_START':
      return (
        <Trans
          i18nKey={`notifications.notificationsList.notificationsCopy.v${version}.${eventType}`}
          values={{
            title: inviteContext.adboardTitle,
            companyName: inviteContext.companyName,
          }}
          components={{ strong: <strong /> }}
        />
      );

    case 'MESSAGE_CREATED':
      const messageContext = context as MessageCreatedContextDto;
      const interactionType = messageContext.type;
      return (
        <Trans
          i18nKey={`notifications.notificationsList.notificationsCopy.v${version}.${eventType}.${interactionType}`}
          values={{
            name: messageContext.name,
            title: messageContext.title,
          }}
          components={{ strong: <strong /> }}
        />
      );

    default:
      console.error(`eventType - ${eventType} : not recognized`);
      return undefined;
  }
};

export const generateDisplayNotifications = (
  notifications: NotificationDto[]
): DisplayNotification[] => {
  return notifications
    .map((notification) => ({
      id: notification.id,
      url: 'url' in notification.context ? notification.context.url : undefined,
      text:
        getNotificationsCopy(
          notification.version,
          notification.event.eventType,
          notification.context
        ) || '',
      time: formatDateRelative(new Date(notification.createdAt)),
      read: notification.read,
    }))
    .filter((notification) => notification.text);
};
