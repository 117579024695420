// External Dependencies
import * as React from 'react';
import { Route } from 'react-router-dom';
import { Admin, Resource, CustomRoutes } from 'react-admin';

// Internal Dependencies
import authProvider from './utils/authProvider';
import admins from './pages/Admins';
import companies from './pages/Companies';
import users from './pages/Users';
import invitations from './pages/Invitations';
import engagements from './pages/Engagements';
import engagementFiles from './pages/EngagementFiles';
import referral from './pages/Referral';
import payment from './pages/Payment';
import featureSwitches from './pages/FeatureSwitches';
import onboardingLink from './pages/OnboardingLink';
import physicianOnboarding from './pages/PhysicianOnboarding';
import rewardsTransactions from './pages/RewardsTransactions';
import rewardsPeriods from './pages/RewardsPeriods';
import rewardsTiers from './pages/RewardsTiers';
import notifications from './pages/Notifications';
import adboards from './pages/AdBoards';
import Dashboard from './pages/Dashboard';
import PasswordReset from './custom-routes/password-reset';
import Utils from './custom-routes/utils';
import { CustomLayout } from './components/Layout';

import combinedDataProvider from './utils/dataProvider';

const AdminView = () => {
  const [dataProvider, setDataProvider] = React.useState(null);
  React.useEffect(() => {
    const initializeDataProvider = async () => {
      const provider = await combinedDataProvider();
      setDataProvider(() => provider);
    };
    initializeDataProvider();
  }, []);

  if (!dataProvider) {
    return <div>Loading</div>;
  }
  return (
    <Admin
      authProvider={authProvider}
      dashboard={Dashboard}
      layout={CustomLayout}
      /*theme={myTheme}*/
      dataProvider={dataProvider}
      title={'KeyOps Admin Panel'}
    >
      <CustomRoutes>
        <Route path="/utils" element={<Utils />} />,
        <Route path="/password-reset" element={<PasswordReset />} />,
      </CustomRoutes>
      <Resource name="Admins" {...admins} />
      <CustomRoutes>
        <Route path="/utils" element={<Utils />} />,
        <Route path="/password-reset" element={<PasswordReset />} />,
      </CustomRoutes>

      <Resource name="Admins" {...admins} />
      <Resource name="Company" {...companies} />
      <Resource name="Users" {...users} />
      <Resource name="Engagement" {...engagements} />
      <Resource name="Invitation" {...invitations} />
      <Resource name="Referrals" {...referral} />
      <Resource name="Payment" {...payment} />
      <Resource
        name="RewardsTransaction"
        options={{ label: 'Rewards Transactions' }}
        {...rewardsTransactions}
      />
      <Resource
        name="RewardsPeriod"
        options={{ label: 'Rewards Period Trackers' }}
        {...rewardsPeriods}
      />
      <Resource
        name="RewardsTier"
        options={{ label: 'Rewards Tiers Awarded' }}
        {...rewardsTiers}
      />
      <Resource
        name="EngagementFile"
        options={{ label: 'Gallery' }}
        {...engagementFiles}
      />
      <Resource
        name="FeatureSwitches"
        options={{ label: 'Feature Switches' }}
        {...featureSwitches}
      />
      <Resource
        name="OnboardingLink"
        options={{ label: 'Onboarding Links' }}
        {...onboardingLink}
      />
      <Resource
        name="PhysicianOnboarding"
        options={{ label: 'Onboarding Link Tracker' }}
        {...physicianOnboarding}
      />
      <Resource name="Notification" {...notifications} />
      <Resource
        name="AdBoards"
        options={{ label: 'Ad Boards' }}
        {...adboards}
      />
    </Admin>
  );
};

export default AdminView;
