import React from 'react';
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  dateFormatter,
  timeFormatter,
  getVideoTime,
} from '@keyops-hcp/ui-components/dist/utils/datetime';
import { AdBoardEngagementFeedDto } from '@keyops-hcp/dtos';

const FeedList = ({ data }: { data: AdBoardEngagementFeedDto[] }) => {
  if (data.length === 0) return <>No data</>;

  const formatAttachmentOpenedText = (
    attachmentOpenedAt: Date,
    attachmentName: string,
  ) => {
    return `Opened ${attachmentName} on ${dateFormatter(
      attachmentOpenedAt.toString(),
    )} at ${timeFormatter(attachmentOpenedAt.toString())}.`;
  };

  const renderTruncatedText = (text: string, prefix?: string) => (
    <Tooltip placement="top" title={text} arrow>
      <Typography
        variant="body2"
        color="GrayText"
        sx={{
          maxWidth: '35ch',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {prefix ? `${prefix} ${text}` : text}
      </Typography>
    </Tooltip>
  );

  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="progress-feed-table">
        <TableBody>
          {data?.map((progressFeedItem, index) => (
            <TableRow
              key={`${progressFeedItem.id}-${index}`}
              sx={{ td: { border: 0, px: 0, verticalAlign: 'top' } }}
            >
              <TableCell width={200}>
                <Typography variant="body2">
                  {progressFeedItem?.participantName}
                </Typography>
                <Typography variant="body2" color="GrayText">
                  {dateFormatter(progressFeedItem.createdAt.toString())}
                </Typography>
              </TableCell>
              <TableCell>
                <Stack direction={'row'} spacing={2}>
                  {renderTruncatedText(
                    progressFeedItem.sectionGroupTitle || '',
                    `Section ${progressFeedItem.sectionGroupIndex + 1}:`,
                  )}
                  {renderTruncatedText(progressFeedItem.sectionTitle || '')}
                  {progressFeedItem?.sectionType === 'video' && (
                    <Typography variant="body2" color="GrayText">
                      {getVideoTime(progressFeedItem?.videoTimeStamp)}
                    </Typography>
                  )}
                </Stack>
                <Typography variant="body2" mt={1}>
                  {progressFeedItem.sectionType === 'attachment' &&
                  progressFeedItem.attachmentOpenedAt
                    ? formatAttachmentOpenedText(
                        progressFeedItem.attachmentOpenedAt,
                        progressFeedItem.attachmentName || 'Attachment',
                      )
                    : progressFeedItem.content}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FeedList;
