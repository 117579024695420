import React, { useState } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

interface MenuOption {
  component: React.ReactNode;
}

interface EditSectionMenuProperties {
  menuOptions: MenuOption[];
}

export const EditSectionMenu: React.FC<EditSectionMenuProperties> = ({
  menuOptions,
}) => {
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorElement);

  const handleMenuButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorElement(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorElement(null);
  };

  return (
    <>
      <IconButton
        aria-label="more"
        id="more-options"
        aria-controls={open ? 'more-options-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleMenuButtonClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="more-options-menu"
        anchorEl={anchorElement}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {menuOptions.map((option, index) => (
          <MenuItem key={index}>{option.component}</MenuItem>
        ))}
      </Menu>
    </>
  );
};
