// External Dependencies
import AssignmentIcon from '@mui/icons-material/Assignment';

// Internal Dependencies
import InvitationList from './components/List';
import InvitationShow from './components/Show';
import InvitationCreate from './components/Create';
import InvitationEdit from './components/Edit';

const resource = {
  list: InvitationList,
  show: InvitationShow,
  create: InvitationCreate,
  edit: InvitationEdit,
  icon: AssignmentIcon,
};

export default resource;
