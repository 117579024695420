import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { VideoDto } from '@keyops-hcp/dtos';
import { VideoForm } from './VideoForm';
import { UseMutateFunction } from '@keyops-hcp/ui-components';

interface VideoAccordionProperties {
  video?: VideoDto | null;
  title?: string;
  setTitle: (value: string) => void;
  description?: string;
  setDescription: (value: string) => void;
  requiredCommentCount?: number;
  setRequiredCommentCount: (value: number) => void;
  updateVideoSection: UseMutateFunction<
    VideoDto,
    unknown,
    {
      title?: string;
      description?: string;
    },
    unknown
  >;
}

const VideoAccordion: React.FC<VideoAccordionProperties> = ({
  video,
  title,
  setTitle,
  description,
  setDescription,
  requiredCommentCount,
  setRequiredCommentCount,
  updateVideoSection,
}) => {
  const [expanded, setExpanded] = useState<string | false>('video');
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Accordion
      variant={'outlined'}
      expanded={expanded === 'video'}
      onChange={handleChange('video')}
      sx={{ flexGrow: 1 }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="video-content"
        id="video-header"
      >
        <Box display={'flex'} flexDirection={'column'}>
          <Typography>Video</Typography>
          {expanded === false && (
            <Typography variant={'subtitle2'}>
              {title || 'Video title'}
            </Typography>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <VideoForm
          video={video}
          title={title}
          setTitle={setTitle}
          description={description}
          setDescription={setDescription}
          requiredCommentCount={requiredCommentCount}
          setRequiredCommentCount={setRequiredCommentCount}
          updateVideoSection={updateVideoSection}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default VideoAccordion;
