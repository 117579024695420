import PaymentIcon from '@mui/icons-material/Payment';

import PaymentList from './components/List';
import PaymentEdit from './components/Edit';

const resource = {
  list: PaymentList,
  edit: PaymentEdit,
  icon: PaymentIcon,
};

export default resource;
