export const tierTypesChoices = [
  { id: 'blue', name: 'blue' },
  { id: 'silver', name: 'silver' },
  { id: 'gold', name: 'gold' },
  { id: 'platinum', name: 'platinum' },
];

export const claimedChoices = [
  { id: false, name: 'Unclaimed' },
  { id: true, name: 'Claimed' },
];
