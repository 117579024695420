// External Dependencies
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';

// Internal Dependencies
import AdminList from './components/List';
import AdminCreate from './components/Create';
import AdminEdit from './components/Edit';

const resource = {
  list: AdminList,
  create: AdminCreate,
  edit: AdminEdit,
  icon: SupervisorAccountIcon,
};

export default resource;
