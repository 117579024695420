import React from 'react';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  Edit,
  EditProps,
  FormTab,
  TabbedForm,
  useEditController,
  useNotify,
  useRecordContext,
  useUpdate,
} from 'react-admin';
import { AdBoardDto } from '@keyops-hcp/dtos';

import DetailsEditTab from './DetailsEditTab';
import ContentEditTab from './ContentEditTab';
import { ParticipantTab } from './ParticipantTab';
import EngagementTab from './EngagementTab';
import { ModeratorTab } from './ModeratorTab';

const AdBoardEditTitle = () => {
  const record = useRecordContext();
  return <span>AdBoard {record ? `"${record.title}"` : ''}</span>;
};

const ActionBreadcrumbs = () => {
  const record = useRecordContext();
  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
      sx={{ py: 2 }}
    >
      <Link underline="hover" key="1" href={`/admin#/AdBoards`}>
        Ad Boards
      </Link>
      {record?.company?.name && (
        <Typography key="2">{record.company.name}</Typography>
      )}
      {record?.title && <Typography key="3">{record.title}</Typography>}
    </Breadcrumbs>
  );
};

const AdBoardEdit: React.FC<EditProps> = (properties) => {
  const { record } = useEditController(properties);

  // AdBoard Details tab handling
  const notify = useNotify();
  const [update] = useUpdate();

  const handleDetailsTabSubmit = (data: AdBoardDto) => {
    const wasAlreadyLive = record?.state === 'live';
    const isLiveState = data.state === 'live';
    const successMessage = `Ad board updated!`;
    const abortMessage = `Update cancelled`;

    // We don't want to alert if it was already live
    if (!wasAlreadyLive && isLiveState) {
      const alertMessage = `The ad board is about to start. Email notifications will be sent to all attendees. Proceed?`;
      const submitConfirmed = window.confirm(alertMessage);

      if (!submitConfirmed) {
        notify(abortMessage, { type: 'warning' });
        return;
      }
    }

    update('AdBoards', { data });
    notify(successMessage, { type: 'success' });
  };

  return (
    <Edit
      title={<AdBoardEditTitle />}
      actions={<ActionBreadcrumbs />}
      {...properties}
    >
      <TabbedForm toolbar={false} onSubmit={handleDetailsTabSubmit}>
        <FormTab label="Details">
          <DetailsEditTab />
        </FormTab>
        <FormTab label="Participants">
          <ParticipantTab />
        </FormTab>
        <FormTab label="Moderators">
          <ModeratorTab />
        </FormTab>
        <FormTab label="Content">
          <ContentEditTab />
        </FormTab>
        <FormTab label="Engagement">
          <EngagementTab />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default AdBoardEdit;
