import { NotificationType } from 'react-admin';
import {
  SectionDto,
  SurveyAdBoardSectionDto,
  SurveyDto,
} from '@keyops-hcp/dtos';
import { useMutation } from '@keyops-hcp/ui-components';
import { useUpdateSectionContent } from './useUpdateSectionContent';
import { createSurvey } from '../api/surveys';

export const useCreateSurveySection = (
  section: SurveyAdBoardSectionDto,
  setEditSection: React.Dispatch<React.SetStateAction<SectionDto>>,
  notify: (
    message: string | React.ReactNode,
    options?: NotificationOptions & {
      type?: NotificationType;
    },
  ) => void,
  setProcessing: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  // To be able to update the section content on success
  const { mutate: updateSectionContent } =
    useUpdateSectionContent(setEditSection);

  return useMutation<SurveyDto, unknown, { surveySparrowId: string }>(
    async ({ surveySparrowId }): Promise<SurveyDto> => {
      // First create the Survey
      setProcessing(true);
      const createdSurvey = await createSurvey(surveySparrowId);

      return createdSurvey;
    },
    {
      onSuccess: (createdSurvey) => {
        if (createdSurvey) {
          try {
            // Then update the section
            updateSectionContent({
              sectionId: section.id,
              content: createdSurvey.id,
            });
            notify('Survey created successfully', { type: 'success' });
          } catch (error) {
            console.error('Failed to update section:', error);
          } finally {
            setProcessing(false);
          }
        }
      },
      onError: (error) => {
        console.error(error);
        notify('Failed to create Survey', { type: 'error' });

        setProcessing(false);
      },
    },
  );
};
