import { forOwn } from 'lodash';
import { PhysicianDto, UserDto } from '@keyops-hcp/dtos';

import i18next from '../../src/languages/i18n.config';

export const getUrl = (route: string, params = {}) => {
  forOwn(params, (val, key) => {
    route = route.replace(`:${key}`, val);
  });
  return route;
};

export const isLocalOrDev = () => {
  return true;
  // TODO: uncomment as soon as we have a prod account, and don't forget the test!! ui-components/test/utils/common-utils.test.ts
  // const appUrl = window.location.href;
  // return appUrl.includes('localhost') || appUrl.includes('dev');
};

// If initialsOnly is true, return the first letter of the admin's name
// TODO: tests on discussions component
export const formatAuthorName = (
  names: string[],
  initialsOnly = false
): string => {
  let formattedName = '';

  names.forEach((name) => {
    if (!name && name != '') {
      formattedName = i18next.t('common.na');
      return;
    }
    formattedName += initialsOnly ? name?.charAt(0)?.toUpperCase() : name;

    if (!initialsOnly) formattedName += ' ';
  });

  return formattedName.trim();
};
export const getSpecialty = (userData: UserDto) => {
  if (userData.hcpType === 'physician') {
    const profile = userData.hcpProfile as PhysicianDto;
    return profile.specialty;
  }
  return [];
};
