// External Dependencies
import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Paper,
  Tab,
  Tabs,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';

// Internal Dependencies
import {
  Countries,
  Ethnicities,
  Genders,
  NurseEmploymentStatuses,
  NurseOccupations,
  NursePracticeSettingDetails,
  NursePracticeSettings,
  NurseSpecialties,
  PharmacistPracticeSettings,
  PharmacistPracticeSpecialties,
  PharmacistRetailChains,
  PharmacistTypes,
  PhysicianPracticeSettings,
  Specialties,
  StatesAndProvinces,
  UserStates,
} from '@keyops-hcp/constants';
import FileUploader from '../../components/fileUploader';
import {
  createPhysiciansBulkMutation,
  createPharmacistsBulkMutation,
  createInvitationBulkMutation,
  createRewardsTransactionsBulkMutation,
  createNursesBulkMutation,
} from '../../utils/graphql_lib';

const TabPanel = (properties) => {
  const { children, value, index, ...other } = properties;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const ScrollableView = ({ items }) => {
  return (
    <Box
      sx={{
        width: 150,
        height: 150,
        overflowY: 'auto',
        border: '1px solid #ccc',
        borderRadius: '4px',
      }}
    >
      <List dense>
        {items.map((item, index) => (
          <ListItem key={index}>
            <ListItemText primary={item} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

function PhysicianUploadCSVExample() {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow sx={{ '& .MuiTableCell-root': { fontWeight: 'bold' } }}>
            {/* user info */}
            <TableCell>first_name</TableCell>
            <TableCell>last_name</TableCell>
            <TableCell>email</TableCell>
            <TableCell>state</TableCell>
            <TableCell>city_of_practice</TableCell>
            <TableCell>province_of_practice</TableCell>
            <TableCell>year_of_birth</TableCell>
            <TableCell>gender</TableCell>
            <TableCell>sms_notification</TableCell>
            <TableCell>mobile_number</TableCell>
            <TableCell>involvement_committee</TableCell>
            <TableCell>country</TableCell>
            <TableCell>ethnicity</TableCell>
            {/* physician info */}
            <TableCell>specialty</TableCell>
            <TableCell>sub_specialty</TableCell>
            <TableCell>license_number</TableCell>
            <TableCell>practice_license_year</TableCell>
            <TableCell>practice_setting</TableCell>
            <TableCell>work_email</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>john</TableCell>
            <TableCell>doe</TableCell>
            <TableCell>john@keyops.io</TableCell>
            <TableCell>verified</TableCell>
            <TableCell>toronto</TableCell>
            <TableCell>ON</TableCell>
            <TableCell>1978</TableCell>
            <TableCell>man</TableCell>
            <TableCell>true</TableCell>
            <TableCell>905416647</TableCell>
            <TableCell>true</TableCell>
            <TableCell>CA</TableCell>
            <TableCell>no-response</TableCell>
            <TableCell>cardiology</TableCell>
            <TableCell>general_cardiology</TableCell>
            <TableCell>213129031</TableCell>
            <TableCell>2005</TableCell>
            <TableCell>community_hospital</TableCell>
            <TableCell>john@workemail.io</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>john</TableCell>
            <TableCell>doe</TableCell>
            <TableCell>john@keyops.io</TableCell>
            <TableCell>verified</TableCell>
            <TableCell>toronto</TableCell>
            <TableCell>ON</TableCell>
            <TableCell>1978</TableCell>
            <TableCell>man</TableCell>
            <TableCell>true</TableCell>
            <TableCell>905416647</TableCell>
            <TableCell>false</TableCell>
            <TableCell>CA</TableCell>
            <TableCell>no-response</TableCell>
            <TableCell>cardiology</TableCell>
            <TableCell>general_cardiology</TableCell>
            <TableCell>213129031</TableCell>
            <TableCell>2005</TableCell>
            <TableCell>community_hospital</TableCell>
            <TableCell>john@workemail.io</TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell>
              <ScrollableView items={UserStates} />
            </TableCell>
            <TableCell></TableCell>
            <TableCell>
              <ScrollableView items={StatesAndProvinces} />
            </TableCell>
            <TableCell></TableCell>
            <TableCell>
              <ScrollableView items={Genders} />
            </TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell>
              <ScrollableView items={Countries} />
            </TableCell>
            <TableCell>
              <ScrollableView items={Ethnicities} />
            </TableCell>
            <TableCell>
              <ScrollableView items={Specialties} />
            </TableCell>
            <TableCell>needs to be formatted in admin</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell>
              <ScrollableView items={PhysicianPracticeSettings} />
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function PharmacistUploadCSVExample() {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow sx={{ '& .MuiTableCell-root': { fontWeight: 'bold' } }}>
            {/* user info */}
            <TableCell>first_name</TableCell>
            <TableCell>last_name</TableCell>
            <TableCell>email</TableCell>
            <TableCell>state</TableCell>
            <TableCell>city_of_practice</TableCell>
            <TableCell>province_of_practice</TableCell>
            <TableCell>year_of_birth</TableCell>
            <TableCell>gender</TableCell>
            <TableCell>sms_notification</TableCell>
            <TableCell>mobile_number</TableCell>
            <TableCell>involvement_committee</TableCell>
            <TableCell>country</TableCell>
            <TableCell>ethnicity</TableCell>
            {/* pharmacist info */}
            <TableCell>practice_setting</TableCell>
            <TableCell>other_practice_setting</TableCell>
            <TableCell>practice_specialty</TableCell>
            <TableCell>other_practice_specialty</TableCell>
            <TableCell>retail_chain</TableCell>
            <TableCell>other_retail_chain</TableCell>
            <TableCell>pharmacist_type</TableCell>
            <TableCell>license_number</TableCell>
            <TableCell>practice_license_year</TableCell>
            <TableCell>other_details</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>john</TableCell>
            <TableCell>doe</TableCell>
            <TableCell>john@keyops.io</TableCell>
            <TableCell>verified</TableCell>
            <TableCell>toronto</TableCell>
            <TableCell>ON</TableCell>
            <TableCell>1978</TableCell>
            <TableCell>man</TableCell>
            <TableCell>true</TableCell>
            <TableCell>905416647</TableCell>
            <TableCell>false</TableCell>
            <TableCell>CA</TableCell>
            <TableCell>no-response</TableCell>
            <TableCell>community_retail</TableCell>
            <TableCell></TableCell>
            <TableCell>ambulatory_care</TableCell>
            <TableCell></TableCell>
            <TableCell>costco</TableCell>
            <TableCell></TableCell>
            <TableCell>full_time_pharmacist</TableCell>
            <TableCell>2238912389</TableCell>
            <TableCell>2012</TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>john</TableCell>
            <TableCell>doe</TableCell>
            <TableCell>john@keyops.io</TableCell>
            <TableCell>verified</TableCell>
            <TableCell>toronto</TableCell>
            <TableCell>ON</TableCell>
            <TableCell>1978</TableCell>
            <TableCell>man</TableCell>
            <TableCell>true</TableCell>
            <TableCell>905416647</TableCell>
            <TableCell>true</TableCell>
            <TableCell>CA</TableCell>
            <TableCell>no-response</TableCell>
            <TableCell>community_retail</TableCell>
            <TableCell></TableCell>
            <TableCell>ambulatory_care</TableCell>
            <TableCell></TableCell>
            <TableCell>costco</TableCell>
            <TableCell></TableCell>
            <TableCell>full_time_pharmacist</TableCell>
            <TableCell>2238912389</TableCell>
            <TableCell>2012</TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell>
              <ScrollableView items={UserStates} />
            </TableCell>
            <TableCell></TableCell>
            <TableCell>
              <ScrollableView items={StatesAndProvinces} />
            </TableCell>
            <TableCell></TableCell>
            <TableCell>
              <ScrollableView items={Genders} />
            </TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell>
              <ScrollableView items={Countries} />
            </TableCell>
            <TableCell>
              <ScrollableView items={Ethnicities} />
            </TableCell>
            <TableCell>
              <ScrollableView items={PharmacistPracticeSettings} />
            </TableCell>
            <TableCell></TableCell>
            <TableCell>
              <ScrollableView items={PharmacistPracticeSpecialties} />
            </TableCell>
            <TableCell></TableCell>
            <TableCell>
              <ScrollableView items={PharmacistRetailChains} />
            </TableCell>
            <TableCell></TableCell>
            <TableCell>
              <ScrollableView items={PharmacistTypes} />
            </TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function NurseUploadCSVExample() {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow sx={{ '& .MuiTableCell-root': { fontWeight: 'bold' } }}>
            {/* user info */}
            <TableCell>first_name</TableCell>
            <TableCell>last_name</TableCell>
            <TableCell>email</TableCell>
            <TableCell>state</TableCell>
            <TableCell>city_of_practice</TableCell>
            <TableCell>province_of_practice</TableCell>
            <TableCell>year_of_birth</TableCell>
            <TableCell>gender</TableCell>
            <TableCell>sms_notification</TableCell>
            <TableCell>mobile_number</TableCell>
            <TableCell>involvement_committee</TableCell>
            <TableCell>country</TableCell>
            <TableCell>ethnicity</TableCell>
            {/* nurse info */}
            <TableCell>occupation</TableCell>
            <TableCell>other_occupation</TableCell>
            <TableCell>specialty</TableCell>
            <TableCell>practice_setting</TableCell>
            <TableCell>other_practice_setting</TableCell>
            <TableCell>practice_setting_detail</TableCell>
            <TableCell>other_practice_setting_detail</TableCell>
            <TableCell>employment_status</TableCell>
            <TableCell>license_number</TableCell>
            <TableCell>practice_license_year</TableCell>
            <TableCell>work_email</TableCell>
            <TableCell>other_details</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>john</TableCell>
            <TableCell>doe</TableCell>
            <TableCell>john@keyops.io</TableCell>
            <TableCell>verified</TableCell>
            <TableCell>toronto</TableCell>
            <TableCell>ON</TableCell>
            <TableCell>1978</TableCell>
            <TableCell>man</TableCell>
            <TableCell>true</TableCell>
            <TableCell>905416647</TableCell>
            <TableCell>false</TableCell>
            <TableCell>CA</TableCell>
            <TableCell>no-response</TableCell>
            {/* nurse info */}
            <TableCell>nurse_practitioner</TableCell>
            <TableCell></TableCell>
            <TableCell>acute_care</TableCell>
            <TableCell>academic_hospital</TableCell>
            <TableCell></TableCell>
            <TableCell>inpatient-oncology</TableCell>
            <TableCell></TableCell>
            <TableCell>full_time</TableCell>
            <TableCell>2238912389</TableCell>
            <TableCell>2012</TableCell>
            <TableCell>test.user@keyops.io</TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell>
              <ScrollableView items={UserStates} />
            </TableCell>
            <TableCell></TableCell>
            <TableCell>
              <ScrollableView items={StatesAndProvinces} />
            </TableCell>
            <TableCell></TableCell>
            <TableCell>
              <ScrollableView items={Genders} />
            </TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell>
              <ScrollableView items={Countries} />
            </TableCell>
            <TableCell>
              <ScrollableView items={Ethnicities} />
            </TableCell>
            <TableCell>
              <ScrollableView items={NurseOccupations} />
            </TableCell>
            <TableCell></TableCell>
            <TableCell>
              <ScrollableView items={NurseSpecialties} />
            </TableCell>
            <TableCell>
              <ScrollableView items={NursePracticeSettings} />
            </TableCell>
            <TableCell></TableCell>
            <TableCell>
              <ScrollableView items={NursePracticeSettingDetails} />
            </TableCell>
            <TableCell></TableCell>
            <TableCell>
              <ScrollableView items={NurseEmploymentStatuses} />
            </TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function InvitationCSVExample() {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>engagement_id</TableCell>
            <TableCell>company_id</TableCell>
            <TableCell>email</TableCell>
            <TableCell>send_grid_template_id</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="right">130</TableCell>
            <TableCell align="right">1</TableCell>
            <TableCell align="right">john@keyops.io</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right">130</TableCell>
            <TableCell align="right">1</TableCell>
            <TableCell align="right">john@keyops.io</TableCell>
            <TableCell align="right">
              d-8081aaef3a80432ba8260445e6f90a1a
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function RewardsTransactionsCSVExample() {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>user_id</TableCell>
            <TableCell>amount</TableCell>
            <TableCell>notes</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="right">1</TableCell>
            <TableCell align="right">100</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right">1</TableCell>
            <TableCell align="right">200</TableCell>
            <TableCell align="right">marketing campaign rewards</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export const DashboardContents = () => {
  const [tab, setTab] = useState(0);
  const [physiciansBulkResults, setPhysiciansBulkResults] = useState([]);
  const [pharmacistsBulkResults, setPharmacistsBulkResults] = useState([]);
  const [nursesBulkResults, setNursesBulkResults] = useState([]);
  const [invitationBulkResults, setInvitationBulkResults] = useState([]);
  const [rewardsTransactionsBulkResults, setRewardsTransactionsBulkResults] =
    useState([]);

  const handleTabChange = (event, newTab) => {
    setTab(newTab);
  };

  const handlePhysiciansBulkMutation = async (data) => {
    const results = await createPhysiciansBulkMutation(data);
    setPhysiciansBulkResults(JSON.parse(results));
  };
  const handlePharmacistsBulkMutation = async (data) => {
    const results = await createPharmacistsBulkMutation(data);
    setPharmacistsBulkResults(JSON.parse(results));
  };
  const handleNursesBulkMutation = async (data) => {
    const results = await createNursesBulkMutation(data);
    setNursesBulkResults(JSON.parse(results));
  };
  const handleRewardsTransactionsBulkMutation = async (data) => {
    const results = await createRewardsTransactionsBulkMutation(data);
    setRewardsTransactionsBulkResults(JSON.parse(results));
  };
  const handleInvitationBulkMutation = async (data) => {
    const results = await createInvitationBulkMutation(data);
    setInvitationBulkResults(JSON.parse(results));
  };

  return (
    <Box
      sx={{
        width: '100%',
        bgcolor: 'background.paper',
      }}
    >
      <Tabs value={tab} onChange={handleTabChange} centered>
        <Tab value={0} label="Upload Physicians by CSV" />
        <Tab value={1} label="Upload Pharmacists by CSV" />
        <Tab value={2} label="Upload Nurses by CSV" />
        <Tab value={3} label="Send Invitations in Bulk by CSV" />
        <Tab value={4} label="Add Rewards Transactions in Bulk by CSV" />
      </Tabs>
      <TabPanel value={tab} index={0}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100vw',
            overflowX: 'auto',
          }}
        >
          <Box sx={{ width: '100%', padding: '10px' }}>
            <FileUploader
              title={'Upload a CSV to add Physicians'}
              subTitle={
                'WARNING: this will enable the app to interact with them. Please use upload bulk user data for basic data entry'
              }
              description={'Upload a CSV to add Physicians'}
              handleChange={handlePhysiciansBulkMutation}
              resultsData={physiciansBulkResults}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              padding: '10px',
              borderTop: '1px solid #ccc',
            }}
          >
            <PhysicianUploadCSVExample />
          </Box>
        </Box>
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100vw',
            overflowX: 'auto',
          }}
        >
          <Box sx={{ width: '100%', padding: '10px' }}>
            <FileUploader
              title={'Upload a CSV to add Pharmacists'}
              subTitle={
                'WARNING: this will enable the app to interact with them. Please use upload bulk user data for basic data entry'
              }
              description={'Upload a CSV to add Pharmacists'}
              handleChange={handlePharmacistsBulkMutation}
              resultsData={pharmacistsBulkResults}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              padding: '10px',
              borderTop: '1px solid #ccc',
            }}
          >
            <PharmacistUploadCSVExample />
          </Box>
        </Box>
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100vw',
            overflowX: 'auto',
          }}
        >
          <Box sx={{ width: '100%', padding: '10px' }}>
            <FileUploader
              title={'Upload a CSV to add Nurses'}
              subTitle={
                'WARNING: this will enable the app to interact with them. Please use upload bulk user data for basic data entry'
              }
              description={'Upload a CSV to add Nurses'}
              handleChange={handleNursesBulkMutation}
              resultsData={nursesBulkResults}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              padding: '10px',
              borderTop: '1px solid #ccc',
            }}
          >
            <NurseUploadCSVExample />
          </Box>
        </Box>
      </TabPanel>
      <TabPanel value={tab} index={3}>
        <Box sx={{ display: 'flex' }}>
          <Box
            sx={{
              width: '50%',
              padding: '10px',
              borderRight: '1px solid #ccc',
            }}
          >
            <Box sx={{ marginBottom: '24px' }}>
              <Typography>
                To use this feature you&apos;ll need a csv with the columns in
                the table below:
              </Typography>
            </Box>
            <InvitationCSVExample />
            <Box sx={{ marginTop: '24px' }}>
              <Typography>
                <strong>engagement_id, company_id, email, </strong> are all
                required. However send_grid_template_id is optional and should
                be left blank in most scenarios.
              </Typography>
            </Box>
            <Box sx={{ marginTop: '24px' }}>
              <Typography>
                <strong>send_grid_template_id</strong> allows that invite to
                have a specific invitation email to be sent. If the id is
                invalid or random text is there the invitation will fail.
              </Typography>
            </Box>
            <Box sx={{ marginTop: '24px' }}>
              <Typography>
                Once you have your file prepared, use the button on the right to
                upload it. This will immediately send it to all users in the
                CSV.
              </Typography>
            </Box>
            <Box sx={{ marginTop: '24px' }}>
              <Typography>
                Once it completes it will print out the results, either success
                or failure along with a message per row. It should only take a
                few seconds.
              </Typography>
            </Box>
          </Box>

          <Box sx={{ width: '50%', padding: '10px' }}>
            <FileUploader
              title={'Select a CSV file as per the documentation.'}
              description={'Upload a CSV to add all Users'}
              handleChange={handleInvitationBulkMutation}
              resultsData={invitationBulkResults}
            />
          </Box>
        </Box>
      </TabPanel>
      <TabPanel value={tab} index={4}>
        <Box sx={{ display: 'flex' }}>
          <Box
            sx={{
              width: '50%',
              padding: '10px',
              borderRight: '1px solid #ccc',
            }}
          >
            <Box sx={{ marginBottom: '24px' }}>
              <Typography>
                To use this feature you&apos;ll need a csv with the columns in
                the table below:
              </Typography>
            </Box>
            <RewardsTransactionsCSVExample />
            <Box sx={{ marginTop: '24px' }}>
              <Typography>
                <strong>user_id, amount, </strong> are both required. However
                notes is optional.
              </Typography>
            </Box>
            <Box sx={{ marginTop: '24px' }}>
              <Typography>
                Once you have your file prepared, use the button on the right to
                upload it.
              </Typography>
            </Box>
            <Box sx={{ marginTop: '24px' }}>
              <Typography>
                Once it completes it will print out the results, either success
                or failure along with a message per row. It should only take a
                few seconds.
              </Typography>
            </Box>
          </Box>

          <Box sx={{ width: '50%', padding: '10px' }}>
            <FileUploader
              title={'Select a CSV file as per the documentation.'}
              description={'Upload a CSV to add many RewardsTransactions'}
              handleChange={handleRewardsTransactionsBulkMutation}
              resultsData={rewardsTransactionsBulkResults}
            />
          </Box>
        </Box>
      </TabPanel>
    </Box>
  );
};

const Dashboard = (properties) => (
  <Card>
    <CardHeader title="Welcome to the administration, you'll find several admin functions below with more full list views and more granulated functionality on individual pages." />
    <CardContent>
      <DashboardContents props={properties} />
    </CardContent>
  </Card>
);

export default Dashboard;
