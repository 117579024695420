import { queryClient, useMutation } from '@keyops-hcp/ui-components';
import { SectionDto } from '@keyops-hcp/dtos';
import {
  SectionContentType,
  updateAdBoardSectionContent,
} from '../api/adboard-sections-api';

export const useUpdateSectionContent = (
  setEditSection: React.Dispatch<React.SetStateAction<SectionDto>>,
) => {
  return useMutation<
    SectionDto,
    unknown,
    { sectionId: string; content: SectionContentType }
  >(
    async ({ sectionId, content }): Promise<SectionDto> => {
      const updatedSection = await updateAdBoardSectionContent(
        sectionId,
        content,
      );

      return updatedSection;
    },
    {
      // Options for the mutation
      onSuccess: (updatedSection) => {
        if (updatedSection) {
          // Refetch all sections
          queryClient.invalidateQueries({
            queryKey: ['getAdBoardSections'],
          });
          // Re-set the section that is currently edited
          setEditSection(updatedSection);
        }
      },
      onError: (error) => {
        console.error(error);
      },
    },
  );
};
