import React from 'react';

import { NumberInput, SelectInput, TextInput } from 'react-admin';
import {
  PharmacistPracticeSettingChoices,
  PharmacistPracticeSpecialtyChoices,
  PharmacistRetailChainChoices,
  PharmacistTypeChoices,
} from '../../../utils/choices';

export const EditPharmacistProfile = () => {
  return (
    <>
      <h3>Pharmacist</h3>
      <h4
        style={{
          marginBottom: 0,
          borderBottom: '1px solid #000',
          width: '100%',
        }}
      >
        Practice
      </h4>
      <SelectInput
        fullWidth
        source="pharmacistType"
        choices={PharmacistTypeChoices}
      />
      <SelectInput
        fullWidth
        source="practiceSetting"
        choices={PharmacistPracticeSettingChoices}
      />
      <TextInput fullWidth source="otherPracticeSetting" />
      <SelectInput
        fullWidth
        source="practiceSpecialty"
        choices={PharmacistPracticeSpecialtyChoices}
      />
      <TextInput fullWidth source="otherPracticeSpecialty" />
      <SelectInput
        fullWidth
        source="retailChain"
        choices={PharmacistRetailChainChoices}
      />
      <TextInput fullWidth source="otherRetailChain" />
      <TextInput fullWidth source="otherDetails" />
      <h4
        style={{
          marginBottom: 0,
          borderBottom: '1px solid #000',
          width: '100%',
        }}
      >
        License
      </h4>
      <NumberInput source="licenseNumber" fullWidth />
      <NumberInput source="practiceLicenseYear" fullWidth />
    </>
  );
};
