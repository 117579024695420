import * as React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  BooleanInput,
  PasswordInput,
  required,
  minLength,
  maxLength,
  email,
} from 'react-admin';

const validateString = (min = 2, max = 90) => [
  required(),
  minLength(min),
  maxLength(max),
];
const validatePassword = (min = 6, max = 30, pRequired = true) =>
  pRequired
    ? [required(), minLength(min), maxLength(max)]
    : [minLength(min), maxLength(max)];

const AdminCreate = (properties) => (
  <Create {...properties}>
    <SimpleForm>
      <TextInput source="name" validate={validateString()} fullWidth />
      <PasswordInput
        source="password"
        validate={validatePassword(6, 30, true)}
        fullWidth
      />
      <TextInput source="email" validate={email()} fullWidth />
      <BooleanInput source="enabled" />
    </SimpleForm>
  </Create>
);

export default AdminCreate;
