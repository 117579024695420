import React, { useCallback, useRef } from 'react';
import Tagify, { BaseTagData } from '@yaireo/tagify';
import '@yaireo/tagify/dist/tagify.css';

export const useTagifyMentions = ({
  mentionableAttendees,
  onChange,
}: {
  mentionableAttendees: BaseTagData[];
  onChange: (value: string) => void;
}) => {
  const tagifyRef = useRef<Tagify<BaseTagData> | null>(null);

  const initializeTagify = useCallback(
    (inputRef: HTMLInputElement | null) => {
      if (inputRef && !tagifyRef.current) {
        tagifyRef.current = new Tagify(inputRef, {
          mode: 'mix', // To have both mentions and free text
          pattern: /@/, // The trigger
          whitelist: mentionableAttendees, // The list of mentionable attendees
          // Since the dropdown is an external element, which is positioned directly on the body element
          // it cannot inherit the CSS variables applied on the ".Tagify" element
          // Class in physician-ui/src/index.css
          dropdown: {
            enabled: 0, // display after @
            position: 'text',
            highlightFirst: true,
          },
        });

        tagifyRef.current.on('change', (e) => {
          onChange(e.detail.value); // Update react-hook-form value
        });
      }
    },
    [mentionableAttendees, onChange]
  );

  return { initializeTagify };
};
