import React from 'react';
import { Box, Card, CardContent, Stack, Typography } from '@mui/material';
import {
  DiscussionDto,
  DiscussionsAdBoardSectionDto,
  SectionDto,
} from '@keyops-hcp/dtos';

import { KeyOpsTrashIcon } from '../../../../components/Icons/KeyOpsTrashIcon';
import { useNotify } from 'react-admin';
import { useDeleteDiscussionSection } from '../../../../custom-hooks/useDeleteDiscussionSection';

export const DiscussionListItem = ({
  discussion,
  section,
  setEditSection,
}: {
  discussion: DiscussionDto;
  section: DiscussionsAdBoardSectionDto;
  setEditSection: React.Dispatch<React.SetStateAction<SectionDto>>;
}) => {
  const notify = useNotify();

  // Delete attachmentSection handling
  const { mutate: deleteDiscussionsSection } = useDeleteDiscussionSection(
    setEditSection,
    notify,
  );
  // Trigger the mutation when the delete button is clicked
  const handleDeleteClick = (sectionId: string, discussionId: string) => {
    deleteDiscussionsSection({ sectionId, discussionId });
  };

  return (
    <>
      <Card key={discussion.id} variant="outlined" sx={{ marginTop: '8px' }}>
        <CardContent
          // Need to do so because there is a pb by default on MUI CardContent
          sx={{
            p: `8px`,
            '&:last-child': {
              paddingBottom: '8px',
            },
          }}
        >
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Box>
              <Typography
                fontWeight={400}
                fontSize={'12px'}
                lineHeight={'12px'}
              >
                {discussion.topic}
              </Typography>
            </Box>
            <Stack direction={'row'}>
              <KeyOpsTrashIcon
                onClick={() => handleDeleteClick(section.id, discussion.id)}
              />
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};
