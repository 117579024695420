// External Dependencies
import React from 'react';
import {
  Filter,
  List,
  Datagrid,
  TextField,
  FunctionField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  DateField,
  DateTimeInput,
} from 'react-admin';
import { EngagementFilter } from '../../../components/Filters/EngagementFilter';

// Internal Dependencies

// Populates the Add Filter Button
const AddFilterDropdown = (properties) => (
  <Filter {...properties}>
    <ReferenceInput source="onboardingLinkId" reference="OnboardingLink">
      <SelectInput label="Onboarding Link" source="id" optionText="name" />
    </ReferenceInput>

    <ReferenceInput source="userId" reference="Users" label="User Email">
      <AutocompleteInput source="email" optionText="email" />
    </ReferenceInput>

    <DateTimeInput source="starts_at" label="Start Date" />
    <DateTimeInput source="ends_at" label="End Date" />
    <EngagementFilter source="engagementId" reference="Engagement" />
  </Filter>
);

const dateOptions = {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
};

const PhysicianOnboardingList = (properties) => {
  return (
    <List
      sort={{ field: 'updatedAt', order: 'DESC' }}
      filters={<AddFilterDropdown />}
      {...properties}
    >
      <Datagrid>
        <ReferenceField label="User" source="userId" reference="Users">
          <FunctionField
            label="Name"
            render={(record) =>
              `${record.firstName} ${record.lastName} (${record.id})`
            }
          />
        </ReferenceField>
        <ReferenceField
          label="Engagement"
          source="onboardingLinkId"
          reference="OnboardingLink"
        >
          <ReferenceField source="engagementId" reference="Engagement">
            <TextField source="title" />
          </ReferenceField>
        </ReferenceField>
        <ReferenceField
          label="Link"
          source="onboardingLinkId"
          reference="OnboardingLink"
        >
          <FunctionField label="Name" render={(record) => `${record.name}`} />
        </ReferenceField>
        <DateField
          label="Created At"
          source="createdAt"
          showTime={true}
          options={dateOptions}
        />
        <TextField source="id" />
        <TextField source="invitationState" />
      </Datagrid>
    </List>
  );
};
export default PhysicianOnboardingList;
