import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  Typography,
  useTheme,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AdBoardSectionType } from '@keyops-hcp/constants';
import { AdBoardDto } from '@keyops-hcp/dtos';

import { KeyopsHeader2, KeyopsSubTitle1 } from '../../../../Headers';
import {
  FileText,
  MessageWrite,
  OutlinedBarChart,
  VideoPlay,
} from '../../../../../icons';
import i18next from '../../../../../languages/i18n.config';

interface ActivitiesSectionProps {
  adBoard: AdBoardDto;
}

export const ActivitiesSection = ({ adBoard }: ActivitiesSectionProps) => {
  const { palette } = useTheme();

  // Map section types to icons
  const sectionTypeIcons: Record<AdBoardSectionType, React.ReactNode> = {
    video: (
      <VideoPlay
        data-testid='VideoPlayIcon'
        sx={{ color: palette.keyops.teal }}
      />
    ),
    attachment: (
      <FileText
        data-testid='FileTextIcon'
        sx={{ color: palette.keyops.teal }}
      />
    ),
    discussion: (
      <MessageWrite
        data-testid='MessageWriteIcon'
        sx={{ color: palette.keyops.teal }}
      />
    ),
    survey: (
      <OutlinedBarChart
        data-testid='OutlinedBarChartIcon'
        sx={{ color: palette.keyops.teal }}
      />
    ),
  };

  return (
    <Box>
      <Card variant='outlined' sx={{ borderRadius: 2 }}>
        <CardContent sx={{ p: 2 }}>
          {adBoard.sectionGroups
            ?.sort((a, b) => a.index - b.index)
            ?.map((group) => {
              const sectionCounts: Partial<Record<AdBoardSectionType, number>> =
                {};

              // Calculate counts dynamically
              group.sections.forEach((section) => {
                sectionCounts[section.type] =
                  (sectionCounts[section.type] || 0) + 1;
              });

              return (
                <Accordion
                  key={group.id}
                  disableGutters
                  elevation={0}
                  sx={{
                    borderBottom: `1px solid ${palette.keyops.blue.lightSteelBlue20}`, // Adds a persistent border
                    '&:last-of-type': {
                      borderBottom: 'none', // Removes the border for the last item if needed
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`${group.index}-content`}
                    id={`${group.index}-header`}
                    sx={{
                      px: { xs: 0, md: 1 },
                      '&:hover': {
                        background: palette.keyops.blue.arcticBlue20,
                      },
                    }}
                  >
                    <Box display={'flex'} flexDirection={'column'} m={0}>
                      <KeyopsHeader2>{group.title}</KeyopsHeader2>
                      <KeyopsSubTitle1>
                        {i18next.t(
                          'ad_board.landing_page.activitiesSection.activitySubTitle',
                          {
                            groupIndex: group.index + 1,
                            estimatedTime: group.estimatedTime,
                          }
                        )}
                      </KeyopsSubTitle1>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails sx={{ px: { xs: 0, md: 1 } }}>
                    {group.description ? (
                      <Typography>{group.description}</Typography>
                    ) : (
                      <KeyopsSubTitle1>
                        <em>
                          {i18next.t(
                            'ad_board.landing_page.activitiesSection.noDescription'
                          )}
                        </em>
                      </KeyopsSubTitle1>
                    )}
                    <Typography fontWeight={500} mt={2}>
                      {i18next.t(
                        'ad_board.landing_page.activitiesSection.whatsIncluded'
                      )}
                    </Typography>
                    <Box
                      display='flex'
                      alignItems='center'
                      flexWrap='wrap'
                      gap={2}
                      mt={2}
                    >
                      {Object.entries(sectionCounts).map(([type, count]) => (
                        <Box
                          key={type}
                          display='flex'
                          alignItems='center'
                          gap={1}
                        >
                          {sectionTypeIcons[type as AdBoardSectionType] || null}
                          <Typography>{`${count} ${type}`}</Typography>
                        </Box>
                      ))}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              );
            })}
        </CardContent>
      </Card>
    </Box>
  );
};
