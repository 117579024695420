export const saveCreds = ({ email, name, token, role, expiresAt }) => {
  localStorage.setItem('email', email);
  localStorage.setItem('role', role);
  localStorage.setItem('token', token);
  localStorage.setItem('name', name);
  localStorage.setItem('requestedAt', new Date());
  localStorage.setItem('expiresAt', expiresAt);
};

export const clearCredentials = (reload = false) => {
  localStorage.removeItem('email');
  localStorage.removeItem('token');
  localStorage.removeItem('name');
  localStorage.removeItem('role');
  localStorage.removeItem('requestedAt');
  localStorage.removeItem('expiresAt');

  if (reload) globalThis.location.reload();
};
