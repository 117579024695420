// External Dependencies
import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Box,
  TextField,
  Typography,
} from '@mui/material';

// Internal Dependencies
import { KeyOpsButton } from '../../components/Button/index.tsx';
import { ADMIN_RESET_USER_PASSWORD } from '../../utils/graphql_lib';
import apolloClient from '../../utils/apolloClient';

const EmailTesting = () => {
  // // 1. Component variable setup
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState(false);
  const [passwordConfirm, setPasswordConfirmChange] = React.useState(false);
  const [status, setStatus] = React.useState('');

  // // 2. Input change handlers
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handlePasswordConfirmChange = (e) => {
    setPasswordConfirmChange(e.target.value);
  };

  // 3. Filter Functions
  const resetPassword = async () => {
    if (!password || !passwordConfirm || email === '') {
      return;
    }
    if (password !== passwordConfirm) {
      setStatus("The passwords don't match");
      return;
    }

    try {
      await apolloClient.mutate({
        mutation: ADMIN_RESET_USER_PASSWORD,
        variables: {
          email,
          password,
          // paramValues: JSON.stringify(paramValues),
          // generatedParams: JSON.stringify(filterGeneratedParams()),
        },
      });
      setStatus('Password set Correctly');

      setTimeout(function () {
        setStatus('');
      }, 2500);
    } catch (error) {
      console.log(error);
      // setStatus(e);
    }
  };

  return (
    <Card>
      <CardHeader title="Password Reset" />
      <CardContent>
        <TextField
          label={'Email'}
          variant={'outlined'}
          onChange={handleEmailChange}
        />
        <br />
        <TextField
          label={'Password'}
          variant={'outlined'}
          onChange={handlePasswordChange}
        />
        <br />
        <TextField
          label={'Password Confirm'}
          variant={'outlined'}
          onChange={handlePasswordConfirmChange}
        />
        <br />

        <Box p={1}>
          <KeyOpsButton onClick={resetPassword}>Reset Password</KeyOpsButton>
        </Box>

        <Box p={1}>
          <Typography>{status}</Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default EmailTesting;
