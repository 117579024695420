import { Theme } from '@mui/material';

export const useOutlinedInputStyles = (theme: Theme) => ({
  tags: {
    display: 'flex',
    alignContent: 'center',
    width: '80%',
    p: '16px',
    boxSizing: 'border-box',
    tag: {
      '--tag-bg': theme.palette.keyops.blue.arcticBlue,
      '--tag-hover': theme.palette.keyops.blue.arcticBlue,
      '--tag-text-color': theme.palette.keyops.blue.midnightBlue,
      '--tag-pad': '0px',
      x: { display: 'none' },
    },
  },
  p: '0px !important',
});

export const useSubmitButtonStyles = (theme: Theme) => ({
  position: 'absolute',
  right: '8px',
  bottom: '8px',
  color: theme.palette.keyops.blue.midnightBlue,
  borderColor: theme.palette.keyops.blue.midnightBlue,
  '&:hover': {
    borderColor: theme.palette.keyops.blue.midnightBlue,
    backgroundColor: theme.palette.keyops.blue.midnightBlue,
    color: 'white',
  },
});
