import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const VideoPlay = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.33334 11.6668C3.33334 7.06446 7.0643 3.3335 11.6667 3.3335H28.3333C32.9357 3.3335 36.6667 7.06446 36.6667 11.6668V28.3335C36.6667 32.9359 32.9357 36.6668 28.3333 36.6668H11.6667C7.0643 36.6668 3.33334 32.9359 3.33334 28.3335V11.6668ZM11.6667 6.66683C8.90525 6.66683 6.66667 8.90541 6.66667 11.6668V28.3335C6.66667 31.0949 8.90525 33.3335 11.6667 33.3335H28.3333C31.0948 33.3335 33.3333 31.0949 33.3333 28.3335V11.6668C33.3333 8.90541 31.0948 6.66683 28.3333 6.66683H11.6667Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15 17.1132C15 14.5472 17.7778 12.9435 20 14.2265L25 17.1132C27.2222 18.3962 27.2222 21.6037 25 22.8867L20 25.7735C17.7778 27.0565 15 25.4527 15 22.8867L15 17.1132ZM23.3333 20L18.3333 17.1132L18.3333 22.8867L23.3333 20Z'
        fill='currentColor'
      />
    </svg>
  </SvgIcon>
);
