import axios from 'axios';
import { VideoDto } from '@keyops-hcp/dtos';

import { getAPIUrl } from '../utils/env';

const apiUrl = getAPIUrl();

export const getAllVideos = async (): Promise<VideoDto[]> => {
  try {
    const response = await axios.get(`${apiUrl}/videos`, {
      headers: {
        keyops__accesstokens: localStorage.getItem('token'),
        userType: 'admin',
      },
    });
    return response.data.data;
  } catch (error) {
    console.error('Error fetching data getAllVideos:', error);
    throw error;
  }
};

export const getOneVideo = async (
  id: string,
): Promise<VideoDto | undefined> => {
  try {
    if (!id) return undefined;
    const response = await axios.get(`${apiUrl}/videos/${id}`, {
      headers: {
        keyops__accesstokens: localStorage.getItem('token'),
        userType: 'admin',
      },
    });
    return response.data.data;
  } catch (error) {
    console.error('Error fetching data getOneVideo:', error);
    throw error;
  }
};

export const uploadVideo = async (
  file: File,
  title?: string,
  description?: string,
): Promise<VideoDto> => {
  const formData = new FormData();
  // In the current design, we can only upload a file to create a video
  if (title) formData.append('title', title);
  if (description) formData.append('description', description);

  formData.append('file', file);

  try {
    const response = await axios.post(`${apiUrl}/videos`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        keyops__accesstokens: localStorage.getItem('token'),
        userType: 'admin',
      },
    });
    return response.data.data;
  } catch (error) {
    console.error('Error uploading video:', error);
    throw new Error('Failed to upload video');
  }
};

export const updateOneVideo = async (
  id: string,
  fieldsToUpdate: Partial<VideoDto>,
): Promise<VideoDto> => {
  try {
    const response = await axios.patch(
      `${apiUrl}/videos/${id}`,
      fieldsToUpdate,
      {
        headers: {
          keyops__accesstokens: localStorage.getItem('token'),
          userType: 'admin',
        },
      },
    );
    return response.data.data;
  } catch (error) {
    console.error(
      `Error update one video with id: ${id} and fieldsToUpdate: ${fieldsToUpdate}`,
      error,
    );
    throw error;
  }
};

export const deleteVideo = async (id: string) => {
  try {
    const response = await axios.delete(`${apiUrl}/videos/${id}`, {
      headers: {
        keyops__accesstokens: localStorage.getItem('token'),
        userType: 'admin',
      },
    });
    return response.data.data;
  } catch (error) {
    console.error(`Error deleting one video with id: ${id}`, error);
    throw error;
  }
};
