import React, { useCallback } from 'react';
import { Box, Container, Divider, Link, Typography } from '@mui/material';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { SectionDto } from '@keyops-hcp/dtos';

import EditableText from '../../../../components/EditableText';
import VideoEditSection from '../video/VideoEditSection';
import { AttachmentsEditSection } from '../attachments/AttachmentsEditSection';
import { SurveyEditSection } from '../surveys/SurveyEditSection';
import { DiscussionsEditSection } from '../discussions/DiscussionsEditSection';

const TITLE_LABEL = 'Untitled activity';
const DESCRIPTION_LABEL = 'Add an activity description';

interface EditSectionProperties {
  editSection: SectionDto;
  onCloseEditSection: () => void;
  onUpdateSectionDetails: (data: Partial<SectionDto>) => void;
  setEditSection: React.Dispatch<React.SetStateAction<SectionDto>>;
}

const EditSection: React.FC<EditSectionProperties> = ({
  editSection,
  onCloseEditSection,
  onUpdateSectionDetails,
  setEditSection,
}) => {
  const handleUpdate = (newData: Partial<SectionDto>) => {
    onUpdateSectionDetails(newData);
  };

  // To render the sections to edit, depending on their type
  const renderSections = useCallback(() => {
    if (editSection.type === 'video') {
      return (
        <VideoEditSection
          editSection={editSection}
          setEditSection={setEditSection}
        />
      );
    }
    if (editSection.type === 'attachment') {
      return (
        <AttachmentsEditSection
          attachmentSection={editSection}
          setEditSection={setEditSection}
        />
      );
    }
    if (editSection.type === 'survey') {
      return (
        <SurveyEditSection
          surveySection={editSection}
          setEditSection={setEditSection}
        />
      );
    }
    if (editSection.type === 'discussion') {
      return (
        <DiscussionsEditSection
          discussionSection={editSection}
          setEditSection={setEditSection}
        />
      );
    }
    return <Typography>Please select a section type.</Typography>;
  }, [editSection]);

  return (
    <>
      <Link
        component="button"
        variant="body2"
        underline="none"
        sx={{ display: 'flex', alignItems: 'center' }}
        onClick={onCloseEditSection}
      >
        <ArrowBackIosNewRoundedIcon sx={{ fontSize: 14, mr: 1 }} />
        Back to content
      </Link>
      <Box mt={3} sx={{ width: '100%' }}>
        <Box display="flex" alignItems="center" width="100%">
          <Typography fontWeight={500}>
            Activity {editSection.index + 1}
          </Typography>
          <Divider orientation="vertical" sx={{ mx: 1 }} flexItem />
          <EditableText
            field="title"
            label={TITLE_LABEL}
            value={editSection.title !== TITLE_LABEL ? editSection.title : ''}
            onSave={handleUpdate}
            sx={{
              fontWeight: 500,
              color: editSection.title === TITLE_LABEL ? '#1976d2' : undefined,
            }}
          />
        </Box>
        <Box mt={0.5}>
          <EditableText
            field="description"
            label={DESCRIPTION_LABEL}
            value={editSection.description || ''}
            onSave={handleUpdate}
            sx={{
              color: !editSection.description ? '#1976d2' : undefined,
            }}
          />
        </Box>
      </Box>
      <Divider sx={{ color: '#E2E8F0', width: '100%', mt: 2, mb: 6 }} />
      <Container maxWidth="md">{renderSections()}</Container>
    </>
  );
};

export default EditSection;
