import React from 'react';

import {
  BooleanInput,
  NumberInput,
  SelectArrayInput,
  SelectInput,
  TextInput,
  email,
} from 'react-admin';
import { useWatch } from 'react-hook-form';
import {
  SpecialtyChoices,
  SubSpecialtyChoicesBySpecialty,
  PhysicianPracticeSettingChoices,
} from '../../../utils/choices';

const SubSpecialtyInput = () => {
  const specialties = useWatch({ name: 'specialty' });

  const subSpecialtyChoices = specialties?.flatMap(
    (specialty) => SubSpecialtyChoicesBySpecialty[specialty]
  );
  return (
    <SelectArrayInput
      fullWidth
      allowEmpty
      source="subSpecialty"
      choices={subSpecialtyChoices}
    />
  );
};

export const EditPhysicianProfile = () => {
  return (
    <>
      <h3>Physician</h3>
      <h4
        style={{
          marginBottom: 0,
          borderBottom: '1px solid #000',
          width: '100%',
        }}
      >
        Practice
      </h4>
      <SelectArrayInput
        fullWidth
        allowEmpty
        source="specialty"
        choices={SpecialtyChoices}
      />
      <SubSpecialtyInput />
      <SelectInput
        fullWidth
        source="practiceSetting"
        choices={PhysicianPracticeSettingChoices}
      />
      <BooleanInput
        label="Conducting clinical trials"
        source="involvementTrials"
      />
      <BooleanInput
        label="A committee that makes clinical guidelines or formulary decisions"
        source="involvementCommittee"
      />
      <h4
        style={{
          marginBottom: 0,
          borderBottom: '1px solid #000',
          width: '100%',
        }}
      >
        License
      </h4>
      <NumberInput source="licenseNumber" fullWidth />
      <NumberInput source="practiceLicenseYear" fullWidth />
      <h4
        style={{
          marginBottom: 0,
          borderBottom: '1px solid #000',
          width: '100%',
        }}
      >
        Work Email
      </h4>
      <TextInput source="workEmail" validate={email()} fullWidth />
      <BooleanInput label="Work Email Verified?" source="workEmailVerified" />
    </>
  );
};
