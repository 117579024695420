import React, { useState } from 'react';
import {
  Box,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import i18next from '../../../../languages/i18n.config';
import { useStyles } from './styles';
import { HTMLOutput } from '../../../HTMLOutput';
import { KeyopsHeader2 } from '../../../Headers';
import { AdBoardActivitiesContent } from '../AdBoardActivities';
import { useAdBoardById } from '../../../../hooks';
import { Loader } from '../../../Loader';

interface AdBoardLandingPageTabsProps {
  adBoardId: string;
}

export const AdBoardLandingPageTabs: React.FC<AdBoardLandingPageTabsProps> = ({
  adBoardId,
}) => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { isLoading, isError, data: adBoard } = useAdBoardById(adBoardId);

  const [view, setView] = useState('objectives');

  const tabs = [
    {
      value: 'objectives',
      label: i18next.t('ad_board.landing_page.objectives'),
    },
    {
      value: 'activities',
      label: i18next.t('ad_board.landing_page.activities'),
    },
  ];
  const handleTabChange = (
    event: React.MouseEvent<HTMLElement>,
    nextView: string
  ) => {
    setView(nextView);
    const element = document.getElementById('adboards-landing-page-tabs');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  if (isError) return <div>{i18next.t('common.error')}</div>;
  return (
    <Box px={{ xs: 2, md: 7 }} py={{ xs: 1, md: 3 }}>
      {!isMobile && (
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            id={'adboards-landing-page-tabs'}
            value={view}
            onChange={handleTabChange}
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
                aria-label={tab.label}
                sx={{
                  ...styles.subTitle,
                  color:
                    view === tab.value
                      ? theme.palette.keyops.blue.keyopsBlue
                      : theme.palette.keyops.black.main,
                  fontSize: '0.875rem',
                  pb: 1,
                }}
              />
            ))}
          </Tabs>
        </Box>
      )}
      {isLoading ? (
        <Loader />
      ) : (
        <Box sx={{ mt: 3 }}>
          {view === 'moderators' ? (
            <Typography>
              <HTMLOutput html={adBoard.moderators} />
            </Typography>
          ) : (
            <>
              {adBoard.objectives && (
                <Box>
                  <KeyopsHeader2
                    id='objectives-content'
                    data-testid='objectives-content'
                  >
                    {i18next.t('ad_board.landing_page.objectives')}
                  </KeyopsHeader2>
                  <Typography>
                    <HTMLOutput html={adBoard.objectives} />
                  </Typography>
                </Box>
              )}
              <Box>
                <KeyopsHeader2
                  id='activities-content'
                  data-testid='activities-content'
                  gutterBottom
                >
                  {i18next.t('ad_board.landing_page.activities')}
                </KeyopsHeader2>
                <AdBoardActivitiesContent adBoard={adBoard} />
              </Box>
            </>
          )}
        </Box>
      )}
    </Box>
  );
};
