import React, { useEffect, useState } from 'react';
import { Button } from 'react-admin';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  InputLabel,
  TextField,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { UseMutateFunction } from '@keyops-hcp/ui-components';
import { SurveyDto } from '@keyops-hcp/dtos';
import { SurveyAdBoardSectionDto } from '@keyops-hcp/dtos';

interface SurveyAccordionProperties {
  section: SurveyAdBoardSectionDto;
  handleSave: UseMutateFunction<
    SurveyDto,
    unknown,
    {
      surveySparrowId: string;
    },
    unknown
  >;
}

export const SurveyAccordion: React.FC<SurveyAccordionProperties> = ({
  section,
  handleSave,
}) => {
  const [expanded, setExpanded] = useState<string | false>('survey');
  const [surveySparrowId, setSurveySparrowId] = useState<string>('');
  const [error, setError] = useState<string>('');

  // Use effect to initialize state based on content
  useEffect(() => {
    if (section?.content?.survey) {
      setSurveySparrowId(section?.content?.survey?.surveySparrowId);
    }
  }, [section]);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleButtonClick = () => {
    // Check that surveySparrowId is a 6-digit number
    const isValid = /^\d{6}$/.test(surveySparrowId);
    if (!isValid) {
      setError('Survey Sparrow ID must be a 6-digit number.');
    } else {
      setError('');
      handleSave({ surveySparrowId });
    }
  };

  return (
    <Accordion
      variant={'outlined'}
      expanded={expanded === 'survey'}
      onChange={handleChange('survey')}
      sx={{ flexGrow: 1 }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="survey-content"
        id="survey-header"
      >
        <Box display={'flex'} flexDirection={'column'}>
          <Typography>Survey</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          <InputLabel>Survey Sparrow ID</InputLabel>
          <TextField
            variant={'outlined'}
            label=""
            value={surveySparrowId}
            onChange={(e) => setSurveySparrowId(e.target.value)}
            error={!!error}
            helperText={error}
            fullWidth
          />
        </Box>
        <Box display={'flex'} flexDirection={'row-reverse'} mt={2}>
          <Button
            label="Save"
            variant={'contained'}
            sx={{ textTransform: 'initial' }}
            onClick={handleButtonClick}
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
