import * as React from 'react';
import { useListContext } from 'react-admin';

const CountStyle = {
  whiteSpace: 'nowrap',
};

const XofY = ({ numeratorFilter }) => {
  const { data, isLoading } = useListContext();
  if (isLoading) return null;
  const records = Object.values(data);
  const text = convertToXofY(records, numeratorFilter);
  return <span style={CountStyle}>{text}</span>;
};

export const XofYField = ({ numeratorFilter = defaultFilter }) => {
  return <XofY numeratorFilter={numeratorFilter} />;
};

function defaultFilter() {
  return true;
}

export const convertToXofY = (records, filter) => {
  if (records && records.length > 0) {
    const total = records.length;
    let x = 0;
    for (var record of records) {
      try {
        if (filter(record)) {
          x++;
        }
      } catch (error) {
        console.log(
          'got error running numerator filter, treating as non-matching ' +
            error
        );
      }
    }
    return ((x / total) * 100).toFixed() + '% (' + x + ' of ' + total + ')';
  }
  return 'N/A';
};
