// External Dependencies
import * as React from 'react';
import {
  TextField,
  EditButton,
  Show,
  SimpleShowLayout,
  TopToolbar,
} from 'react-admin';

const PostShowActions = ({ basePath, data }) => (
  <TopToolbar>
    <EditButton basePath={basePath} record={data} />
  </TopToolbar>
);

const CompanyShow = (properties) => (
  <Show actions={<PostShowActions />} {...properties}>
    <SimpleShowLayout>
      <TextField source="name" />
    </SimpleShowLayout>
  </Show>
);

export default CompanyShow;
