// External Dependencies
import * as React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  maxLength,
  minLength,
  BooleanInput,
  ReferenceInput,
  SelectInput,
} from 'react-admin';

const validateName = [required(), minLength(2), maxLength(255)];

export const EngagementInfo = () => (
  <div style={{ margin: '20px 0', width: '100%' }}>
    <p>You can optionally add an Engagement to the onboarding link:</p>
    <ul>
      <li>
        If no engagement is provided, any user will be onboarded as normal and
        will not be invited to any engagement after onboarding
      </li>
      <li>
        If an engagement is selected, any user will be invited to that
        engagement once they have completed the onboarding survey
      </li>
      <li>
        This will invite the users to the selected engagement regardless of
        whether referrals are enabled for that engagement or not
      </li>
      <li>
        In the event that the engagement has ended by the time a user has
        onboarded this way, the invitation will be treated as if the user was
        invited but did not complete the engagement
      </li>
      <li>
        If an engagement is added or changed, users who have signed up prior to
        this change will not automatically get invited to the new engagement
      </li>
    </ul>
  </div>
);

const OnboardingLinkCreate = (properties) => (
  <Create {...properties}>
    <SimpleForm>
      <TextInput source="id" disabled fullWidth />
      <TextInput source="name" validate={validateName} fullWidth />
      <BooleanInput source="enabled" defaultValue={true} />
      <EngagementInfo />
      <ReferenceInput
        perPage={1000}
        source="engagementId"
        reference="Engagement"
        allowEmpty
        fullWidth
      >
        <SelectInput source="title" optionText="title" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export default OnboardingLinkCreate;
