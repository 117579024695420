import * as React from 'react';
import { List, EditButton, Datagrid, TextField, EmailField } from 'react-admin';

const AdminList = (properties) => (
  <List exporter={false} {...properties}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <EmailField source="email" />
      <EditButton />
    </Datagrid>
  </List>
);

export default AdminList;
