import { NotificationType } from 'react-admin';
import { SectionDto, VideoAdBoardSectionDto, VideoDto } from '@keyops-hcp/dtos';
import { useMutation } from '@keyops-hcp/ui-components';

import { useUpdateSectionContent } from './useUpdateSectionContent';
import { uploadVideo } from '../api/videos';

export const useCreateVideoSection = (
  section: VideoAdBoardSectionDto,
  setEditSection: React.Dispatch<React.SetStateAction<SectionDto>>,
  notify: (
    message: string | React.ReactNode,
    options?: NotificationOptions & {
      type?: NotificationType;
    },
  ) => void,
  setProcessing: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  // To be able to update the section content on success
  const { mutate: updateSectionContent } =
    useUpdateSectionContent(setEditSection);

  return useMutation<
    VideoDto,
    unknown,
    { file: File; title?: string; description?: string }
  >(
    async ({ file, title, description }): Promise<VideoDto> => {
      // First create the Video
      setProcessing(true);
      const uploadedVideo = await uploadVideo(file, title, description);

      return uploadedVideo;
    },
    {
      onSuccess: (uploadedVideo) => {
        if (uploadedVideo) {
          try {
            // Then update the section
            updateSectionContent({
              sectionId: section.id,
              content: {
                videoId: uploadedVideo.id,
              },
            });
            notify('Video uploaded successfully', { type: 'success' });
          } catch (error) {
            console.error('Failed to update section:', error);
          } finally {
            setProcessing(false);
          }
        }
      },
      onError: (error) => {
        console.error(error);
        notify('Failed to upload video', { type: 'error' });

        setProcessing(false);
      },
    },
  );
};
