// External Dependencies
import React from 'react';
import {
  TextField,
  EditButton,
  ReferenceField,
  TopToolbar,
  Show,
  SimpleShowLayout,
  FunctionField,
  NumberField,
  BooleanField,
  DateField,
} from 'react-admin';

// These two consts are exported to Admin.js which seems to handle the routing
const ReferralShowActions = ({ basePath, data }) => (
  <TopToolbar>
    <EditButton basePath={basePath} record={data} />
  </TopToolbar>
);

const ReferralShow = (properties) => (
  <Show actions={<ReferralShowActions />} {...properties}>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField
        label="Engagement"
        source="engagementId"
        reference="Engagement"
      >
        <TextField source="title" />
      </ReferenceField>
      <ReferenceField label="Sent by" source="sentBy" reference="Users">
        <FunctionField
          label="Name"
          render={(record) => `${record.firstName} ${record.lastName}`}
        />
      </ReferenceField>
      <ReferenceField label="Received by" source="receivedBy" reference="Users">
        <FunctionField
          label="Name"
          render={(record) => `${record.firstName} ${record.lastName}`}
        />
      </ReferenceField>
      <ReferenceField
        label="Referral Payout"
        source="engagementId"
        reference="Engagement"
      >
        <NumberField source="referralPayout" />
      </ReferenceField>
      <BooleanField source="completed" />
      <BooleanField source="paid" />
      <DateField source="updatedAt" showTime />
    </SimpleShowLayout>
  </Show>
);

export default ReferralShow;
