import axios from 'axios';
import { AdBoardContractDto, AdBoardDto } from '@keyops-hcp/dtos';
import { AD_BOARDS } from '../utils/api-endpoints.const';

// TODO: use react-query mutation for create/update/delete
// TODO: see if some of them should go in ui-component package

export const getAllAdBoards = async (
  pagination: {
    page: number;
    perPage: number;
  },
  sort: {
    field: string;
    order: string;
  },
  filter: Record<string, string>,
): Promise<AdBoardDto[]> => {
  try {
    const response = await axios.get(AD_BOARDS, {
      headers: {
        keyops__accesstokens: localStorage.getItem('token'),
        userType: 'admin',
      },
      params: {
        page: pagination.page,
        perPage: pagination.perPage,
        sortField: sort.field,
        sortOrder: sort.order,
        filter,
      },
    });
    const responseData = response.data.data;

    return responseData.map((item, index) => ({
      ...item,
      id: item.id || index.toString(), // Ensure each item has a unique string id
    }));
  } catch (error) {
    console.error('Error fetching data getAllAdBoards:', error);
    throw error;
  }
};

export const getAdBoardById = async (id: string): Promise<AdBoardDto> => {
  try {
    const response = await axios.get(`${AD_BOARDS}/${id}`, {
      headers: {
        keyops__accesstokens: localStorage.getItem('token'),
        userType: 'admin',
      },
    });
    const responseData = response.data.data;
    return responseData;
  } catch (error) {
    console.error('Error fetching data getOneAdBoard:', error);
    throw error;
  }
};

export const createOneAdBoard = async (
  adBoardData: AdBoardDto,
): Promise<AdBoardDto> => {
  try {
    const response = await axios.post(AD_BOARDS, adBoardData, {
      headers: {
        'Content-Type': 'application/json',
        keyops__accesstokens: localStorage.getItem('token'),
        userType: 'admin',
      },
    });

    return response.data.data;
  } catch (error) {
    console.error('Error creating new AdBoard:', error);
    throw error;
  }
};

export const deleteAdBoardsByIds = async (
  adBoardIds: string[],
): Promise<[number, string[]]> => {
  try {
    const response = await axios.delete(AD_BOARDS, {
      headers: {
        'Content-Type': 'application/json',
        keyops__accesstokens: localStorage.getItem('token'),
        userType: 'admin',
      },
      data: { ids: adBoardIds },
    });
    if (response.status !== 200) throw new Error('Error deleting AdBoards');
    return response.data.data;
  } catch (error) {
    console.error('Error deleting AdBoards:', error);
    throw error;
  }
};

export const updateOneAdBoard = async (
  adBoardData: AdBoardDto,
): Promise<AdBoardDto> => {
  try {
    const response = await axios.patch(
      `${AD_BOARDS}/${adBoardData.id}`,
      adBoardData,
      {
        headers: {
          'Content-Type': 'application/json',
          keyops__accesstokens: localStorage.getItem('token'),
          userType: 'admin',
        },
      },
    );

    return response.data.data[1];
  } catch (error) {
    console.error('Error updating AdBoard:', error);
    throw error;
  }
};

export const reorderSections = async (
  adBoardId: string,
  reorderArray: { id: string; index: number; sectionGroupId: string }[],
) => {
  try {
    await axios.post(
      `${AD_BOARDS}/${adBoardId}/reorder-sections`,
      reorderArray,
      {
        headers: {
          'Content-Type': 'application/json',
          keyops__accesstokens: localStorage.getItem('token'),
          userType: 'admin',
        },
      },
    );
  } catch (error) {
    console.error('Error updating AdBoard:', error);
    throw error;
  }
};

export const uploadContract = async (adBoardId: string, file: File) => {
  const formData = new FormData();
  formData.append('file', file);

  try {
    const response = await axios.post(
      `${AD_BOARDS}/${adBoardId}/contract`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          keyops__accesstokens: localStorage.getItem('token'),
          userType: 'admin',
        },
      },
    );
    return response.data.data;
  } catch (error) {
    console.error('Error uploading contract:', error);
    throw new Error('Failed to upload contract');
  }
};

export const getAdBoardContractById = async (
  id: string,
): Promise<AdBoardContractDto> => {
  try {
    const response = await axios.get(`${AD_BOARDS}/${id}/contract`, {
      headers: {
        keyops__accesstokens: localStorage.getItem('token'),
        userType: 'admin',
      },
    });
    const responseData = response.data.data;
    return responseData;
  } catch (error) {
    console.error('Error fetching data getAdBoardContractById:', error);
    throw error;
  }
};

export const getParticipantsByAdBoardId = async (adBoardId: string) => {
  try {
    const response = await axios.get(`${AD_BOARDS}/${adBoardId}/participants`, {
      headers: {
        keyops__accesstokens: localStorage.getItem('token'),
        userType: 'admin',
      },
    });

    if (response.status === 200) {
      return response.data.data;
    }
    throw new Error(response?.data?.message);
  } catch (error) {
    console.error('Error fetching data getParticipantDetails:', error);
    throw new Error(error?.response?.data?.message);
  }
};

export const getAdBoardProgressFeed = async (adBoardId: string | null) => {
  try {
    if (!adBoardId) throw new Error('No ad-board Id defined.');

    const response = await axios.get(
      `${AD_BOARDS}/${adBoardId}/progress-feed`,
      {
        headers: {
          keyops__accesstokens: localStorage.getItem('token'),
          userType: 'admin',
        },
      },
    );

    if (response.status === 200) {
      const data = response.data.data;
      data.forEach(
        (item) => (item.id = `${item.userId}-${item.createdAt.toString()}`),
      );

      return data;
    }
    throw new Error(response?.data?.message);
  } catch (error) {
    console.error('Error fetching data getAdBoardProgressFeed:', error);
    throw new Error(error?.response?.data?.message);
  }
};
