import React, { useEffect, useState } from 'react';
import { Box, Card, CardHeader, Divider, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { AdBoardSectionGroupDto, SectionDto } from '@keyops-hcp/dtos';
import SectionsList from './SectionsList';
import { Droppable } from '@hello-pangea/dnd';
import { AddEditSectionGroup } from './AddEditSectionGroup';
import { DeleteSectionGroupDialog } from './DeleteSectionGroupDialog';

interface SectionGroupCardProperties {
  sectionGroup: AdBoardSectionGroupDto;
  onEditSection: (data: SectionDto) => void;
  onAddSection: (AdBoardSectionGroupDto) => void;
}

const SectionGroupCard: React.FC<SectionGroupCardProperties> = ({
  sectionGroup,
  onEditSection,
  onAddSection,
}) => {
  const [sectionGroupState, setSectionGroupState] = useState(sectionGroup);
  // IMPORTANT: Update sectionsList when sections is updated (happens on refetch)
  useEffect(() => {
    setSectionGroupState(sectionGroup);
  }, [sectionGroup]);
  // Display sections depending on their type
  return (
    <Box key={sectionGroup.id}>
      <Card variant="outlined">
        <CardHeader
          action={
            <>
              <IconButton
                color="primary"
                onClick={() => onAddSection(sectionGroup)}
                title="Add activity"
              >
                <AddIcon />
              </IconButton>
              <AddEditSectionGroup sectionGroup={sectionGroup} />
              <DeleteSectionGroupDialog sectionGroup={sectionGroup} />
            </>
          }
          title={sectionGroup.title || 'Add section title...'}
          titleTypographyProps={{ variant: 'body1' }}
          subheader={sectionGroup.description || 'Add a section description...'}
          sx={{
            backgroundColor: '#F7FAFC',
            '& .MuiCardHeader-action': { display: 'contents' },
          }}
        />
        <Divider />
        <Droppable droppableId={sectionGroupState.id}>
          {(provided) => (
            <>
              {sectionGroupState.sections && (
                <SectionsList
                  sections={sectionGroupState.sections}
                  provided={provided}
                  onEditSection={onEditSection}
                />
              )}
            </>
          )}
        </Droppable>
      </Card>
    </Box>
  );
};

export default SectionGroupCard;
