import axios from 'axios';
import { AD_BOARD_SECTIONS } from '../utils/api-endpoints.const';
import {
  AttachmentAdBoardSectionContentDto,
  DiscussionAdBoardSectionContentDto,
  SectionDto,
  VideoAdBoardSectionContentDto,
} from '@keyops-hcp/dtos';

// TODO: use react-query mutation?
export const createOneAdBoardSection = async (
  data: Partial<SectionDto>,
): Promise<SectionDto> => {
  try {
    const response = await axios.post(AD_BOARD_SECTIONS, data, {
      headers: {
        'Content-Type': 'application/json',
        keyops__accesstokens: localStorage.getItem('token'),
        userType: 'admin',
      },
    });

    return response.data.data;
  } catch (error) {
    console.error('Error creating new AdBoard Section:', error);
    throw error;
  }
};

// TODO: use react-query mutation?
export const updateOneAdBoardSection = async (
  data: Partial<SectionDto>,
): Promise<SectionDto[]> => {
  try {
    const response = await axios.patch(
      `${AD_BOARD_SECTIONS}/${data.id}`,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          keyops__accesstokens: localStorage.getItem('token'),
          userType: 'admin',
        },
      },
    );

    return response.data.data;
  } catch (error) {
    console.error('Error creating new AdBoard Section:', error);
    throw error;
  }
};

export type SectionContentType =
  | string
  | Partial<VideoAdBoardSectionContentDto>
  | Partial<AttachmentAdBoardSectionContentDto>[]
  | Partial<DiscussionAdBoardSectionContentDto>[];

// TODO: use react-query mutation?
// TODO: see if it should go in ui-component package
/**
 * updateAdBoardSectionContent is used to update the content ONLY of a section
 */
export const updateAdBoardSectionContent = async (
  id: string,
  content: SectionContentType,
): Promise<SectionDto> => {
  try {
    const response = await axios.patch(
      `${AD_BOARD_SECTIONS}/${id}/content`,
      { content },
      {
        headers: {
          'Content-Type': 'application/json',
          keyops__accesstokens: localStorage.getItem('token'),
          userType: 'admin',
        },
      },
    );

    return response.data.data;
  } catch (error) {
    console.error('Error creating new AdBoard Section:', error);
    throw error;
  }
};

// TODO: use react-query mutation?
// Specific to admin capability, leave it here
export const deleteOneAdBoardSection = async (
  sectionId: string,
): Promise<SectionDto> => {
  try {
    const response = await axios.delete(`${AD_BOARD_SECTIONS}/${sectionId}`, {
      headers: {
        'Content-Type': 'application/json',
        keyops__accesstokens: localStorage.getItem('token'),
        userType: 'admin',
      },
    });
    if (response.status !== 200)
      throw new Error('Error deleting AdBoard Section');
    return response.data.data;
  } catch (error) {
    console.error('Error deleting AdBoard Section:', error);
    throw error;
  }
};

// Specific to admin capability, leave it here
export const deleteOneAdBoardAttachmentSection = async (
  sectionId: string,
  attachmentId: string,
): Promise<SectionDto> => {
  try {
    const response = await axios.delete(
      `${AD_BOARD_SECTIONS}/${sectionId}/attachments/${attachmentId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          keyops__accesstokens: localStorage.getItem('token'),
          userType: 'admin',
        },
      },
    );
    if (response.status !== 200)
      throw new Error('Error deleting AdBoardAttachmentSection');
    return response.data.data;
  } catch (error) {
    console.error('Error deleting AdBoardAttachmentSection:', error);
    throw error;
  }
};

export const deleteOneAdBoardDiscussionSection = async (
  sectionId: string,
  discussionId: string,
): Promise<SectionDto> => {
  try {
    const response = await axios.delete(
      `${AD_BOARD_SECTIONS}/${sectionId}/discussions/${discussionId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          keyops__accesstokens: localStorage.getItem('token'),
          userType: 'admin',
        },
      },
    );
    if (response.status !== 200)
      throw new Error('Error deleting AdBoardDiscussionSection');
    return response.data.data;
  } catch (error) {
    console.error('Error deleting AdBoardDiscussionSection:', error);
    throw error;
  }
};
