import { useMutation, useQueryClient } from 'react-query';
import { uploadContract } from '../api/adboards-api';

export const useUploadContract = (notify) => {
  const queryClient = useQueryClient();
  return useMutation<unknown, unknown, { adBoardId: string; file: File }>(
    async ({ adBoardId, file }) => {
      const contract = await uploadContract(adBoardId, file);
      return contract;
    },
    {
      // Options for the mutation
      onSuccess: () => {
        // Refetch contract
        queryClient.invalidateQueries({
          queryKey: ['getAdBoardContractById'],
        });
        notify('Contract uploaded', { type: 'success' });
      },
      onError: (error) => {
        console.error(error);
        notify('Failed to upload contract', { type: 'error' });
      },
    },
  );
};
