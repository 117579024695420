import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Loading, useGetMany, useList } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Dialog,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Pagination,
  SelectChangeEvent,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { AdBoardDto, AdBoardEngagementParticipantsDto } from '@keyops-hcp/dtos';
import { KeyopsHeader2, KeyopsSubTitle2 } from '@keyops-hcp/ui-components';
import { dateFormatter } from '@keyops-hcp/ui-components/dist/utils/datetime';

import FeedFilters from './FeedFilters';
import FeedList from './FeedList';
import useProgressFeed from '../../../../custom-hooks/useProgressFeed';

const ITEMS_PER_PAGE = 10;

const ParticipantsListModel = ({
  open,
  handleClose,
  openTab,
  filteredInProgressParticipants,
  filteredCompletedParticipants,
}: {
  open: boolean;
  handleClose: () => void;
  openTab: number;
  filteredInProgressParticipants: AdBoardEngagementParticipantsDto[];
  filteredCompletedParticipants: AdBoardEngagementParticipantsDto[];
}) => {
  const navigate = useNavigate();
  const {
    data: users,
    error,
    isFetching,
  } = useGetMany('Users', {
    ids: [
      ...filteredInProgressParticipants.map((p) => p.userId),
      ...filteredCompletedParticipants.map((p) => p.userId),
    ],
  });

  const [tabValue, setTabValue] = useState(openTab);

  useEffect(() => {
    setTabValue(openTab);
  }, [openTab]);

  const participantsList = useMemo(
    () =>
      tabValue === 0
        ? filteredInProgressParticipants
        : filteredCompletedParticipants,
    [tabValue, filteredInProgressParticipants, filteredCompletedParticipants],
  );

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm">
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 4,
          top: 4,
          zIndex: 1,
        }}
      >
        <Close sx={{ fontSize: 18 }} />
      </IconButton>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tabValue}
          onChange={(e, newValue) => setTabValue(newValue)}
          aria-label="participants-tabs"
          sx={{ minWidth: 360, mt: 1 }}
        >
          <Tab label="In progress" />
          <Tab label="Completed" />
        </Tabs>
      </Box>
      {isFetching && <Loading />}
      {!isFetching && error && <>Something went wrong</>}
      {!isFetching && !error && (
        <Box height={300} overflow={'auto'}>
          <List
            sx={{
              width: '100%',
              maxWidth: 360,
              bgcolor: 'background.paper',
              padding: 0,
              '& li': { padding: 0 },
            }}
          >
            {participantsList.length > 0 ? (
              participantsList.map((participant) => {
                const user = users?.find(
                  (user) => parseInt(user.id) === participant.userId,
                );
                return (
                  <ListItem
                    key={participant.userId}
                    secondaryAction={
                      <Button
                        variant="text"
                        onClick={() => navigate(`/Users/${user.id}`)}
                      >
                        View
                      </Button>
                    }
                  >
                    <ListItemButton>
                      <ListItemText
                        primary={`${user?.firstName ?? 'Unknown'} ${
                          user?.lastName ?? ''
                        }`}
                        secondary={`Last active: ${dateFormatter(
                          participant.updatedAt.toString(),
                        )}`}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })
            ) : (
              <Box p={0.5} textAlign={'center'}>
                <Typography>No data</Typography>
              </Box>
            )}
          </List>
        </Box>
      )}
    </Dialog>
  );
};

const ProgressFeed = ({
  filters,
  handleSearchChange,
  adBoardData,
  participantsData,
}: {
  filters: { [key: string]: string };
  handleSearchChange: (
    e:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>,
    field: string,
  ) => void;
  adBoardData: AdBoardDto;
  participantsData: AdBoardEngagementParticipantsDto[];
}) => {
  const filtersApplied = useMemo(
    () => Object.keys(filters).length > 0,
    [filters],
  );

  const [openParticipantsListModel, setOpenParticipantsListModel] =
    useState(false);
  const [openTab, setOpenTab] = useState(0);

  const { isLoading, isError, data = [] } = useProgressFeed();
  const {
    data: listData,
    total: totalNumberOfData,
    page: currentPage,
    setPage,
  } = useList({
    data,
    filter: filters,
    perPage: ITEMS_PER_PAGE,
  });

  const totalPages = useMemo(
    () => Math.ceil(totalNumberOfData / ITEMS_PER_PAGE),
    [totalNumberOfData],
  );

  const handleOpenParticipantsListModel = (tab: number) => {
    setOpenParticipantsListModel(true);
    setOpenTab(tab);
  };

  const engagementCalculations = useMemo(() => {
    const inProgress: AdBoardEngagementParticipantsDto[] = [];
    const completed: AdBoardEngagementParticipantsDto[] = [];

    participantsData.forEach((participant) => {
      const sectionProgress =
        participant.progress?.progressDetails?.sectionProgress || [];
      const filteredSection = adBoardData?.sections?.find(
        (section) => section.index === parseInt(filters.sectionIndex),
      );
      const sectionProgressForFiltered = sectionProgress.find(
        (progressSection) => progressSection.sectionId === filteredSection?.id,
      );
      if (sectionProgressForFiltered?.finished) {
        completed.push(participant);
      } else {
        inProgress.push(participant);
      }
    });

    return { inProgress, completed };
  }, [participantsData, adBoardData, filters]);

  const { inProgress, completed } = engagementCalculations;

  const stats = useMemo(
    () => [
      { label: 'In progress', value: inProgress.length, tab: 0 },
      { label: 'Completed', value: completed.length, tab: 1 },
    ],
    [inProgress, completed],
  );

  if (isError) return <>Something went wrong</>;

  return (
    <Box py={1.5}>
      <FeedFilters filters={filters} onSearchChange={handleSearchChange} />
      {filtersApplied && (
        <>
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            py={1.5}
            gap={4}
            mt={2}
          >
            {stats.map(({ label, value, tab }, index) => (
              <Stack key={index} gap={1}>
                <KeyopsSubTitle2>{label}</KeyopsSubTitle2>
                <KeyopsHeader2
                  color={value || value === 0 ? '#1976D2' : undefined}
                >
                  {isLoading ? (
                    <Loading />
                  ) : (
                    <Link
                      underline="none"
                      sx={{ cursor: 'pointer' }}
                      onClick={() => handleOpenParticipantsListModel(tab)}
                    >
                      {value}
                    </Link>
                  )}
                </KeyopsHeader2>
              </Stack>
            ))}
          </Box>
          <ParticipantsListModel
            open={openParticipantsListModel}
            handleClose={() => setOpenParticipantsListModel(false)}
            openTab={openTab}
            filteredInProgressParticipants={inProgress}
            filteredCompletedParticipants={completed}
          />
        </>
      )}
      <Box mt={2}>
        {isLoading ? <Loading /> : <FeedList data={listData ?? []} />}
      </Box>
      <Pagination
        variant="outlined"
        shape="rounded"
        count={totalPages}
        page={currentPage}
        onChange={(e, value) => setPage(value)}
        sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}
      />
    </Box>
  );
};

export default ProgressFeed;
