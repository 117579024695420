import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const OutlinedBarChart = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      width='38'
      height='38'
      viewBox='0 0 38 38'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.16663 11.0833C3.16663 6.71108 6.71104 3.16666 11.0833 3.16666H26.9166C31.2889 3.16666 34.8333 6.71108 34.8333 11.0833V26.9167C34.8333 31.2889 31.2889 34.8333 26.9166 34.8333H11.0833C6.71104 34.8333 3.16663 31.2889 3.16663 26.9167V11.0833ZM11.0833 6.33333C8.45994 6.33333 6.33329 8.45998 6.33329 11.0833V26.9167C6.33329 29.54 8.45994 31.6667 11.0833 31.6667H26.9166C29.54 31.6667 31.6666 29.54 31.6666 26.9167V11.0833C31.6666 8.45998 29.54 6.33333 26.9166 6.33333H11.0833Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.6667 15.8333C13.5412 15.8333 14.25 16.5422 14.25 17.4167L14.25 25.3333C14.25 26.2078 13.5412 26.9167 12.6667 26.9167C11.7923 26.9167 11.0834 26.2078 11.0834 25.3333L11.0834 17.4167C11.0834 16.5422 11.7923 15.8333 12.6667 15.8333Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M25.3333 19C26.2078 19 26.9167 19.7089 26.9167 20.5833V25.3333C26.9167 26.2078 26.2078 26.9167 25.3333 26.9167C24.4589 26.9167 23.75 26.2078 23.75 25.3333L23.75 20.5833C23.75 19.7089 24.4589 19 25.3333 19Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19 11.0833C19.8744 11.0833 20.5833 11.7922 20.5833 12.6667L20.5833 25.3333C20.5833 26.2078 19.8744 26.9167 19 26.9167C18.1255 26.9167 17.4166 26.2078 17.4166 25.3333L17.4166 12.6667C17.4166 11.7922 18.1255 11.0833 19 11.0833Z'
        fill='currentColor'
      />
    </svg>
  </SvgIcon>
);
