import { AdBoardParticipantsDto } from '@keyops-hcp/dtos';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { getAllAdBoardParticipants } from '../api/participants';

export const useAllParticipantsByAdBoard = (
  adBoardId: string
): UseQueryResult<AdBoardParticipantsDto[], Error> => {
  return useQuery({
    queryKey: ['all-ad-board-participant', adBoardId],
    queryFn: () => getAllAdBoardParticipants(adBoardId),
    enabled: !!adBoardId,
  });
};
