import React, { useState } from 'react';

import {
  Button,
  FormControl,
  FormGroup,
  FormHelperText,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';

import { AttachmentDto, AttachmentFormDto } from '@keyops-hcp/dtos';
import { UseMutateFunction } from '@keyops-hcp/ui-components';

type AttachmentsFormType = {
  attachment: Partial<AttachmentFormDto> | undefined;
  attachmentId?: string;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  updateAttachmentSection?: UseMutateFunction<
    AttachmentDto,
    unknown,
    {
      attachmentId: string;
      attachment: AttachmentFormDto;
    },
    unknown
  >;
  createAttachmentSection?: UseMutateFunction<
    AttachmentDto,
    unknown,
    {
      attachmentToSave: AttachmentFormDto;
    },
    unknown
  >;
};

// Use to create and edit an Attachment
export const AttachmentForm = ({
  attachment,
  attachmentId,
  setIsModalOpen,
  updateAttachmentSection,
  createAttachmentSection,
}: AttachmentsFormType) => {
  // Form handling
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AttachmentFormDto>({
    defaultValues: {
      title: attachment?.title,
      description: attachment?.description,
      readingTime: attachment?.readingTime,
      file: attachment?.file,
    },
  });

  // State handling
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Handle creating and editing the attachment
  const handleSaveAttachment: SubmitHandler<AttachmentFormDto> = async (
    attachmentToSave,
  ) => {
    try {
      setIsLoading(true);

      // If there is a file and no attachment id, then it's a creation
      if (attachment?.file && !attachmentId && createAttachmentSection) {
        // Had to add the file here, as it was removed from the defaultValues
        createAttachmentSection({
          attachmentToSave: {
            ...attachmentToSave,
            file: attachment?.file as File,
          },
        });
      }
      // Otherwise, it's an update
      else if (attachmentId && updateAttachmentSection) {
        updateAttachmentSection({ attachmentId, attachment: attachmentToSave });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      setIsModalOpen(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleSaveAttachment)}>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <FormGroup>
          {/* File */}
          {attachment?.file && (
            <FormControl>
              {attachment?.file.name && (
                <Stack>
                  <Typography>File</Typography>
                  <Typography>{attachment?.file.name}</Typography>
                </Stack>
              )}
            </FormControl>
          )}
          <FormControl>
            {/* Title */}
            <TextField
              label={'Document title'}
              id="attachment-title-input"
              aria-describedby="attachment-title-input"
              variant="outlined"
              fullWidth
              {...register('title', { required: true })}
              sx={{ paddingBottom: `8px` }}
            />
            {errors.title && (
              <FormHelperText>{errors.title.message}</FormHelperText>
            )}
          </FormControl>

          {/* Description */}
          <FormControl>
            <TextField
              label={'Description (optional)'}
              id="attachment-description-input"
              aria-describedby="attachment-description-input"
              variant="outlined"
              multiline
              fullWidth
              maxRows={4}
              {...register('description', {
                maxLength: {
                  value: 255,
                  message:
                    'Value too long. Please use less than 255 characters.',
                },
              })}
              sx={{ paddingBottom: `8px` }}
            />

            {errors.description && (
              <FormHelperText sx={{ color: 'red' }}>
                {errors.description.message}
              </FormHelperText>
            )}
          </FormControl>

          {/* Reading time */}
          <FormControl>
            {/* TODO: MUI does not recommend the use of type="number" and announced a NumberInput will be added - To follow
cf https://mui.com/material-ui/react-text-field/#type-quot-number-quot */}
            <Stack direction={'row'}>
              <TextField
                label={'Reading time'}
                type="number"
                id="attachment-reading-time-input"
                aria-describedby="attachment-reading-time-input"
                variant="outlined"
                {...register('readingTime', { required: true })}
                sx={{ paddingBottom: `8px`, width: `15vw` }}
              />
              <FormHelperText>Reading time, in minutes</FormHelperText>
            </Stack>
            {errors.readingTime && (
              <FormHelperText>{errors.readingTime.message}</FormHelperText>
            )}
          </FormControl>

          <Button type="submit" variant="contained" sx={{ marginTop: '8px' }}>
            Save
          </Button>
        </FormGroup>
      )}
    </form>
  );
};
