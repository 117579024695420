import React from 'react';
import { Typography } from '@mui/material';

import { PrimaryTooltip } from '@keyops-hcp/ui-components';
import { ChipField, useRecordContext } from 'react-admin';

export const AdBoardInviteStatusField = ({ source }) => {
  const record = useRecordContext(); // Access the current row's record from context

  if (!record) return null; // Safeguard against missing record

  return record.state === 'contract-signed' ||
    record.state === 'failed-contract-confirmation' ? (
    <PrimaryTooltip
      placement="top"
      title={
        <Typography align="center" fontSize="12px">
          Participant can still join the KoLab.
        </Typography>
      }
      arrow
    >
      {/* Necessary to display the tooltip */}
      <span>
        <ChipField source={source} />
      </span>
    </PrimaryTooltip>
  ) : (
    <ChipField source={source} />
  );
};
// Needed in a custom component, otherwise won't work
AdBoardInviteStatusField.defaultProps = {
  label: 'Invite',
  textAlign: 'center',
};
