import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { Button } from 'react-admin';
import { SectionDto } from '@keyops-hcp/dtos';

interface SectionDeleteDialogProperties {
  open: SectionDto | false;
  handleClose: () => void;
  onDeleteSection: (data: SectionDto) => void;
}

const SectionDeleteDialog: React.FC<SectionDeleteDialogProperties> = ({
  open,
  handleClose,
  onDeleteSection,
}) => {
  if (!open) return <></>;
  return (
    <Dialog
      open={!!open}
      onClose={handleClose}
      aria-labelledby="delete-section-dialog"
      maxWidth={'xs'}
    >
      <DialogTitle id="delete-section-dialog">Delete</DialogTitle>
      <DialogContent>
        <DialogContentText id="delete-section-dialog-description">
          Deleting this will delete the entire section, including all activities
          and content. Are you sure you’d like to proceed?
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: 3, pt: 0 }}>
        <Button
          label="Cancel"
          size={'medium'}
          variant={'outlined'}
          onClick={handleClose}
          sx={{ textTransform: 'initial' }}
        />
        <Button
          label="Yes, delete section"
          size={'medium'}
          variant={'contained'}
          onClick={() => {
            onDeleteSection(open);
            handleClose();
          }}
          sx={{ textTransform: 'initial' }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default SectionDeleteDialog;
