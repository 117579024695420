import React, { useEffect, useState } from 'react';
import { Loading, useNotify } from 'react-admin';
import { Box, Container } from '@mui/material';
import { AttachmentsAdBoardSectionDto, SectionDto } from '@keyops-hcp/dtos';

import { AttachmentUploadCard } from './AttachmentUploadCard';
import { AttachmentsAccordion } from './AttachmentsAccordion';
import { CreateAttachmentModal } from './CreateAttachmentModal';
import { useCreateAttachmentSection } from '../../../../custom-hooks/useCreateAttachmentSection';

interface AttachmentsEditSectionProperties {
  attachmentSection: AttachmentsAdBoardSectionDto;
  setEditSection: React.Dispatch<React.SetStateAction<SectionDto>>;
}

export const AttachmentsEditSection: React.FC<
  AttachmentsEditSectionProperties
> = ({ attachmentSection, setEditSection }) => {
  // Success and error notifications
  const notify = useNotify();

  // Loading handling
  const [processing, setProcessing] = useState<boolean>(false);

  // Once a new attachment file is uploaded, open a modal to create the attachment
  const [newAttachmentFile, setNewAttachmentFile] = useState<File | undefined>(
    undefined,
  );

  // Handle create attachment section
  const { mutate: createAttachmentSection } = useCreateAttachmentSection(
    attachmentSection,
    setEditSection,
    notify,
    setProcessing,
    setNewAttachmentFile,
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    if (newAttachmentFile) {
      setIsModalOpen(true);
    }
  }, [newAttachmentFile]);

  // On modal close, empty the file
  const handleModalClosing = () => {
    setIsModalOpen(false);
    setNewAttachmentFile(undefined);
  };

  // Loading handling
  if (processing) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }

  // When there is no attachments yet in the section, display the upload card
  if (!attachmentSection?.content || attachmentSection?.content?.length === 0) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
        <AttachmentUploadCard setNewAttachmentFile={setNewAttachmentFile} />
        {isModalOpen && (
          <CreateAttachmentModal
            attachmentFile={newAttachmentFile}
            open={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            handleModalClosing={handleModalClosing}
            createAttachmentSection={createAttachmentSection}
          />
        )}
      </Box>
    );
  }
  // When there are already attachments, display the accordion with the attachments list
  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
      <AttachmentsAccordion
        section={attachmentSection as AttachmentsAdBoardSectionDto}
        setNewAttachmentFile={setNewAttachmentFile}
        setEditSection={setEditSection}
      />
      {isModalOpen && (
        <CreateAttachmentModal
          attachmentFile={newAttachmentFile}
          open={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          handleModalClosing={handleModalClosing}
          createAttachmentSection={createAttachmentSection}
        />
      )}
    </Box>
  );
};
