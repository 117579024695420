import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Stack,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DiscussionsAdBoardSectionDto, SectionDto } from '@keyops-hcp/dtos';

import { DiscussionsList } from './DiscussionsList';
import { DiscussionForm } from './DiscussionForm';
import { useCreateDiscussionSection } from '../../../../custom-hooks/useCreateDiscussionSection';
import { useNotify } from 'react-admin';

export const DiscussionsAccordion = ({
  section,
  setEditSection,
}: {
  section: DiscussionsAdBoardSectionDto;
  setEditSection: React.Dispatch<React.SetStateAction<SectionDto>>;
}) => {
  const notify = useNotify();

  const [expanded, setExpanded] = useState<string | false>('discussion');

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  //   Mutation to create the discussion
  const { mutate: createDiscussionSection, isLoading } =
    useCreateDiscussionSection(section, setEditSection, notify);

  const [showDiscussionForm, setShowDiscussionForm] = useState<boolean>(
    section.content ? false : true,
  );

  useEffect(() => {
    setShowDiscussionForm(section.content ? false : true);
  }, [section]);

  return (
    <Accordion
      variant={'outlined'}
      expanded={expanded === 'discussion'}
      onChange={handleChange('discussion')}
      sx={{ flexGrow: 1 }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="discussions-content"
        id="discussions-header"
      >
        <Box display={'flex'} flexDirection={'column'}>
          <Typography>Discussion board</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>Topics</Typography>

        {/* Display the list of discussions if any */}
        {section.content?.length > 0 && (
          <DiscussionsList
            discussions={section.content}
            section={section}
            setEditSection={setEditSection}
          />
        )}
        {/* The form is displayed if there is no discussion or if "Add Topic" btn is clicked */}
        {showDiscussionForm && (
          <DiscussionForm
            discussion={undefined}
            createDiscussionSection={createDiscussionSection}
            isLoading={isLoading}
          />
        )}

        {/* Add topic CTA */}
        <Stack direction={'row-reverse'}>
          <Button
            onClick={() => {
              setShowDiscussionForm(true);
            }}
            variant="text"
            size="small"
            sx={{
              textTransform: 'initial',
              mt: '16px',
            }}
            startIcon={<AddIcon fontSize="small" />}
          >
            Add topic
          </Button>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};
