import RewardsPeriodShow from './components/Show';
import RewardsPeriodList from './components/List';
import { DateRange } from '@mui/icons-material';

const resource = {
  list: RewardsPeriodList,
  show: RewardsPeriodShow,
  icon: DateRange,
};

export default resource;
