import React, { useState, useRef, useEffect } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Step,
  StepConnector,
  stepConnectorClasses,
  Stepper,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import { FiChevronUp } from 'react-icons/fi';
import { AdBoardSectionGroupDto, SectionDto } from '@keyops-hcp/dtos';
import { ActivityListItem } from '../ActivityListItem';
import { useAdBoardContext } from '../../context';
import { KeyopsSubTitle2 } from '../Headers';
import { countFinishedSectionsInSectionGroup } from '../../utils/adboard-sections.utils';
import { ClockCircle } from '../../icons';
import i18next from '../../languages/i18n.config';

interface SectionActivitiesViewProps {
  sectionGroup: AdBoardSectionGroupDto;
  setCurrentSectionId: (id: string) => void;
}

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`& .${stepConnectorClasses.line}`]: {
    width: 3,
    border: 0,
    minHeight: 10,
    backgroundColor: '#E0F0FF',
    borderRadius: 1,
    ...theme.applyStyles('dark', {
      backgroundColor: theme.palette.grey[800],
    }),
  },
}));

export const SectionActivitiesView = ({
  sectionGroup,
  setCurrentSectionId,
}: SectionActivitiesViewProps): JSX.Element => {
  const { palette } = useTheme();
  const {
    role,
    adBoardProgress,
    currentSectionDetails,
    isSectionAccessible,
    isSectionFinished,
  } = useAdBoardContext();

  const [expanded, setExpanded] = useState<boolean>(true);

  // Refs for each ActivityListItem
  const sectionRefs = useRef<Map<string, HTMLDivElement>>(new Map());

  // jump to the current section in the nav on first render
  useEffect(() => {
    if (currentSectionDetails?.id) {
      const targetElement = sectionRefs.current.get(currentSectionDetails?.id);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [currentSectionDetails]);

  // Toggle function to expand/collapse the accordion
  const handleToggle = () => {
    setExpanded((prev) => !prev);
  };

  const ContentItem = ({ section }: { section: SectionDto }) => {
    return (
      <div
        ref={(el) => {
          if (el) {
            sectionRefs.current.set(section.id, el);
          }
        }}
      >
        <ActivityListItem
          key={`activity-${section.id}`}
          text={section.title}
          sectionId={section.id}
          selected={currentSectionDetails?.id === section?.id}
          finished={isSectionFinished(section)}
          accessible={isSectionAccessible(section)}
          setCurrentSectionId={setCurrentSectionId}
        />
      </div>
    );
  };

  return (
    <Accordion
      key={sectionGroup.id}
      elevation={0}
      disableGutters
      sx={{
        '&::before': {
          display: 'none',
        },
      }}
      expanded={expanded}
      onChange={handleToggle} // Set the toggle function here
    >
      <AccordionSummary
        id={`${sectionGroup.id}-header`}
        aria-controls={`${sectionGroup.id}-content`}
        expandIcon={
          <FiChevronUp style={{ color: palette.keyops.blue.midnightBlue }} />
        }
        sx={{
          flexDirection: 'row-reverse',
          gap: 1,
          pl: 3,
          pr: 6,
          mb: role === 'participant' && -1, // remove space when count & estimated time are present
        }}
      >
        <Typography
          variant={'body3'}
          sx={{
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 1,
          }}
        >
          {sectionGroup.title || 'Untitled section'}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ py: 0, px: 6 }}>
        {role === 'participant' && (
          // Hide count & estimated time for non participants
          <KeyopsSubTitle2 sx={{ pb: 1 }}>
            <span style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              {adBoardProgress &&
                countFinishedSectionsInSectionGroup(
                  sectionGroup,
                  adBoardProgress
                )}
              /
              {sectionGroup?.sections?.length > 1
                ? i18next.t('ad_board.sectionsDrawer.activityDisplay_many', {
                    count: sectionGroup?.sections?.length,
                  })
                : i18next.t('ad_board.sectionsDrawer.activityDisplay_one', {
                    count: sectionGroup?.sections?.length,
                  })}{' '}
              • <ClockCircle sx={{ fontSize: '16px' }} />
              {sectionGroup?.estimatedTime > 1
                ? i18next.t('ad_board.sectionsDrawer.durationDisplay_other', {
                    count: sectionGroup?.estimatedTime,
                  })
                : i18next.t('ad_board.sectionsDrawer.durationDisplay_one', {
                    count: sectionGroup?.estimatedTime,
                  })}
            </span>
          </KeyopsSubTitle2>
        )}
        <Stepper orientation='vertical' connector={<ColorlibConnector />}>
          {sectionGroup?.sections.map((section) => (
            <ContentItem key={section.id} section={section} />
          ))}
          {/* This extra step is here to achieve design requirements  */}
          <Step />
        </Stepper>
      </AccordionDetails>
    </Accordion>
  );
};
