// External Dependencies
import * as React from 'react';
import {
  Filter,
  List,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  ReferenceField,
  NullableBooleanInput,
  DateTimeInput,
  BooleanField,
  FunctionField,
  EditButton,
  downloadCSV,
} from 'react-admin';
import jsonExport from 'jsonexport/dist';
import { EngagementFilter } from '../../../components/Filters/EngagementFilter';

// Internal Dependencies
const exporter = async (records, fetchRelatedRecords) => {
  const fetchUser = (users, id) => {
    return users[id] ? users[id] : { firstName: '', lastName: '', email: '' };
  };

  const fetchEngagement = (id) => {
    return engagements[id] ? engagements[id] : { name: '' };
  };

  const usersSentBy = await fetchRelatedRecords(records, 'sentBy', 'Users');

  const usersReceivedBy = await fetchRelatedRecords(
    records,
    'receivedBy',
    'Users'
  );

  const engagements = await fetchRelatedRecords(
    records,
    'engagementId',
    'Engagement'
  );

  const postsForExport = records.map((post) => {
    const sentByPhys = fetchUser(usersSentBy, post.sentBy);
    const receivedByPhys = fetchUser(usersReceivedBy, post.receivedBy);
    return {
      engagementId: post.engagementId,
      engagementName: fetchEngagement(post.engagementId).title,
      sentBy: post.sentBy,
      sentByName: sentByPhys.firstName + ' ' + sentByPhys.lastName,
      sentByEmail: sentByPhys.email,
      receivedByID: post.receivedBy,
      receivedByName: receivedByPhys.firstName + ' ' + receivedByPhys.lastName,
      receivedByEmail: receivedByPhys.email,
      payout: post.payout,
      completed: post.completed,
      paid: post.paid,
      created_at: post.createdAt,
      updated_at: post.updatedAt,
    };
  });

  jsonExport(
    postsForExport,
    {
      // headers: ['id', 'title', 'author_name', 'body'] // order fields in the export
    },
    (error, csv) => {
      downloadCSV(csv, 'Referrals'); // download as 'posts.csv` file
    }
  );
};

const AddFilterDropdown = (properties) => (
  <Filter {...properties}>
    <EngagementFilter source="engagementId" reference="Engagement" />
    <NullableBooleanInput label="paid" source="paid" />
    <NullableBooleanInput label="completed" source="completed" />
    <DateTimeInput
      source="starts_at"
      reference="Invitation"
      label="Start Date"
    />
    <DateTimeInput source="ends_at" reference="Invitation" label="End Date" />
  </Filter>
);

const ReferralsList = (properties) => (
  <List
    aside={<div />}
    exporter={exporter}
    filters={<AddFilterDropdown />}
    sort={{ field: 'updatedAt', order: 'DESC' }}
    {...properties}
  >
    <Datagrid rowClick="show">
      <ReferenceField source="engagementId" reference="Engagement">
        <TextField label="id" source="id" /> &mdash;
        <TextField label="title" source="title" />
      </ReferenceField>
      <ReferenceField label="Sent by" source="sentBy" reference="Users">
        <FunctionField
          label="Name"
          render={(record) => `${record.firstName} ${record.lastName}`}
        />
      </ReferenceField>
      <ReferenceField label="Received by" source="receivedBy" reference="Users">
        <FunctionField
          label="Name"
          render={(record) => `${record.firstName} ${record.lastName}`}
        />
      </ReferenceField>
      <ReferenceField
        label="Referral Payout"
        source="engagementId"
        reference="Engagement"
      >
        <NumberField source="referralPayout" />
      </ReferenceField>
      <BooleanField source="completed" />
      <BooleanField source="paid" />
      <DateField source="updatedAt" showTime />
      <EditButton />
    </Datagrid>
  </List>
);
export default ReferralsList;
