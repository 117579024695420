import React from 'react';
import { AutocompleteInput, ReferenceInput } from 'react-admin';

export const EngagementFilter = ({ ...props }) => {
  return (
    <ReferenceInput perPage={1000} {...props}>
      <AutocompleteInput
        source="id"
        sx={{ width: '500px' }}
        optionText={(choice) => {
          if (!choice) return ''; // Handles null or undefined choice
          return `${choice.id} - ${choice.title}`.trim();
        }}
      />
    </ReferenceInput>
  );
};
