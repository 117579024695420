import { queryClient, useMutation } from '@keyops-hcp/ui-components';
import { AdBoardSectionGroupDto } from '@keyops-hcp/dtos';
import {
  createOneAdBoardSectionGroup,
  updateOneAdBoardSectionGroup,
} from '../api/ad-board-section-groups-api';

export const useSaveSectionGroup = (
  afterSuccess?: (sectionGroup?: AdBoardSectionGroupDto) => void,
) => {
  return useMutation<
    AdBoardSectionGroupDto,
    unknown,
    Partial<AdBoardSectionGroupDto>
  >(
    async (
      sectionGroup: Partial<AdBoardSectionGroupDto>,
    ): Promise<AdBoardSectionGroupDto> => {
      const adBoardSectionGroup = sectionGroup.id
        ? updateOneAdBoardSectionGroup(sectionGroup)
        : createOneAdBoardSectionGroup(sectionGroup);

      return adBoardSectionGroup;
    },
    {
      // Options for the mutation
      onSuccess: async (updatedSectionGroup) => {
        await queryClient.invalidateQueries({
          queryKey: ['getAdBoardById'],
        });
        // Refetch all sections
        await queryClient.invalidateQueries({
          queryKey: ['getAdBoardSections'],
        });
        if (afterSuccess) {
          afterSuccess(updatedSectionGroup);
        }
        return updatedSectionGroup;
      },
      onError: (error) => {
        console.error(error);
      },
    },
  );
};
