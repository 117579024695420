import React, { useState } from 'react';
import { Loading, useNotify } from 'react-admin';
import { Box, Container } from '@mui/material';
import { SectionDto, SurveyAdBoardSectionDto } from '@keyops-hcp/dtos';
import { useUpdateSurveySection } from '../../../../custom-hooks/useUpdateSurveySection';
import { useCreateSurveySection } from '../../../../custom-hooks/useCreateSurveySection';
import { SurveyAccordion } from './SurveyAccordion';

interface SurveyEditSectionProperties {
  surveySection: SurveyAdBoardSectionDto;
  setEditSection: React.Dispatch<React.SetStateAction<SectionDto>>;
}

export const SurveyEditSection: React.FC<SurveyEditSectionProperties> = ({
  surveySection,
  setEditSection,
}) => {
  // Success and error notifications
  const notify = useNotify();
  // Loading handling
  const [processing, setProcessing] = useState<boolean>(false);

  // Create the survey and the SurveySection
  const { mutate: createSurveySection } = useCreateSurveySection(
    surveySection,
    setEditSection,
    notify,
    setProcessing,
  );

  // Update the survey and the surveySection
  const { mutate: updateSurveySection } = useUpdateSurveySection(
    surveySection,
    setEditSection,
    notify,
    setProcessing,
  );

  // Loading handling
  if (processing) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }

  // Display the accordion with the survey
  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
      <SurveyAccordion
        section={surveySection}
        handleSave={
          !surveySection?.content?.surveyId
            ? createSurveySection
            : updateSurveySection
        }
      />
    </Box>
  );
};
