// External Dependencies
import React, { useCallback } from 'react';
import {
  Edit,
  TextInput,
  ShowButton,
  BooleanInput,
  required,
  minLength,
  maxLength,
  email,
  TopToolbar,
  FormTab,
  TabbedForm,
  SelectInput,
  Toolbar,
  SaveButton,
  useRedirect,
  TextField,
  Labeled,
  useUpdate,
} from 'react-admin';
import { useWatch } from 'react-hook-form';

// Internal Dependencies
import {
  CountryChoices,
  GenderChoices,
  ProvinceChoices,
  StateChoices,
  UserStateChoices,
} from '../../../utils/choices.js';
import { EditHcpProfile } from './EditHcpProfile.js';
import { MixPanelLink } from './utils';

const validateString = (min = 2, max = 90) => [
  required(),
  minLength(min),
  maxLength(max),
];

const UserName = ({ record }) => {
  return <span>User {record ? `"${record.firstName}"` : ''}</span>;
};

const UserEditActions = ({ basePath, data }) => (
  <TopToolbar>
    <ShowButton basePath={basePath} record={data} />
  </TopToolbar>
);

const UserEditToolbar = (properties) => (
  <Toolbar {...properties}>
    <SaveButton />
  </Toolbar>
);

const ProvinceOfPracticeSelect = () => {
  const country = useWatch({ name: 'country' });
  return (
    <SelectInput
      fullWidth
      source="provinceOfPractice"
      choices={country === 'CA' ? ProvinceChoices : StateChoices}
    />
  );
};

export const UserEdit = (properties) => {
  const { basePath } = properties;
  const [mutate] = useUpdate();
  const redirectTo = useRedirect();

  const endYear = new Date().getFullYear() - 20;
  const years = Array.from(new Array(60), (value, index) => {
    const year = endYear - index;
    return { id: `${year}`, name: `${year}` };
  });

  const save = useCallback(
    async ({ ...values }, redirect) => {
      try {
        const res = await mutate(
          {
            type: 'update',
            resource: 'User',
            payload: {
              data: {
                ...values,
              },
            },
          },
          { returnPromise: true }
        );
        redirectTo(redirect, basePath, res.data.id, res.data);
      } catch (error) {
        console.log(error);
      }
    },
    [mutate]
  );

  return (
    <Edit actions={<UserEditActions />} title={<UserName />} {...properties}>
      <TabbedForm save={save} toolbar={<UserEditToolbar />}>
        <FormTab label="User">
          <MixPanelLink />
          <h4
            style={{
              marginBottom: 0,
              borderBottom: '1px solid #000',
              width: '100%',
            }}
          >
            Main details
          </h4>
          <Labeled label="Id">
            <TextField source="id" />
          </Labeled>
          <Labeled label="Hcp Type">
            <TextField source="hcpType" />
          </Labeled>
          <TextInput source="firstName" validate={validateString()} fullWidth />
          <TextInput source="lastName" validate={validateString()} fullWidth />
          <TextInput source="email" validate={email()} fullWidth />
          <SelectInput source="state" choices={UserStateChoices} fullWidth />
          <TextField source="lastSignInAt" />
          <h4
            style={{
              marginBottom: 0,
              borderBottom: '1px solid #000',
              width: '100%',
            }}
          >
            Location
          </h4>
          <SelectInput fullWidth source="country" choices={CountryChoices} />
          <ProvinceOfPracticeSelect />
          <TextInput source="cityOfPractice" fullWidth />
          <h4
            style={{
              marginBottom: 0,
              borderBottom: '1px solid #000',
              width: '100%',
            }}
          >
            Mobile
          </h4>
          <TextInput source="mobileNumber" fullWidth />
          <BooleanInput source="smsNotifications" fullWidth />
          <h4
            style={{
              marginBottom: 0,
              borderBottom: '1px solid #000',
              width: '100%',
            }}
          >
            Demographics
          </h4>
          <SelectInput source="yearOfBirth" choices={years} fullWidth />
          <SelectInput fullWidth source="gender" choices={GenderChoices} />
          <TextInput source="ethnicity" fullWidth />
          <h4
            style={{
              marginBottom: 0,
              borderBottom: '1px solid #000',
              width: '100%',
            }}
          >
            Admin only
          </h4>
          <TextInput source="adminNotes" fullWidth multiline />
        </FormTab>
        <FormTab label="HCP Profile">
          <EditHcpProfile />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
