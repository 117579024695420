import React from 'react';
import { Card, Typography } from '@mui/material';
import { Button } from 'react-admin';
import { UseMutateFunction } from '@keyops-hcp/ui-components';
import { VideoDto } from '@keyops-hcp/dtos';

interface VideoUploadCardProperties {
  createVideoSection: UseMutateFunction<
    VideoDto,
    unknown,
    {
      file: File;
      title?: string;
      description?: string;
    },
    unknown
  >;
}

export const VideoUploadCard: React.FC<VideoUploadCardProperties> = ({
  createVideoSection,
}) => {
  return (
    <Card
      variant={'outlined'}
      sx={{
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        p: 2,
      }}
    >
      <Typography>Video</Typography>
      <label htmlFor="upload-video">
        <input
          id="upload-video"
          type="file"
          accept="video/*"
          style={{ display: 'none' }}
          onChange={(e) => {
            if (e.target.files?.[0])
              createVideoSection({ file: e.target.files?.[0] });
          }}
        />
        <Button
          variant="outlined"
          label={'Upload video'}
          sx={{ textTransform: 'initial' }}
          component="span"
        />
      </label>
    </Card>
  );
};
