import React, { RefObject } from 'react';
import ReactPlayer from 'react-player';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Box, FormControl, FormGroup, FormHelperText } from '@mui/material';

import { usePostDiscussionMessage } from '../../hooks';

import { MentionInput } from '../MentionInput';
import { extractMentionIds } from '../../utils/discussion';
import { MessageDto } from '@keyops-hcp/dtos';

interface DiscussionMessageFormProperties {
  discussionId: string;
  playerRef?: RefObject<ReactPlayer | null>;
  replyTo?: MessageDto;
  onPost?: () => void; // Optional onPost logic (Ex: closing reply messageForm, scrolling to bottom, etc...)
}
export const DiscussionMessageForm = ({
  discussionId,
  playerRef,
  replyTo,
  onPost,
}: DiscussionMessageFormProperties): React.JSX.Element => {
  const initialMessage: string = undefined;

  // Form handling
  const {
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
  } = useForm<{ message: string }>({
    defaultValues: {
      message: initialMessage,
    },
  });

  // Post one message (update the progress and invalidate the get all discussions in the mutation itself)
  const {
    mutate: postDiscussionMessage,
    isLoading,
    isError,
  } = usePostDiscussionMessage();

  const handlePostMessage: SubmitHandler<{ message: string }> = async (
    messageToSave,
  ) => {
    const mentionedUserIds = extractMentionIds(messageToSave.message);

    const threadId = replyTo?.threadId ?? replyTo?.id;

    if (playerRef) {
      const player = playerRef.current.getInternalPlayer();
      const currentTime = await player.getCurrentTime();

      postDiscussionMessage({
        message: messageToSave.message,
        discussionId,
        type: 'video',
        threadId,
        context: {
          videoTimeStamp: Number.parseInt(`${currentTime}`, 10),
          mentions: mentionedUserIds,
        },
      });
    } else {
      postDiscussionMessage({
        message: messageToSave.message,
        discussionId,
        threadId,
        type: 'discussion',
        context: {
          mentions: mentionedUserIds,
        },
      });
    }
    reset();

    if (onPost) onPost();
  };

  const handleOnFocusEvent = () => {
    if (playerRef && 'current' in playerRef && playerRef.current) {
      const player = playerRef.current.getInternalPlayer();
      player.pause();
    }
  };

  if (isError) return <>Something went wrong</>;

  const userToTag = replyTo?.threadId ? replyTo.user : undefined;

  return (
    <Box width={'100%'} alignSelf={'center'} pb={2}>
      <form
        onSubmit={handleSubmit(handlePostMessage)}
        onFocus={handleOnFocusEvent}
        style={{
          transition: 'opacity 0.5s ease-in-out', // Smooth form transitions when submitting
          opacity: isLoading ? 0.5 : 1, // Dim the form when loading
        }}
      >
        <FormGroup>
          <FormControl variant="outlined">
            <MentionInput
              setValue={setValue}
              control={control}
              userToTag={userToTag}
            />

            {errors.message && (
              <FormHelperText>{errors.message.message}</FormHelperText>
            )}
          </FormControl>
        </FormGroup>
      </form>
    </Box>
  );
};
