// External Dependencies
import * as React from 'react';
import {
  Filter,
  List,
  Datagrid,
  FunctionField,
  NumberField,
  DateField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
} from 'react-admin';
// Internal Dependencies
import { tierTypesChoices } from '../data';

const AddFilterDropdown = (properties) => (
  <Filter {...properties}>
    <ReferenceInput source="userId" reference="Users" label="User Name">
      <AutocompleteInput
        source="id"
        optionText={(choice) => {
          if (!choice) return ''; // Handles null or undefined choice
          const firstName = choice.firstName || '';
          const lastName = choice.lastName || '';
          return `${firstName} ${lastName}`.trim();
        }}
      />
    </ReferenceInput>
    <SelectInput
      source="tierRange"
      reference="RewardsPeriods"
      label="Reward Tier"
      choices={tierTypesChoices}
    />
  </Filter>
);

const RewardsPeriodList = (properties) => (
  <List
    aside={<div />}
    filters={<AddFilterDropdown />}
    sort={{ field: 'updatedAt', order: 'DESC' }}
    {...properties}
  >
    <Datagrid rowClick="show">
      <ReferenceField label="User" source="userId" reference="Users">
        <FunctionField
          label="Name"
          render={(record) => `${record.firstName} ${record.lastName}`}
        />
      </ReferenceField>
      <NumberField source="pointsTotal" />
      <DateField source="startDate" showTime />
      <DateField source="endDate" showTime />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
);
export default RewardsPeriodList;
