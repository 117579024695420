import { useQuery, UseQueryResult } from '@keyops-hcp/ui-components';
import { getInvitesByAdBoardId } from '../api/adboard-invites-api';
import { AdBoardInviteDto } from '@keyops-hcp/dtos';

export const useInvitesByAdBoardId = (
  adBoardId: string,
): UseQueryResult<AdBoardInviteDto[], Error> => {
  return useQuery({
    queryKey: ['getInvitesByAdBoardId', adBoardId],
    queryFn: () => getInvitesByAdBoardId(adBoardId),
  });
};
