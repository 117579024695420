// External Dependencies
import React, { useState, useCallback } from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  maxLength,
  minLength,
  SelectInput,
  SelectArrayInput,
  ReferenceInput,
  BooleanInput,
  NumberInput,
  FormDataConsumer,
  ImageField,
  useRedirect,
  useNotify,
  useUpdate,
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';

// Internal Dependencies
import KOToggleButton from '../../../components/toggle_button';
import { FileUploadInput } from '../../../components/file_upload_input';
import { HcpTypeChoices, SpecialtyChoices } from '../../../utils/choices.js';

const validateName = [required(), minLength(5), maxLength(255)];

const EngagementCreate = (properties) => {
  const { basePath } = properties;
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const [selectedHcpType, setSelectedHcpType] = useState();

  const handleFileUpload = async (link, id = null) => {
    let actualLink = link;
    setUploadedFiles((previousState) => [
      ...previousState,
      {
        link: actualLink,
        ...(id ? { id, new: false } : { new: true }),
      },
    ]);
  };

  const handleFileMove = (index, newIndex) => {
    let copiedFiles = [...uploadedFiles];
    const removedElement = copiedFiles.splice(index, 1)[0];
    copiedFiles.splice(newIndex, 0, removedElement);
    setUploadedFiles(copiedFiles);
  };

  const handleFileDelete = (index) => {
    const copiedFiles = uploadedFiles.filter(
      (item, index_) => index !== index_
    );
    setUploadedFiles(copiedFiles);
  };

  const [mutate] = useUpdate();
  const redirectTo = useRedirect();
  const notify = useNotify();
  const save = useCallback(
    async (values, redirect) => {
      try {
        const res = await mutate(
          {
            type: 'create',
            resource: 'Engagement',
            payload: {
              data: {
                ...values,
                files: uploadedFiles,
              },
            },
          },
          { returnPromise: true }
        );
        notify('ra.notification.created', 'info', {
          smart_count: 1,
        });
        redirectTo(redirect, basePath, res.data.id, res.data);
      } catch (error) {
        console.log(error);
      }
    },
    [mutate, uploadedFiles]
  );

  return (
    <Create {...properties}>
      <SimpleForm save={save}>
        <h4
          style={{
            marginBottom: 0,
            borderBottom: '1px solid #000',
            width: '100%',
          }}
        >
          Main details
        </h4>

        <TextInput source="title" validate={validateName} fullWidth />
        <RichTextInput source="summary" fullWidth />
        <ReferenceInput
          source="companyId"
          reference="Company"
          perPage={1000}
          allowEmpty
          fullWidth
          required
        >
          <SelectInput required optionText="name" />
        </ReferenceInput>
        <BooleanInput source="isBlinded" />
        <h4
          style={{
            marginBottom: 0,
            borderBottom: '1px solid #000',
            width: '100%',
          }}
        >
          Survey Sparrow details
        </h4>
        <NumberInput
          source="surveySparrowId"
          options={{ label: 'Survey Sparrow ID' }}
          fullWidth
        />
        <TextInput
          source="link"
          options={{ label: 'Survey Sparrow Share Link' }}
          fullWidth
        />

        <h4
          style={{
            marginBottom: 0,
            borderBottom: '1px solid #000',
            width: '100%',
          }}
        >
          Eligibility details
        </h4>
        {/* TODO: replace with a shared HcpTypes */}
        <SelectInput
          source="hcpType"
          choices={HcpTypeChoices}
          fullWidth
          onChange={(e) => setSelectedHcpType(e.target.value)}
          required
        />

        {selectedHcpType === 'physician' && (
          <SelectArrayInput
            source="allowedSpecialties"
            choices={SpecialtyChoices}
            fullWidth
          />
        )}
        <BooleanInput source="hasOtherEligibilityCriteria" />
        <RichTextInput source="otherEligibilityCriteria" fullWidth />

        <h4
          style={{
            marginBottom: 0,
            borderBottom: '1px solid #000',
            width: '100%',
          }}
        >
          Effort & Payout
        </h4>
        <NumberInput source="estimatedTime" fullWidth />
        <NumberInput source="payoutValue" fullWidth />

        <h4
          style={{
            marginBottom: 0,
            borderBottom: '1px solid #000',
            width: '100%',
          }}
        >
          Media
        </h4>
        <KOToggleButton
          source="type"
          values={{
            video: 'Video',
            document: 'Images',
            attachment: 'Attachment Link',
          }}
          defaultValue="plain"
        />
        <FormDataConsumer>
          {({ formData }) => {
            return formData.type === 'video' ? (
              <>
                <TextInput
                  source="mediaLink"
                  options={{
                    label: 'Vimeo Media Link',
                  }}
                  fullWidth
                />
                <TextInput source="mediaDescription" fullWidth />
              </>
            ) : formData.type === 'document' ? (
              <>
                <FileUploadInput
                  source="EngagementFiles"
                  label="Documents"
                  accept="image/*"
                  multiple={true}
                  handleFileUpload={handleFileUpload}
                  handleFileMove={handleFileMove}
                  handleFileDelete={handleFileDelete}
                >
                  <ImageField source="link" />
                </FileUploadInput>
                <TextInput source="mediaDescription" fullWidth />
              </>
            ) : formData.type === 'attachment' ? (
              <TextInput source="attachmentLink" fullWidth />
            ) : (
              <></>
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};

export default EngagementCreate;
