import React from 'react';
import { FiCircle, FiLock, FiCheckCircle } from 'react-icons/fi';
import { Box, Step, StepLabel, Typography, useTheme } from '@mui/material';

interface ActivityListItemProps {
  text: string;
  sectionId: string;
  selected: boolean;
  finished: boolean;
  accessible: boolean;
  setCurrentSectionId: (id: string, contentId?: string) => void;
}

export const ActivityListItem = ({
  text,
  sectionId,
  selected,
  finished,
  setCurrentSectionId,
  accessible,
}: ActivityListItemProps): JSX.Element => {
  const { palette } = useTheme();

  return (
    <Step
      sx={{
        cursor: accessible ? 'pointer' : 'default',
      }}
      onClick={() => {
        if (accessible) {
          setCurrentSectionId(sectionId);
        }
      }}
    >
      <StepLabel sx={{ p: 0 }}>
        <Box display={'flex'} alignItems={'center'} gap={2} pl={0.6}>
          {finished ? (
            <FiCheckCircle
              data-testid='check-circle-icon'
              fontSize={18}
              style={{ flexShrink: 0, color: palette.keyops.teal }}
            />
          ) : accessible ? (
            <FiCircle
              data-testid='circle-icon'
              fontSize={18}
              style={{ flexShrink: 0 }}
            />
          ) : (
            <FiLock
              data-testid='lock-icon'
              fontSize={18}
              style={{ flexShrink: 0 }}
            />
          )}
          <Typography
            variant={'body3'}
            lineHeight={1.2}
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              color: palette.keyops.black.main,
              fontWeight: selected ? '500' : '400',
            }}
          >
            {text}
          </Typography>
        </Box>
      </StepLabel>
    </Step>
  );
};
