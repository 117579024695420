// External Dependencies
import React from 'react';
import {
  TextField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  NumberField,
  DateField,
} from 'react-admin';

const RewardsPeriodShow = (properties) => (
  <Show {...properties}>
    <SimpleShowLayout>
      <ReferenceField source="userId" reference="Users">
        <TextField label="id" source="id" />
      </ReferenceField>
      <NumberField source="pointsTotal" />
      <DateField source="startDate" showTime />
      <DateField source="endDate" showTime />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </SimpleShowLayout>
  </Show>
);

export default RewardsPeriodShow;
