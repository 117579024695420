import React from 'react';
import { Box, Divider } from '@mui/material';
import { useAdBoardContext } from '../../context';
import { SectionActivitiesView } from '../SectionActivitiesView';
import { Loader } from '../Loader';
import i18next from '../../languages/i18n.config';
import { KeyopsHeader2 } from '../Headers';
import { CloseButton } from '../CloseButton';

interface SectionNavigationContentProps {
  handleSectionDrawerToggle: () => void;
  setCurrentSectionId: (id: string) => void;
}

export const SectionNavigationContent = ({
  handleSectionDrawerToggle,
  setCurrentSectionId,
}: SectionNavigationContentProps): JSX.Element => {
  const { isLoading, isError, sectionGroupsData } = useAdBoardContext();

  if (isError) return <>Something went wrong</>;

  return (
    <>
      <Box
        pl={3}
        pr={2}
        py={2}
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        <KeyopsHeader2 fontWeight={400}>
          {i18next.t('ad_board.sectionsDrawer.activities')}
        </KeyopsHeader2>
        <CloseButton onClick={handleSectionDrawerToggle} />
      </Box>
      <Divider />
      <Box
        flexGrow={1}
        sx={{
          pb: '20px',
          height: 'calc(100vh - 180px)', // Adjust for header size
          overflowY: 'auto',
        }}
      >
        {(isLoading || !sectionGroupsData || sectionGroupsData.length <= 0) && (
          <Loader size='3rem' pt={2} />
        )}
        {sectionGroupsData &&
          sectionGroupsData.length > 0 &&
          sectionGroupsData.map((sectionGroup) => (
            <SectionActivitiesView
              key={sectionGroup.id}
              sectionGroup={sectionGroup}
              setCurrentSectionId={setCurrentSectionId}
            />
          ))}
      </Box>
    </>
  );
};
