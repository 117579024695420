// External Dependencies
import React from 'react';
import {
  TextField,
  ReferenceField,
  DateField,
  SimpleForm,
  Labeled,
  Edit,
  FunctionField,
  SelectInput,
  BooleanInput,
  TextInput,
  DateInput,
} from 'react-admin';
import { tierTypesChoices } from '../data';

const RewardsTierEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <Labeled label="Id">
        <TextField source="id" />
      </Labeled>
      <ReferenceField
        label="User"
        source="rewardPeriodId"
        reference="RewardsPeriod"
      >
        <ReferenceField label="User" source="userId" reference="Users">
          <FunctionField
            label="Name"
            render={(record) => `${record.firstName} ${record.lastName}`}
          />
        </ReferenceField>
      </ReferenceField>
      <SelectInput source="tier" choices={tierTypesChoices} required />
      <Labeled label="Awarded At">
        <DateField source="awardedAt" />
      </Labeled>
      <BooleanInput source="claimed" />
      <DateInput source="claimedAt" />
      <TextInput source="rewardClaimed" fullWidth />
      <BooleanInput source="notificationDismissed" />
    </SimpleForm>
  </Edit>
);

export default RewardsTierEdit;
