import React from 'react';
import { useInput } from 'react-admin';
import { Typography, ToggleButton, ToggleButtonGroup } from '@mui/material';

const KOToggleButton = (properties) => {
  const { values, defaultValue } = properties;
  const {
    field: { onChange, value, ...rest },
    // meta: { touched, error },
    // isRequired
  } = useInput(properties);

  const changeValue = (e, v) => {
    onChange(v ? v : defaultValue);
  };
  return (
    <>
      <ToggleButtonGroup
        value={value}
        exclusive
        onChange={changeValue}
        {...rest}
      >
        {values &&
          Object.keys(values).map((k, index) => (
            <ToggleButton key={index} value={k} aria-label="centered">
              <Typography variant={'body1'}>{values[k]}</Typography>
            </ToggleButton>
          ))}
      </ToggleButtonGroup>
    </>
  );
};

export default KOToggleButton;
