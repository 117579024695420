// External Dependencies
import * as React from 'react';
import { cloneElement } from 'react';
import {
  Filter,
  List,
  Datagrid,
  TextField,
  DateField,
  TextInput,
  FunctionField,
  SelectInput,
  Pagination,
  ReferenceManyField,
  downloadCSV,
  useListContext,
  TopToolbar,
  CreateButton,
  ExportButton,
  sanitizeListRestProps,
  EditButton,
} from 'react-admin';
import jsonExport from 'jsonexport/dist';
import { XofYField, convertToXofY } from '../../../components/XofYField';

// Internal Dependencies
import CustomBulkDeleteButton from '../../../components/CustomBulkDeleteButton';
import {
  CountryChoices,
  UserTypeChoices,
  SpecialtyChoices,
  StateAndProvinceChoices,
  UserStateChoices,
} from '../../../utils/choices.js';
import { CopyPropertyButton } from '../../../components/CopyPropertyButton';

function isCompletedInvitation(record) {
  return record.state === 'completed';
}

function isCompletedReferral(record) {
  return record.completed;
}

const exporter = (users, _empty, dataProvider) => {
  const queryParameters = { pagination: { page: 1, perPage: 100_000 } };
  Promise.all([
    dataProvider.getList('Invitation', queryParameters).then((result) => {
      console.log('invitations retrieved: ' + result.total);
      const invitationsByUserId = new Map();
      for (const invitation of result.data) {
        const userId = invitation.userId;
        let result = invitationsByUserId.get(userId) ?? [];
        result.push(invitation);
        invitationsByUserId.set(userId, result);
      }
      for (const user of users) {
        const userId = user.id;
        user.invitations = convertToXofY(
          invitationsByUserId.get(userId),
          isCompletedInvitation
        );
      }
    }),
    dataProvider.getList('Referrals', queryParameters).then((result) => {
      console.log('referrals retrieved: ' + result.total);
      const referralsByUserId = new Map();
      for (const referral of result.data) {
        const senderId = referral.sentBy;
        let result = referralsByUserId.get(senderId) ?? [];
        result.push(referral);
        referralsByUserId.set(senderId, result);
      }
      for (const user of users) {
        const userId = user.id;
        user.referrals = convertToXofY(
          referralsByUserId.get(Number.parseInt(userId)),
          isCompletedReferral
        );
      }
    }),
  ]).then((results) => {
    // TODO: use `results`?
    console.log(results);
    const usersForExport = users.map((user) => {
      return user;
      // const { backlinks, author, ...postForExport } = post; // omit backlinks and author
      // postForExport.author_name = post.author.name; // add a field
      // return postForExport;
    });
    jsonExport(
      usersForExport,
      {
        // headers: ['id', 'title', 'author_name', 'body'] // order fields in the export
      },
      (error, csv) => {
        downloadCSV(csv, 'users'); // download as 'posts.csv` file
      }
    );
  });
};
const UserFilter = (properties) => {
  return (
    <>
      <Filter {...properties}>
        <TextInput label="Search" source="q" alwaysOn />

        <SelectInput source="specialty" choices={SpecialtyChoices} />
        <SelectInput source="country" choices={CountryChoices} />
        <SelectInput
          source="provinceOfPractice"
          choices={StateAndProvinceChoices}
        />
        <SelectInput source="state" choices={UserStateChoices} />
        <SelectInput source="hcpType" choices={UserTypeChoices} />
      </Filter>
    </>
  );
};

const UserBulkActionButtons = (properties) => (
  <React.Fragment>
    <CustomBulkDeleteButton {...properties} />
  </React.Fragment>
);

const UserPagination = (properties) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...properties} />
);

const ListActions = (properties) => {
  const { className, filters, ...rest } = properties;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
    total,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={100_000} // TODO: use maxResults from props?
      />
    </TopToolbar>
  );
};

export const UserList = (properties) => {
  return (
    <List
      pagination={<UserPagination />}
      filters={<UserFilter />}
      sort={{ field: 'createdAt', order: 'DESC' }}
      exporter={exporter}
      actions={<ListActions />}
      {...properties}
    >
      <Datagrid rowClick="show" bulkActionButtons={<UserBulkActionButtons />}>
        <TextField source="id" />
        <CopyPropertyButton label="" property="id" />
        <FunctionField
          label="Name"
          render={(record) => `${record.firstName} ${record.lastName}`}
        />
        <TextField source="email" />
        <TextField source="state" />
        <TextField source="hcpType" />
        <TextField source="country" />
        <TextField source="provinceOfPractice" />
        <FunctionField
          label="Specialty"
          render={(record) => `${record.specialty}`}
        />
        <TextField label="Language preference" source="languagePreference" />

        <DateField source="createdAt" />
        <DateField source="updatedAt" />

        <ReferenceManyField
          reference="Invitation"
          target="userId"
          perPage={9999}
          label="Invitations"
        >
          <XofYField numeratorFilter={isCompletedInvitation} />
        </ReferenceManyField>
        <ReferenceManyField
          reference="Referrals"
          target="sentBy"
          perPage={9999}
          label="Referrals"
        >
          <XofYField numeratorFilter={isCompletedReferral} />
        </ReferenceManyField>

        <EditButton />
      </Datagrid>
    </List>
  );
};
