import React from 'react';
import { Popover } from '@mui/material';

import { theme } from '../../theme';

export interface TopNavBarPopoverProps {
  contentType: string; // type of the content to display (notifications, profile, ...)
  contentAnchor: HTMLButtonElement | SVGSVGElement | null; // The html element that is the popover anchor
  isOpen: boolean;
  onClose: () => void;
  topOffset?: string; // Optional top offset
  children: React.ReactNode;
}

export const TopNavBarPopover = ({
  contentType,
  contentAnchor,
  isOpen,
  onClose,
  topOffset,
  children,
}: TopNavBarPopoverProps) => {
  return (
    <Popover
      id={contentType + '-popover'}
      data-testid={contentType + '-popover'}
      open={isOpen}
      anchorEl={contentAnchor}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      elevation={2}
      slotProps={{
        paper: {
          sx: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mt: topOffset ?? 0, // Add margin-top to shift the popover downward
            width: `539px`, // Also aligned with NotificationsRenderer in desktop
            borderRadius: 1,
            boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.05)',
            border: '1px solid ' + theme.palette.keyops.blue.lightSteelBlue20,
            px: '8px',
          },
        },
      }}
    >
      {children}
    </Popover>
  );
};
