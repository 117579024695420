import { NotificationType } from 'react-admin';
import {
  DiscussionDto,
  DiscussionAdBoardSectionContentDto,
  SectionDto,
  DiscussionsAdBoardSectionDto,
} from '@keyops-hcp/dtos';
import { useMutation } from '@keyops-hcp/ui-components';

import { useUpdateSectionContent } from './useUpdateSectionContent';
import { createOneDiscussion } from '../api/discussions';

export const useCreateDiscussionSection = (
  section: DiscussionsAdBoardSectionDto,
  setEditSection: React.Dispatch<React.SetStateAction<SectionDto>>,
  notify: (
    message: string | React.ReactNode,
    options?: NotificationOptions & {
      type?: NotificationType;
    },
  ) => void,
) => {
  // To be able to update the section content on success
  const { mutate: updateSectionContent } =
    useUpdateSectionContent(setEditSection);

  return useMutation<
    DiscussionDto,
    unknown,
    { discussionToSave: DiscussionDto }
  >(
    async ({ discussionToSave }): Promise<DiscussionDto> => {
      // First create the discussion
      const createdDiscussion = await createOneDiscussion(discussionToSave);

      return createdDiscussion;
    },
    {
      onSuccess: (createdDiscussion) => {
        if (createdDiscussion) {
          try {
            // Then update the section
            updateSectionContent({
              sectionId: section.id,
              content: [
                {
                  discussionId: createdDiscussion.id,
                  index: section?.content?.length ?? 0,
                } as DiscussionAdBoardSectionContentDto,
              ],
            });
            notify('Discussion created successfully', { type: 'success' });
          } catch (error) {
            console.error('Failed to update section:', error);
          }
        }
      },
      onError: (error) => {
        console.error(error);
        notify('Failed to create the discussion', { type: 'error' });
      },
    },
  );
};
