// External Dependencies
import * as React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  maxLength,
  minLength,
  TextField,
  Labeled,
} from 'react-admin';

const validateName = [required(), minLength(2), maxLength(15)];

const CompanyName = ({ record }) => {
  return <span>Company {record ? `"${record.name}"` : ''}</span>;
};
const CompanyEdit = (properties) => (
  <Edit title={<CompanyName />} {...properties}>
    <SimpleForm>
      <Labeled label="Id">
        <TextField source="id" />
      </Labeled>
      <TextInput source="name" validate={validateName} fullWidth />
    </SimpleForm>
  </Edit>
);

export default CompanyEdit;
