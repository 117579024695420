import React from 'react';
import { IconButton, useTheme } from '@mui/material';
import { FiX } from 'react-icons/fi';

export const CloseButton = ({
  onClick,
}: {
  onClick: () => void;
}): JSX.Element => {
  const { palette } = useTheme();

  return (
    <IconButton
      size='small'
      onClick={() => onClick()}
      sx={{
        color: palette.keyops.blue.lightSteelBlue20,
        '&:hover': {
          backgroundColor: 'transparent',
          color: palette.keyops.blue.lightSteelBlue,
        },
      }}
    >
      <FiX style={{ fontSize: '1.25rem' }} />
    </IconButton>
  );
};
