import { useQuery, UseQueryResult } from '@keyops-hcp/ui-components';
import { getParticipantsByAdBoardId } from '../api/adboards-api';
import { AdBoardEngagementParticipantsDto } from '@keyops-hcp/dtos';

const useParticipantsByAdBoardId = (
  adBoardId: string,
): UseQueryResult<AdBoardEngagementParticipantsDto[], Error> => {
  return useQuery({
    queryKey: ['getParticipantsByAdBoardId', adBoardId],
    queryFn: () => getParticipantsByAdBoardId(adBoardId),
    enabled: !!adBoardId,
  });
};

export default useParticipantsByAdBoardId;
