import { Stars } from '@mui/icons-material';
import RewardsTierEdit from './components/Edit';
import RewardsTierList from './components/List';

const resource = {
  list: RewardsTierList,
  edit: RewardsTierEdit,
  icon: Stars,
};

export default resource;
