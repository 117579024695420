import React from 'react';
import { Container, Stack } from '@mui/material';
import { KeyopsHeader2, KeyopsSubTitle1 } from '@keyops-hcp/ui-components';

const EmptyEngagement = () => (
  <Container maxWidth="xs" sx={{ py: 8 }}>
    <Stack width={'100%'} textAlign={'center'} gap={1}>
      <KeyopsHeader2 fontSize={'1.125rem'}>
        Your ad board isn’t live yet.
      </KeyopsHeader2>
      <KeyopsSubTitle1>
        Once it’s live, you’ll be able to monitor participant progress and
        engagement here.
      </KeyopsSubTitle1>
    </Stack>
  </Container>
);

export default EmptyEngagement;
