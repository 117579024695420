import * as React from 'react';

const FullNameField = ({ record = {} }) => (
  <span>
    {record.firstName} {record.lastName}
  </span>
);
FullNameField.defaultProps = { label: 'Name' };

export const EngagementFilesPreviewField = ({ record = {} }) => {
  const { type, mediaLink } = record;
  return (
    <div>
      {type === 'video' ? (
        <iframe
          title="preview"
          src={`https://player.vimeo.com/video/${mediaLink}`}
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
        ></iframe>
      ) : (
        ''
      )}
    </div>
  );
};
