// External Dependencies
import * as React from 'react';
import {
  Filter,
  List,
  Datagrid,
  FunctionField,
  DateField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  BooleanField,
  TextField,
  DateTimeInput,
} from 'react-admin';
import { claimedChoices, tierTypesChoices } from '../data';
// Internal Dependencies

const AddFilterDropdown = (props) => (
  <Filter {...props}>
    <ReferenceInput source="userId" reference="Users" label="User Name">
      <AutocompleteInput
        source="id"
        optionText={(choice) => {
          if (!choice) return ''; // Handles null or undefined choice
          const firstName = choice.firstName || '';
          const lastName = choice.lastName || '';
          return `${firstName} ${lastName}`.trim();
        }}
      />
    </ReferenceInput>
    <SelectInput source="tier" label="Reward Tier" choices={tierTypesChoices} />

    <DateTimeInput source="awardedAfter" label="Awarded After" />

    <DateTimeInput source="awardedBefore" label="Awarded Before" />
    <SelectInput source="claimed" label="Claimed" choices={claimedChoices} />
  </Filter>
);

const RewardsTierList = (props) => (
  <List
    aside={<div />}
    filters={<AddFilterDropdown />}
    sort={{ field: 'updatedAt', order: 'DESC' }}
    {...props}
  >
    <Datagrid rowClick="edit">
      <ReferenceField
        label="User"
        source="rewardPeriodId"
        reference="RewardsPeriod"
      >
        <ReferenceField source="userId" reference="Users">
          <FunctionField
            label="Name"
            render={(record) => `${record.firstName} ${record.lastName}`}
          />
        </ReferenceField>
      </ReferenceField>
      <ReferenceField
        source="rewardPeriodId"
        reference="RewardsPeriod"
        sortable={false}
      >
        <DateField source="startDate" /> &mdash; <DateField source="endDate" />
      </ReferenceField>
      <TextField source="tier" />
      <DateField source="dateAwarded" showTime />
      <BooleanField source="claimed" />
      <DateField source="claimedDate" showTime />
      <TextField source="rewardClaimed" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
      <BooleanField source="notificationDismissed" />
    </Datagrid>
  </List>
);
export default RewardsTierList;
