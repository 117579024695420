// External Dependencies
// import * as React from 'react';
import React from 'react';
import { Edit, BooleanInput, SimpleForm } from 'react-admin';

// Internal Dependencies

const ReferralsEdit = (properties) => {
  return (
    <Edit title={<div />} aside={<div />} {...properties}>
      <SimpleForm>
        {/*<NumberInput source="payout" multiline fullWidth helperText={false} />
      <NumberInput source="sentBy" multiline fullWidth helperText={false} />
      <NumberInput source="receivedBy" multiline fullWidth helperText={false} />
      <NumberInput source="engagementId" multiline fullWidth helperText={false} />*/}
        <BooleanInput source="paid" />
      </SimpleForm>
    </Edit>
  );
};

export default ReferralsEdit;
