import axios from 'axios';
import { AD_BOARD_SECTION_GROUPS } from '../utils/api-endpoints.const';
import { AdBoardSectionGroupDto } from '@keyops-hcp/dtos';

export const createOneAdBoardSectionGroup = async (
  data: Partial<AdBoardSectionGroupDto>,
): Promise<AdBoardSectionGroupDto> => {
  try {
    const response = await axios.post(AD_BOARD_SECTION_GROUPS, data, {
      headers: {
        'Content-Type': 'application/json',
        keyops__accesstokens: localStorage.getItem('token'),
        userType: 'admin',
      },
    });

    return response.data.data;
  } catch (error) {
    console.error('Error creating new AdBoard Section Group:', error);
    throw error;
  }
};

export const updateOneAdBoardSectionGroup = async (
  data: Partial<AdBoardSectionGroupDto>,
): Promise<AdBoardSectionGroupDto> => {
  try {
    const response = await axios.patch(
      AD_BOARD_SECTION_GROUPS + '/' + data.id,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          keyops__accesstokens: localStorage.getItem('token'),
          userType: 'admin',
        },
      },
    );

    return response.data.data;
  } catch (error) {
    console.error('Error creating new AdBoard Section Group:', error);
    throw error;
  }
};

export const deleteOneAdBoardSectionGroup = async (
  id: string,
): Promise<number> => {
  try {
    const response = await axios.delete(AD_BOARD_SECTION_GROUPS + '/' + id, {
      headers: {
        'Content-Type': 'application/json',
        keyops__accesstokens: localStorage.getItem('token'),
        userType: 'admin',
      },
    });

    return response.data.data;
  } catch (error) {
    console.error('Error creating new AdBoard Section Group:', error);
    throw error;
  }
};
