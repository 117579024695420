import React, { useEffect } from 'react';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { IconButton } from '@mui/material';
import { useTranslate } from 'ra-core';
import { styled } from '@mui/material/styles';

// Define styled components with previous makeStyles styles
const StyledItem = styled('div')(() => ({
  display: 'inline-block',
  position: 'relative',
  float: 'left',
  '& button': {
    position: 'absolute',
    opacity: 0,
  },
  '&:hover button': {
    opacity: 1,
  },
}));

const StyledIconButton = styled(IconButton)(({ theme, position }) => ({
  position: 'absolute',
  top: position === 'top' ? theme.spacing(1) : 'auto',
  bottom: position === 'bottom' ? '0.5rem' : 'auto',
  left: position === 'left' ? '0.5rem' : 'auto',
  right: position === 'right' ? '0.5rem' : 'auto',
  minWidth: position === 'top' ? theme.spacing(2) : 'auto',
  color: theme.palette.error.main,
}));

export const FileUploadPreview = (properties) => {
  const { children, onRemove, onMove, file, ...rest } = properties;

  const translate = useTranslate();

  useEffect(() => {
    return () => {
      const preview = file.rawFile ? file.rawFile.preview : file.preview;

      if (preview) {
        globalThis.URL.revokeObjectURL(preview);
      }
    };
  }, [file]);

  return (
    <StyledItem {...rest}>
      <StyledIconButton position="left" onClick={() => onMove(-1)}>
        <NavigateBeforeIcon />
      </StyledIconButton>
      <StyledIconButton position="right" onClick={() => onMove(1)}>
        <NavigateNextIcon />
      </StyledIconButton>
      <StyledIconButton
        position="top"
        onClick={onRemove}
        aria-label={translate('ra.action.delete')}
        title={translate('ra.action.delete')}
      >
        <RemoveCircleIcon />
      </StyledIconButton>
      {children}
    </StyledItem>
  );
};
