import React from 'react';
import { List, ListItem, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router';

import { DisplayNotification } from '../../utils/notifications-utils';

interface NotificationsListProps {
  list: DisplayNotification[];
  handleClose: () => void;
}

export const NotificationsList = ({
  list,
  handleClose,
}: NotificationsListProps) => {
  const { palette } = useTheme();
  const navigate = useNavigate();

  const handleNotificationClick = (url: string | undefined) => {
    if (url) {
      handleClose();
      const parsedUrl = new URL(url);
      navigate(parsedUrl.pathname + parsedUrl.search + parsedUrl.hash);
    }
  };

  return (
    <List data-testid='notifications-list' sx={{ p: 0 }}>
      {list.map((item) => (
        <ListItem
          key={item.id}
          data-testid='notification-item'
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            wordWrap: 'break-word',
            px: 2,
            gap: 0.5,
            backgroundColor: !item.read && palette.keyops.blue.arcticBlue,
            ':hover': item.url
              ? {
                  backgroundColor: palette.keyops.gray[100],
                  cursor: 'pointer',
                }
              : {},
          }}
          onClick={() => handleNotificationClick(item.url)}
        >
          <Typography variant='body3'>{item.text}</Typography>
          <Typography
            variant='h3'
            color={palette.keyops.blue.lightSteelBlue}
            textTransform={'lowercase'}
          >
            {item.time}
          </Typography>
        </ListItem>
      ))}
    </List>
  );
};

export default NotificationsList;
