import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
} from 'react-admin';

const dateOptions = {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
};

const OnboardingLinkList = (properties) => (
  <List sort={{ field: 'updatedAt', order: 'DESC' }} {...properties}>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <TextField source="enabled" />
      <DateField
        label="Created At"
        source="createdAt"
        showTime="true"
        options={dateOptions}
      />
      <DateField
        label="Updated At"
        source="updatedAt"
        showTime="true"
        options={dateOptions}
      />
      <TextField source="id" />
      <ReferenceField
        label="Engagement"
        source="engagementId"
        reference="Engagement"
      >
        <TextField source="title" />
      </ReferenceField>
    </Datagrid>
  </List>
);

export default OnboardingLinkList;
