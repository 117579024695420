import React from 'react';
import { useNavigate } from 'react-router';
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import { MobileAdBoardTopBar } from '../MobileAdBoardTopBar';
import { DesktopAdBoardTopBar } from '../DesktopAdBoardTopBar';
import { DASHBOARD } from '../../utils/physician-ui-routes';
import { KeyOpsIcon } from '../KeyOpsIcon';
import { useAdBoardContext } from '../../context';

interface AdBoardTopBarProps {
  toggleOutsideDrawer: (newOpen: boolean) => () => void;
  toggleDiscussionDrawer: (isDrawerOpen: boolean) => void;
  toggleSectionDrawer: () => void;
  isSectionDrawerOpen: boolean;
  isDiscussionDrawerOpen: boolean;
}

export const AdBoardTopBar: React.FC<AdBoardTopBarProps> = ({
  toggleOutsideDrawer,
  toggleDiscussionDrawer,
  toggleSectionDrawer,
  isSectionDrawerOpen,
  isDiscussionDrawerOpen,
}) => {
  const { breakpoints, palette } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const navigate = useNavigate();
  const { shouldShowMainHcpNav } = useAdBoardContext();

  const TopBar = isMobile ? MobileAdBoardTopBar : DesktopAdBoardTopBar;

  return (
    <AppBar
      position={'fixed'}
      color={'inherit'}
      sx={{
        boxShadow: 'none',
        borderBottom: `1px solid ${palette.keyops.gray[200]}`,
        height: '96px',
        pt: '16px',
      }}
    >
      <Toolbar>
        <Box
          display={'flex'}
          alignItems={'center'}
          gap={isMobile ? 0.5 : 1}
          mr={5}
          flexGrow={isMobile ? 1 : 0}
        >
          {shouldShowMainHcpNav() && (
            <IconButton
              size='large'
              edge='start'
              color='inherit'
              aria-label='menu'
              data-testid='menu-icon'
              sx={{ color: palette.keyops.blue.midnightBlue }}
              onClick={toggleOutsideDrawer(true)}
            >
              <MenuIcon sx={{ fontSize: '45px' }} />
            </IconButton>
          )}
          {!isMobile && (
            <Box
              data-testid='KeyOps-icon'
              width={45}
              height={45}
              onClick={() => navigate(DASHBOARD)}
              sx={{ cursor: 'pointer' }}
            >
              <KeyOpsIcon />
            </Box>
          )}
        </Box>
        <TopBar
          toggleDiscussionDrawer={toggleDiscussionDrawer}
          toggleSectionDrawer={toggleSectionDrawer}
          isSectionDrawerOpen={isSectionDrawerOpen}
          isDiscussionDrawerOpen={isDiscussionDrawerOpen}
        />
      </Toolbar>
    </AppBar>
  );
};
