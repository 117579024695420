import { queryClient, useMutation } from '@keyops-hcp/ui-components';
import { deleteOneAdBoardSectionGroup } from '../api/ad-board-section-groups-api';

export const useDeleteAdBoardSectionGroup = (notify) => {
  return useMutation<unknown, unknown, string>(
    async (id) => {
      const deletedCount = await deleteOneAdBoardSectionGroup(id);
      return deletedCount;
    },
    {
      // Options for the mutation
      onSuccess: async (updatedSection) => {
        if (updatedSection) {
          await queryClient.invalidateQueries({
            queryKey: ['getAdBoardById'],
          });
          // Refetch all sections
          await queryClient.invalidateQueries({
            queryKey: ['getAdBoardSections'],
          });
          notify('Section group successfully deleted', { type: 'success' });
        }
      },
      onError: (error) => {
        console.error(error);
        notify('Failed to delete the section group', { type: 'error' });
      },
    },
  );
};
