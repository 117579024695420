// Import your API function
import {
  createOneAdBoard,
  deleteAdBoardsByIds,
  getAdBoardById,
  getAllAdBoards,
  updateOneAdBoard,
} from '../api/adboards-api';

// Define the localDataProvider
const localDataProvider = {
  getList: async (resource, parameters) => {
    if (resource !== 'AdBoards') {
      throw new Error(`Unknown resource: ${resource}`);
    }

    const { pagination, sort, filter } = parameters;

    try {
      const data = await getAllAdBoards(pagination, sort, filter); // Fetch data using your API function
      return {
        data: data.map((item, index) => ({
          ...item,
          id: item.id || index.toString(), // Ensure each item has a unique string id
        })),
        total: data.length,
      };
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  },

  getOne: async (resource, parameters) => {
    if (resource !== 'AdBoards') {
      throw new Error(`Unknown resource: ${resource}`);
    }
    try {
      const data = await getAdBoardById(parameters.id); // Fetch data using your API function
      return {
        data,
      };
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  },

  getMany: async () => {
    throw new Error('Method not implemented');
  },

  getManyReference: async () => {
    throw new Error('Method not implemented');
  },

  create: async (resource, parameters) => {
    if (resource !== 'AdBoards') {
      throw new Error(`Unknown resource: ${resource}`);
    }
    const adBoardData = parameters.data;
    try {
      const response = await createOneAdBoard(adBoardData);
      return { data: response };
    } catch (error) {
      console.error('Error creating AdBoard:', error);
      throw error;
    }
  },

  update: async (resource, parameters) => {
    if (resource !== 'AdBoards') {
      throw new Error(`Unknown resource: ${resource}`);
    }
    const adBoardData = parameters.data;
    try {
      const response = await updateOneAdBoard(adBoardData);
      return { data: response[0] };
    } catch (error) {
      console.error(`Error updating AdBoard - ${adBoardData?.id}:`, error);
      throw error;
    }
  },

  updateMany: async () => {
    throw new Error('Method not implemented');
  },

  delete: async () => {
    throw new Error('Method not implemented');
  },

  deleteMany: async (resource, parameters) => {
    if (resource !== 'AdBoards') {
      throw new Error(`Unknown resource: ${resource}`);
    }
    const adBoardIds = parameters.ids;
    try {
      const response = await deleteAdBoardsByIds(adBoardIds);
      return { data: response };
    } catch (error) {
      console.error('Error creating AdBoard:', error);
      throw error;
    }
  },
};

export default localDataProvider;
