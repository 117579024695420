import React from 'react';
import {
  Datagrid,
  DateField,
  FunctionField,
  ListContextProvider,
  ReferenceField,
  useList,
  WrapperField,
} from 'react-admin';
import {
  Box,
  IconButton,
  LinearProgress,
  LinearProgressProps,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { PersonOutline } from '@mui/icons-material';
import { dateFormatter } from '@keyops-hcp/ui-components/dist/utils/datetime';
import { AdBoardEngagementParticipantsDto } from '@keyops-hcp/dtos';

const LinearProgressWithLabel = (
  properties: LinearProgressProps & { value: number },
) => {
  return (
    <>
      {/* TODO: Add tooltip here */}
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...properties} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography
            variant="body2"
            sx={{ color: 'text.secondary' }}
          >{`${Math.round(properties.value)}%`}</Typography>
        </Box>
      </Box>
    </>
  );
};

const ParticipantsList = ({
  handleParticipantFeed,
  participantsData,
}: {
  handleParticipantFeed: (value: number | null) => void;
  participantsData: AdBoardEngagementParticipantsDto[];
}) => {
  const listContext = useList({ data: participantsData });

  return (
    <ListContextProvider value={listContext}>
      <Datagrid bulkActionButtons={false}>
        <WrapperField label="Name">
          <Stack>
            <ReferenceField source="userId" reference="Users">
              <FunctionField
                render={(record) => `${record.firstName} ${record.lastName}`}
              />
            </ReferenceField>
            <FunctionField
              render={(record) =>
                `Last active: ${dateFormatter(record.progress?.updatedAt)}`
              }
            />
          </Stack>
        </WrapperField>
        <DateField source="progress.startedOn" label="Started on" />
        <FunctionField
          source="progress"
          label="Progress"
          render={(record) => (
            <LinearProgressWithLabel
              value={record.completionPercentage}
              sx={{ height: 10, borderRadius: 5 }}
            />
          )}
        />
        <DateField source="progress.finishedOn" label="Finished on" />
        <FunctionField
          label="Actions"
          render={(record) => (
            <Stack direction="row">
              {/* TODO: uncomment this when this feature is available */}
              {/* <Tooltip title='Messages' placement='top' arrow>
                <IconButton onClick={() => null}>
                  <ChatBubbleOutline />
                </IconButton>
              </Tooltip> */}
              <Tooltip title="View engagement" placement="top" arrow>
                <IconButton
                  onClick={() => handleParticipantFeed(record.userId)}
                >
                  <PersonOutline />
                </IconButton>
              </Tooltip>
            </Stack>
          )}
        />
      </Datagrid>
    </ListContextProvider>
  );
};

export default ParticipantsList;
