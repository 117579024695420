// External Dependencies
// import * as React from 'react';
import React from 'react';
import {
  SelectInput,
  Edit,
  TextInput,
  TextField,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  SimpleForm,
  Labeled,
} from 'react-admin';

// Internal Dependencies

const PaymentEdit = (properties) => {
  return (
    <Edit title={<div />} aside={<div />} {...properties}>
      <SimpleForm>
        <Labeled label="Id">
          <TextField source="id" />
        </Labeled>
        <TextInput
          disabled
          source="origin.type"
          label="Origin Type"
          fullWidth
        />
        <TextInput disabled source="origin.id" label="Origin Id" fullWidth />
        <TextInput
          disabled
          source="origin.engagementId"
          label="Origin Engagement Id"
          fullWidth
        />
        <ReferenceInput source="userId" reference="Users" fullWidth>
          <AutocompleteInput
            source="userId"
            optionText={(choice) => `${choice.firstName}, ${choice.lastName}`}
          />
        </ReferenceInput>
        <NumberInput
          source="amount"
          label="Amount (In cents)"
          parse={(v) => (v > 99_999 ? Math.floor(v / 10) : v)}
          fullWidth
        />
        <TextInput disabled source="provider" label="Provider" fullWidth />
        <SelectInput
          source="state"
          choices={[
            { id: 'pending', name: 'Pending' },
            { id: 'pending_approved', name: 'Pending Approved' },
            { id: 'cancelled', name: 'Cancelled' },
          ]}
          fullWidth
        />
        <TextField color="error" disabled source="failureNote" fullWidth />
      </SimpleForm>
    </Edit>
  );
};

export default PaymentEdit;
