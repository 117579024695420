import { queryClient, useMutation } from '@keyops-hcp/ui-components';
import { sendAdBoardInvites } from '../api/adboard-invites-api';

export const useSendAdBoardInvite = (notify) => {
  return useMutation<
    unknown,
    unknown,
    { adBoardId: string; selectedIds: string[] }
  >(
    async ({ adBoardId, selectedIds }) => {
      await sendAdBoardInvites(adBoardId, selectedIds);
    },
    {
      // Options for the mutation
      onSuccess: () => {
        // Refetch invites
        queryClient.invalidateQueries({
          queryKey: ['getInvitesByAdBoardId'],
        });

        notify('Invites sent', { type: 'success' });
      },
      onError: (error) => {
        console.error(error);
        notify('Failed to send invites', { type: 'error' });
      },
    },
  );
};
