import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Divider,
  Stack,
  useTheme,
} from '@mui/material';
import { AdBoardDto } from '@keyops-hcp/dtos';

import { KeyopsHeader2 } from '../../../../Headers';
import { useAllParticipantsByAdBoard } from '../../../../../hooks/useAllParticipantsByAdBoard';
import { Loader } from '../../../../Loader';
import i18next from '../../../../../languages/i18n.config';
import { KeyOpsUserDisplay } from '../../../../KeyOpsUserDisplay';

interface ModeratorsSectionProps {
  adBoard: AdBoardDto;
}

export const ModeratorsSection = ({ adBoard }: ModeratorsSectionProps) => {
  const { palette } = useTheme();

  const {
    isLoading,
    isError,
    data: participants,
  } = useAllParticipantsByAdBoard(adBoard.id);

  const moderators =
    participants?.filter((participant) => participant.role === 'moderator') ??
    [];

  if (isError) return <div>{i18next.t('common.error')}</div>;

  return (
    <Box>
      <Card variant='outlined' sx={{ borderRadius: 2 }}>
        <CardContent sx={{ px: 3 }}>
          {moderators.length > 0 && (
            <>
              <KeyopsHeader2 gutterBottom>
                {i18next.t('ad_board.landing_page.moderators')}
              </KeyopsHeader2>
              {isLoading ? (
                <Loader />
              ) : (
                <Stack spacing={3} mt={2}>
                  {moderators.map((moderator) => (
                    <KeyOpsUserDisplay
                      key={moderator.id}
                      firstName={moderator.user.firstName}
                      lastName={moderator.user.lastName}
                      avatarStyles={{
                        backgroundColor: palette.keyops.blue.midnightBlue,
                      }}
                    />
                  ))}
                </Stack>
              )}
              <Divider variant='middle' sx={{ mt: 3, mb: 2 }} />
            </>
          )}
          {adBoard.company?.name && (
            <>
              <KeyopsHeader2 gutterBottom>
                {i18next.t('ad_board.landing_page.hostedBy')}
              </KeyopsHeader2>
              <KeyOpsUserDisplay
                firstName={adBoard.company?.name}
                primaryTextStyles={{ fontSize: '0.875rem' }}
                avatarStyles={{
                  backgroundColor: palette.keyops.blue.midnightBlue,
                }}
              />
            </>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};
