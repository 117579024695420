import React from 'react';
import { Button as MUIButton } from '@mui/material';

export const KeyOpsButton = (properties) => {
  return (
    <MUIButton
      style={{
        borderRadius: 50,
        fontWeight: 700,
        textTransform: 'none',
        letterSpacing: '1.1px',
        whiteSpace: 'nowrap',
        margin: 10,
      }}
      variant="contained"
      color="primary"
      {...properties}
    />
  );
};
